import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import {
  RejectWithValueState,
  GetState,
  ChangePasswordState,
  ChangePasswordPayloadState
} from './interfaces'
import api from '../../utils/api'

// Async Requests
export const postChangePassword = createAsyncThunk<any, ChangePasswordPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'changePassword/postChangePassword',
  async ({ oldPassword, newPassword, confirmPassword }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user/update-password', {
      oldPassword,
      newPassword,
      confirmPassword
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial States
const changePasswordState: ChangePasswordState = {
  postChangePasswordLoading: false
}

// Slices
const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: changePasswordState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postChangePassword.pending, (state) => {
        state.postChangePasswordLoading = true
      })
      .addMatcher(isAnyOf(postChangePassword.fulfilled,
        postChangePassword.rejected), (state) => {
        state.postChangePasswordLoading = false
      })
  }
})

// Selectors
export const selectPostChangePasswordLoading = (state: RootState): boolean => state.changePasswordReducer.postChangePasswordLoading

export default changePasswordSlice.reducer
