import { Form, Input, Select, Button, Row, Col, message, Grid } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { selectSelectList, selectMerchantId } from '../../../LoginPage/reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { AddBankFormState } from '../../interfaces'
import { selectPostAddBankAccountLoading, postAddBankAccount, getBankAccounts, postValidateBankAccount } from '../../reducers'
import { BankAndWhiteListButtonsContainer } from '../../styles'
import { selectGlobalCurrency } from '../../../AuthLayout/reducers'

const { useBreakpoint } = Grid

const AddBankForm = (props: AddBankFormState): JSX.Element => {
  const { setAddVisible, filters } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const postAddBankAccountLoading = useAppSelector(selectPostAddBankAccountLoading)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const [form] = Form.useForm()

  const bankSelectList = selectList.bankSelectList.filter((item) => item.currencyName === globalCurrency).map((item: { code: string, name: string }) => {
    return {
      label: `${item.code} - ${item.name}`,
      value: item.code
    }
  }).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  async function onHandleAddBankSubmit (values: any): Promise<any> {
    const addSubmitPayload = {
      bankCode: values.bankCode,
      branch: values.branch,
      name: values.name,
      number: values.number,
      merchantId
    }
    const filtersPayload = {
      ...filters
    }

    try {
      await dispatch(postAddBankAccount(addSubmitPayload)).then(unwrapResult)
      await dispatch(getBankAccounts(filtersPayload))
      await dispatch(postValidateBankAccount())
      await setAddVisible(false)
      return await message.success('Add Bank Account Success!')
    } catch (error: any) {
      await message.error(error.message)
      return form.resetFields()
    }
  }

  function onHandleCancel (): void {
    setAddVisible(false)
  }

  return (
    <Form
      name='add-bank-form'
      form={form}
      onFinish={onHandleAddBankSubmit}
      requiredMark={false}
      layout='vertical'
      initialValues={{
        bankCode: undefined,
        branch: '',
        name: '',
        number: ''
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Bank'
            name='bankCode'
            rules={[{ required: true, message: 'Bank is required.' }]}
          >
            <Select showSearch placeholder='Select Bank' options={bankSelectList} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Remarks'
            name='branch'
            rules={[{ required: true, message: 'Remarks is required.' }]}
          >
            <Input placeholder='Remarks' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Beneficiary Name'
            name='name'
            rules={[{ required: true, message: 'Beneficiary Name is required.' }]}
          >
            <Input placeholder='Beneficiary Name' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Account Number'
            name='number'
            rules={[{ required: true, message: 'Account Number is required.' }, { pattern: new RegExp('^[0-9]*$'), message: 'field only accepts numbers.' }]}
          >
            <Input placeholder='Account Number' />
          </Form.Item>
        </Col>
      </Row>
      <BankAndWhiteListButtonsContainer>
        <Button
          onClick={onHandleCancel}
          block={screens.xs}
          shape='round'
        >
          Cancel
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          block={screens.xs}
          shape='round'
          loading={postAddBankAccountLoading}
        >
          Submit
        </Button>
      </BankAndWhiteListButtonsContainer>
    </Form>
  )
}

export default AddBankForm
