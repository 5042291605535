// utils
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ReactComponent as VNDSvg } from '../assets/images/vnd.svg'
import { ReactComponent as THBSvg } from '../assets/images/thb.svg'
import { ReactComponent as RMBSvg } from '../assets/images/rmb.svg'
import { ReactComponent as IDRSvg } from '../assets/images/idr.svg'
import { ReactComponent as MYRSvg } from '../assets/images/myr.svg'
import { ReactComponent as KRWSvg } from '../assets/images/krw.svg'
import { ReactComponent as USDTTRCSvg } from '../assets/images/usdt-trc20.svg'
import { ReactComponent as USDTBEPSvg } from '../assets/images/usdt-bep20.svg'

import { StatusCode, StatusType } from './interface'
dayjs.extend(utc)

const isEmptyObj = (obj: {}): boolean => {
  return Object.keys(obj).length === 0
}
const isNullOrUndefined = (value: any): boolean => {
  return value === undefined || value === null
}
const titleCaseWithHyphen = (str: string): string => {
  if (str.includes('-')) { // converting to title case, words with hyphen
    return str.split('-')
      .reduce((a, b) => a.charAt(0).toUpperCase() + a.slice(1) + ' ' + b.charAt(0).toUpperCase() + b.slice(1))
  }

  // converting to title case, words without hyphen
  return str.replace(/(^[a-z])|(\s+[a-z])/g, txt => txt.toUpperCase())
}
const camelCaseToTitleCase = (camelCase: string): string => camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`)
  .replace(/^./, (match) => match.toUpperCase())
  .trim()

const pascalCase = (words: string): string => words.replace(/(\w)(\w*)/g,
  function (g0, g1: string, g2: string) {
    return g1.toUpperCase() + g2.toLowerCase()
  })

const statusCases = (statusColors: StatusCode): Record<StatusType, string> => ({
  APPROVED: statusColors.SUCCESS,
  CANCELLED: statusColors.FAILED,
  DONE: statusColors.SUCCESS,
  'FAIL TOPUP': statusColors.FAILED,
  FAILED: statusColors.FAILED,
  PENDING: statusColors.PENDING,
  PROCESSING: statusColors.PENDING,
  REJECTED: statusColors.FAILED,
  SUBMITTED: statusColors.PENDING,
  SUCCESS: statusColors.SUCCESS
})
const renderStatusColors = (status: StatusType, statusColors: StatusCode): string => {
  return statusCases(statusColors)[status]
}

const today = dayjs()
const yesterday = dayjs().subtract(1, 'day')
const lastSevenDays = dayjs().subtract(7, 'day')
const lastThirtyDays = dayjs().subtract(30, 'day')
const startOfMonth = dayjs().startOf('month')
const endOfMonth = dayjs().endOf('month')
const lastMonthStartDay = dayjs().subtract(1, 'months').startOf('month')
const lastMonthLastDay = dayjs().subtract(1, 'months').endOf('month')

interface PresetDate {
  label: React.ReactNode
  value: [dayjs.Dayjs, dayjs.Dayjs]
}

const datePresets: PresetDate[] = [
  { label: 'Today', value: [today, today] },
  { label: 'Yesterday', value: [yesterday, yesterday] },
  { label: 'Last 7 Days', value: [lastSevenDays, today] },
  { label: 'Last 30 Days', value: [lastThirtyDays, today] },
  { label: 'This Month', value: [startOfMonth, endOfMonth] },
  { label: 'Last Month', value: [lastMonthStartDay, lastMonthLastDay] }
]

const truncateString = (str: string, num: number): string => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }

  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`
}
const getCurrencyName = (value: number): string => {
  switch (value) {
    case 1:
      return 'VND'
    case 2:
      return 'THB'
    case 3:
      return 'RMB'
    case 4:
      return 'IDR'
    case 5:
      return 'MYR'
    case 6:
      return 'KRW'
    case 7:
      return 'USDT-TRC20'
    case 9:
      return 'USDT-BEP20'
    default:
      return ''
  }
}
const getCurrencyFlag = (value: string): React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
  title?: string | undefined
}> | undefined => {
  switch (value) {
    case 'VND':
      return VNDSvg
    case 'THB':
      return THBSvg
    case 'RMB':
      return RMBSvg
    case 'IDR':
      return IDRSvg
    case 'MYR':
      return MYRSvg
    case 'KRW':
      return KRWSvg
    case 'USDT-TRC20':
      return USDTTRCSvg
    case 'USDT-BEP20':
      return USDTBEPSvg
    default:
      return VNDSvg
  }
}
const getCurrencyValue = (value: string): number => {
  switch (value) {
    case 'VND':
      return 1
    case 'THB':
      return 2
    case 'RMB':
      return 3
    case 'IDR':
      return 4
    case 'MYR':
      return 5
    case 'KRW':
      return 6
    case 'USDT-TRC20':
      return 7
    case 'USDT-BEP20':
      return 9
    default:
      return 0
  }
}

const inputCheckIp = (value: string): boolean => {
  if (!/[0-9]|\./.test(value[value.length - 1])) return false
  if (value[value.length - 1] === '.' && value[value.length - 2] === '.') return false
  return true
}

const clientTimeZone = -(new Date().getTimezoneOffset() / 60)

const datetime = {
  getStartDate: (date: dayjs.Dayjs | string | null | undefined) => {
    if (date == null) return null
    return dayjs(date).startOf('day').utc(true).toDate()
  },
  getEndDate: (date: dayjs.Dayjs | string | null | undefined) => {
    if (date == null) return null
    return dayjs(date).endOf('day').utc(true).toDate()
  },
  getDate: (date: dayjs.Dayjs | string | null | undefined) => {
    if (date == null) return null
    return dayjs(date).utc(true).toDate()
  }
}

export {
  isEmptyObj,
  titleCaseWithHyphen,
  isNullOrUndefined,
  camelCaseToTitleCase,
  pascalCase,
  datePresets,
  renderStatusColors,
  truncateString,
  getCurrencyName,
  getCurrencyFlag,
  getCurrencyValue,
  inputCheckIp,
  clientTimeZone,
  datetime
}
