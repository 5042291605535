import styled from 'styled-components'
import { PageHeader } from '@ant-design/pro-layout'
import { Card } from 'antd'
import { CardContainerState } from './interfaces'

const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`
const CardContainer = styled(Card)<CardContainerState>`
    background: ${props => props.bgcolor !== undefined ? props.bgcolor : props.theme.colors.primaryColor};
    color: ${props => props.theme.colors.secondaryColor};
    padding: 15px;
    min-width: 19%;
    margin: 0 0 20px 0;

    h1 {
        color: ${props => props.theme.colors.secondaryColor};
        font-size: 12px;
    }
    
    h2 {
        color: ${props => props.theme.colors.secondaryColor};
        font-size: 16px;
        font-weight: bold;
        margin: 5px 0;
        padding-left: 20px;
    }
    
    p {
        font-size: 10px;
        margin: 0;
    }

    small.invert {
        color: rgba(0, 0, 0, 0.8);
    }
    
    div.footer small {
        position: absolute;
        bottom: 10%;
    }

    @media (max-width: 992px) {
        width: 49%;
        margin ${props => props.indexnumber === 4 && '0 auto 20px'};
    }

    @media (max-width: 576px) {
        width: 100%;
    }
`
const PageHeaderContainer = styled(PageHeader)`
    margin: -32px -20px 32px;

    .ant-page-header-content {
        padding: 0;
    }
`

export {
  CardContainer,
  PageHeaderContainer,
  CardWrapper
}
