import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import {
  RejectWithValueState,
  GetState,
  MerchantDetailsState,
  GetMerchantDetailsState,
  GetMerchantDetailsPayloadState,
  PostMerchantDetailsPayloadState,
  GetBankAccountAndWhiteListState,
  GetBankAccountsPayloadState,
  PostUpdateBankAccountPayloadState,
  PostAddBankAccountPayloadState,
  GetWhitelistsPayloadState,
  ValidateWhiteListPayload,
  PostUpdateWhiteListPayloadState,
  PostAddWhiteListPayloadState,
  GetCurrenciesSupportedPayloadState
} from './interfaces'
import api from '../../utils/api'

// Async Requests
export const getMerchantDetails = createAsyncThunk<GetMerchantDetailsState, GetMerchantDetailsPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/getMerchantDetails',
  async ({ merchantId }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get(`/Merchant/GetMerchantById?id=${merchantId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postMerchantDetails = createAsyncThunk<any, PostMerchantDetailsPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/postMerchantDetails',
  async ({ id, name, website }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/UpdateMerchant', {
      id,
      website,
      name
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const getBankAccounts = createAsyncThunk<GetBankAccountAndWhiteListState, GetBankAccountsPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/getBankAccounts',
  async ({ cardNumber, merchantId, pageIndex, pageSize, currency, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/SearchMerchantAccounts', {
      cardNumber,
      merchantId,
      pageIndex,
      pageSize,
      currency,
      status
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const getWhitelists = createAsyncThunk<GetBankAccountAndWhiteListState, GetWhitelistsPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/getWhitelists',
  async ({ ipAddress, ipType, merchantId, pageIndex, pageSize, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/SearchWhiteList', {
      ipAddress,
      ipType,
      merchantId,
      pageIndex,
      pageSize,
      status
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const getCurrenciesSupported = createAsyncThunk<GetBankAccountAndWhiteListState, GetCurrenciesSupportedPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/getCurrenciesSupported',
  async ({ merchantId, pageIndex, pageSize }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/GetCurrenciesSupported', {
      merchantId,
      pageIndex,
      pageSize
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postUpdateBankAccount = createAsyncThunk<any, PostUpdateBankAccountPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/postUpdateBankAccount',
  async ({ bankCode, branch, id, merchantId, name, number, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/UpdateBank', {
      bankCode,
      branch,
      id,
      merchantId,
      name,
      number,
      status
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postUpdateWhiteList = createAsyncThunk<any, PostUpdateWhiteListPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/postUpdateWhiteList',
  async ({ id, merchantId, ipAddress, type, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/UpdateWhiteList', {
      id,
      merchantId,
      ipAddress,
      type,
      status
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postValidateBankAccount = createAsyncThunk<boolean, never | void, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/postValidateBankAccount',
  async (_, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchant/validateaddbank', _, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postValidateWhiteList = createAsyncThunk<boolean, ValidateWhiteListPayload, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/postValidateWhiteList',
  async ({ type }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchant/validateaddwhitelist', {
      type
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postAddBankAccount = createAsyncThunk<any, PostAddBankAccountPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/postAddBankAccount',
  async ({ bankCode, branch, merchantId, name, number }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/AddNewMerchantAccount', {
      bankCode,
      branch,
      merchantId,
      name,
      number
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postAddWhiteList = createAsyncThunk<any, PostAddWhiteListPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'merchant/postAddWhiteList',
  async ({ ipAddress, type, merchantId }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/Merchant/AddNewWhiteList', {
      ipAddress,
      type,
      merchantId
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const merchantDetailsState: MerchantDetailsState = {
  getMerchantDetailsLoading: false,
  postMerchantDetailsLoading: false,
  getBankAccountsLoading: false,
  getWhitelistsLoading: false,
  getCurrenciesSupportedLoading: false,
  postUpdateBankAccountLoading: false,
  postUpdateWhiteListLoading: false,
  postValidateBankAccountLoading: false,
  postValidateBankAccountDetail: false,
  postValidateWhiteListLoading: false,
  postValidateWhiteListDetail: false,
  postAddBankAccountLoading: false,
  postAddWhiteListLoading: false,
  merchantDetails: {
    name: '',
    code: '',
    securityCode: '',
    currenciesSupported: [],
    transactionRate: 0,
    withdrawRate: 0,
    website: '',
    status: 0,
    byUser: '',
    isAdmin: false
  },
  bankAccounts: {
    data: [],
    total: 0
  },
  whiteLists: {
    data: [],
    total: 0
  },
  currenciesSupported: {
    data: [],
    total: 0
  }
}

// Slices
const merchantDetailsSlice = createSlice({
  name: 'merchant',
  initialState: merchantDetailsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantDetails.pending, (state) => {
        state.getMerchantDetailsLoading = true
      })
      .addCase(getMerchantDetails.fulfilled, (state, action) => {
        state.merchantDetails = {
          name: action.payload.name,
          code: action.payload.code,
          securityCode: action.payload.securityCode,
          currenciesSupported: action.payload.currenciesSupported,
          transactionRate: action.payload.transactionRate,
          withdrawRate: action.payload.withdrawRate,
          website: action.payload.website,
          status: action.payload.status,
          byUser: action.payload.byUser,
          isAdmin: action.payload.isAdmin
        }
      })
      .addCase(postMerchantDetails.pending, (state) => {
        state.postMerchantDetailsLoading = true
      })
      .addCase(getBankAccounts.pending, (state) => {
        state.getBankAccountsLoading = true
      })
      .addCase(getWhitelists.pending, (state) => {
        state.getWhitelistsLoading = true
      })
      .addCase(getCurrenciesSupported.pending, (state) => {
        state.getCurrenciesSupportedLoading = true
      })
      .addCase(postUpdateBankAccount.pending, (state) => {
        state.postUpdateBankAccountLoading = true
      })
      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.bankAccounts.data = action.payload.data
        state.bankAccounts.total = action.payload.total
      })
      .addCase(getWhitelists.fulfilled, (state, action) => {
        state.whiteLists.data = action.payload.data
        state.whiteLists.total = action.payload.total
      })
      .addCase(getCurrenciesSupported.fulfilled, (state, action) => {
        state.currenciesSupported.data = action.payload.data
        state.currenciesSupported.total = action.payload.total
      })
      .addCase(postValidateBankAccount.pending, (state) => {
        state.postValidateBankAccountLoading = true
      })
      .addCase(postValidateBankAccount.fulfilled, (state, action) => {
        state.postValidateBankAccountDetail = action.payload
      })
      .addCase(postValidateWhiteList.pending, (state) => {
        state.postValidateWhiteListLoading = true
      })
      .addCase(postValidateWhiteList.fulfilled, (state, action) => {
        state.postValidateWhiteListDetail = action.payload
      })
      .addCase(postAddBankAccount.pending, (state) => {
        state.postAddBankAccountLoading = true
      })
      .addCase(postAddWhiteList.pending, (state) => {
        state.postAddWhiteListLoading = true
      })
      .addCase(postUpdateWhiteList.pending, (state) => {
        state.postUpdateWhiteListLoading = true
      })
      .addMatcher(isAnyOf(getMerchantDetails.fulfilled,
        getMerchantDetails.rejected,
        postMerchantDetails.fulfilled,
        postMerchantDetails.rejected,
        getBankAccounts.fulfilled,
        getBankAccounts.rejected,
        postUpdateBankAccount.fulfilled,
        postUpdateBankAccount.rejected,
        postAddBankAccount.fulfilled,
        postAddBankAccount.rejected,
        getWhitelists.fulfilled,
        getWhitelists.rejected,
        postUpdateWhiteList.fulfilled,
        postUpdateWhiteList.rejected,
        postAddWhiteList.fulfilled,
        postAddWhiteList.rejected,
        getCurrenciesSupported.fulfilled,
        getCurrenciesSupported.rejected
      ), (state) => {
        state.getMerchantDetailsLoading = false
        state.postMerchantDetailsLoading = false
        state.getBankAccountsLoading = false
        state.postUpdateBankAccountLoading = false
        state.postAddBankAccountLoading = false
        state.getWhitelistsLoading = false
        state.postUpdateWhiteListLoading = false
        state.postAddWhiteListLoading = false
        state.getCurrenciesSupportedLoading = false
      })
      .addMatcher(isAnyOf(postValidateBankAccount.fulfilled,
        postValidateBankAccount.rejected,
        postValidateWhiteList.fulfilled,
        postValidateWhiteList.rejected
      ), (state) => {
        state.postValidateBankAccountLoading = false
        state.postValidateWhiteListLoading = false
      })
  }
})

// Selectors
export const selectGetMerchantDetailsLoading = (state: RootState): boolean => state.merchantDetailsReducer.getMerchantDetailsLoading
export const selectPostMerchantDetailsLoading = (state: RootState): boolean => state.merchantDetailsReducer.postMerchantDetailsLoading
export const selectMerchantDetails = (state: RootState): GetMerchantDetailsState => state.merchantDetailsReducer.merchantDetails
export const selectGetBankAccountsLoading = (state: RootState): boolean => state.merchantDetailsReducer.getBankAccountsLoading
export const selectBankAccounts = (state: RootState): GetBankAccountAndWhiteListState => state.merchantDetailsReducer.bankAccounts
export const selectPostUpdateBankAccountLoading = (state: RootState): boolean => state.merchantDetailsReducer.postUpdateBankAccountLoading
export const selectPostValidateBankAccountLoading = (state: RootState): boolean => state.merchantDetailsReducer.postValidateBankAccountLoading
export const selectPostValidateBankAccountDetail = (state: RootState): boolean => state.merchantDetailsReducer.postValidateBankAccountDetail
export const selectPostAddBankAccountLoading = (state: RootState): boolean => state.merchantDetailsReducer.postAddBankAccountLoading
export const selectGetWhiteListsLoading = (state: RootState): boolean => state.merchantDetailsReducer.getWhitelistsLoading
export const selectWhiteLists = (state: RootState): GetBankAccountAndWhiteListState => state.merchantDetailsReducer.whiteLists
export const selectPostValidateWhiteListLoading = (state: RootState): boolean => state.merchantDetailsReducer.postValidateWhiteListLoading
export const selectPostValidateWhiteListDetail = (state: RootState): boolean => state.merchantDetailsReducer.postValidateWhiteListDetail
export const selectPostUpdateWhiteListLoading = (state: RootState): boolean => state.merchantDetailsReducer.postUpdateWhiteListLoading
export const selectPostAddWhiteListLoading = (state: RootState): boolean => state.merchantDetailsReducer.postAddWhiteListLoading
export const selectCurrenciesSupported = (state: RootState): GetBankAccountAndWhiteListState => state.merchantDetailsReducer.currenciesSupported
export const selectGetCurrenciesSupportedLoading = (state: RootState): boolean => state.merchantDetailsReducer.getCurrenciesSupportedLoading

export default merchantDetailsSlice.reducer
