import styled from 'styled-components'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { Row, Button } from 'antd'
import { FiCopy } from 'react-icons/fi'

const MerchantEyeOutline = styled(EyeOutlined)`
  color: ${props => props.theme.colors.primaryColor};
`
const MerchantEyeInvisibleOutlined = styled(EyeInvisibleOutlined)`
  color: ${props => props.theme.colors.primaryColor};
`
const CopyIcon = styled(FiCopy)`
  color: ${props => props.theme.colors.primaryColor};
  cursor: pointer;
`
const LabelText = styled.span`
  border-radius: 4px;
  padding: 2px 10px;
`
const ValueText = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  height: 32px;
  padding-left: 10px;
`
const ShowIconContainer = styled.span`
  margin: 0 10px;
`
const MaskSecurityCode = styled.span`
  vertical-align: middle;
`
const RowContainer = styled(Row)`
  margin: 10px 0;
`
const MerchantDetailButtonsContainer = styled.div`
  > button {
    margin: 0 16px 0 0;

    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
  }
`
const BankAndWhiteListButtonsContainer = styled.div`
  margin: 16px 0 0;
  text-align: right;

  > button {
    margin: 0 0 0 16px;

    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
  }
`
const ButtonWithIcon = styled(Button)`
  > svg {
    margin-right: 5px;
  }
`

export {
  MerchantEyeOutline,
  MerchantEyeInvisibleOutlined,
  LabelText,
  ValueText,
  ShowIconContainer,
  CopyIcon,
  MaskSecurityCode,
  RowContainer,
  MerchantDetailButtonsContainer,
  BankAndWhiteListButtonsContainer,
  ButtonWithIcon
}
