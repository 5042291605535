import styled from 'styled-components'

const ChangePasswordPageButtonsContainer = styled.div`
  display: flex;

    > div, button {
        margin: 0 16px 0 0;

        @media (max-width: 576px) {
            margin: 0 0 16px;
        }
    }

    @media (max-width: 576px) {
      display: block;
    }
`

export {
  ChangePasswordPageButtonsContainer
}
