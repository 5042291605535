import { Button } from 'antd'
import styled from 'styled-components'

const RoleManagementContent = styled.div`
    background: ${props => props.theme.colors.secondaryColor};
    border-radius: 4px;
    border: 1px solid #dee2e6;
    box-shadow: 0 1px 2px 0 rgb(31 45 61 / 7%);
    margin-bottom: 20px;
    padding: 20px;
`

const RoleManagementButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  > button {
    margin-right: 7px;
  }
`
const DisableButton = styled(Button)`
  background: ${props => props.theme.colors.disableColor};
  border-color: ${props => props.theme.colors.disableColor};
  color: ${props => props.theme.colors.secondaryColor};

  &:hover, &:active, &:focus {
    background: ${props => props.theme.colors.disableColor};
    border-color: ${props => props.theme.colors.disableColor};
    color: ${props => props.theme.colors.secondaryColor};
    opacity: 0.8;
  }
`

const InactiveButton = styled(Button)`
  background: ${props => props.theme.colors.enableColor};
  border-color: ${props => props.theme.colors.enableColor};

  &:hover, &:active, &:focus {
    background: ${props => props.theme.colors.enableColor};
    border-color: ${props => props.theme.colors.enableColor};
    opacity: 0.8;
  }
`

const ButtonWithIcon = styled(Button)`
  margin-right: 10px;
  > svg {
    margin-right: 5px;
  }
`

const TableContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
`

const RoleManagementModalContainer = styled.div`
  margin: 16px 0 0;
  text-align: right;

  > button {
    margin: 0 0 0 16px;

    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
  }
`

export {
  RoleManagementContent,
  RoleManagementButtonContainer,
  DisableButton,
  ButtonWithIcon,
  TableContainer,
  InactiveButton,
  RoleManagementModalContainer
}
