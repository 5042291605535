import React, { useState, useEffect } from 'react'
import { AuthLayoutStateProps } from './interfaces'
import { Menu, Dropdown, Grid } from 'antd'
import {
  LayoutWrapper,
  LayoutHeader,
  HeaderMenuButton,
  HeaderMenuButtonIcon,
  MainLayout,
  LayoutContent,
  MainContent,
  SidebarLogoContainer,
  SidebarLogo,
  HeaderCurrencyButton,
  AppSider
} from './styles'
import { customTheme } from '../..'
import navigationRoutes from '../../navigation/navigationRoutes'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { logOut, selectRolePermission, selectSelectList } from '../LoginPage/reducers'
import { persistor } from '../../store/store'
import Footer from '../../components/Footer'
import Icon, { UserOutlined } from '@ant-design/icons'
import { getCurrencyFlag } from '../../utils/utils'
import { selectedGlobalCurrency, selectGlobalCurrency } from './reducers'
import { ImProfile } from 'react-icons/im'
import { CgPassword } from 'react-icons/cg'
import { FiLogOut } from 'react-icons/fi'

const { SubMenu } = Menu
const { useBreakpoint } = Grid

// submenu keys of first level
const rootSubmenuKeys = ['/transaction', '/report']

const AuthLayout = (props: AuthLayoutStateProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const { children, history, location } = props
  const subMenuMatchPath = `/${location.pathname.split('/')[1]}` // this will make sure submenu parent will be open when children are selected
  const [openKeys, setOpenKeys] = useState<string[]>([subMenuMatchPath])
  const selectList = useAppSelector(selectSelectList)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const rolePermission = useAppSelector(selectRolePermission)
  const canViewProfile = rolePermission?.includes('PROFILE_VIEW')
  const canChangePassword = rolePermission?.includes('CHANGE_PASSWORD_VIEW')
  const merchantCurrenciesSelectList = selectList.merchantCurrenciesSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    }))

  const HeaderMenu = (): JSX.Element => {
    return (
      <Menu onClick={onClickHeaderMenu}>
        {canViewProfile && <Menu.Item key='/profile' icon={<ImProfile />}>Profile</Menu.Item>}
        {canChangePassword && <Menu.Item key='/change-password' icon={<CgPassword />}>Change Password</Menu.Item>}
        <Menu.Item key='logout' icon={<FiLogOut />}>Log out</Menu.Item>
      </Menu>
    )
  }

  const HeaderCurrencyMenu = (): JSX.Element => {
    return (
      <Menu onClick={onClickCurrencyMenu} selectedKeys={[globalCurrency]}>
        {
          merchantCurrenciesSelectList.map((currency) => (
            <Menu.Item key={currency.label} icon={<Icon style={{ fontSize: 16 }} component={getCurrencyFlag(currency.label)} />}>
              {currency.label}
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }

  function onClickCurrencyMenu ({ key }: any): void {
    dispatch(selectedGlobalCurrency(key))
  }

  async function onClickHeaderMenu ({ key }: any): Promise<any> {
    if (key === 'logout') {
      await persistor.purge()
      await dispatch(logOut())
      return window.location.reload()
    } else {
      history.push(key)
    }
  }

  function onOpenChangeMenu (keys: any[]): void {
    const latestOpenKey = keys.find(key => !openKeys.includes(key))

    if (!rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey !== undefined ? [latestOpenKey] : [])
    }
  }

  function onClickMenuItem ({ key }: any): void {
    return history.push(key)
  }

  function onClickSideBarLogo (): void {
    return history.push('/')
  }

  useEffect(() => {
    if (globalCurrency === '') {
      dispatch(selectedGlobalCurrency(merchantCurrenciesSelectList[0].label))
    }
  }, [dispatch, globalCurrency, merchantCurrenciesSelectList])

  return (
    <LayoutWrapper>
      <AppSider
        breakpoint='xl'
        collapsedWidth='50'
        width='250'
        collapsible
      >
        <SidebarLogoContainer>
          <SidebarLogo
            src={(screens.xs === true ||
              screens.sm === true ||
              screens.md === true ||
              screens.lg === true) &&
              (screens.xl === false) ? customTheme.logoIcon : customTheme.logo}
            alt={customTheme.title}
            onClick={onClickSideBarLogo}
          />
        </SidebarLogoContainer>
        <Menu
          mode='inline'
          selectedKeys={[location.pathname]}
          openKeys={openKeys}
          onOpenChange={onOpenChangeMenu}
          onClick={onClickMenuItem}
        >
          {
            navigationRoutes.map(({ name: namePrimary, path: pathPrimary, icon: IconPrimary, roles, subRoutesPrimary }, indexRoute) => {
              // if (rolePermission.includes(roles)) { // make sure role are included in the menu property
              if (subRoutesPrimary === undefined) { // Main Routes
                return (
                  roles?.some(r => rolePermission?.includes(r)) &&
                    <React.Fragment key={indexRoute}>
                      <Menu.Item key={pathPrimary} icon={IconPrimary}>
                        {namePrimary}
                      </Menu.Item>
                    </React.Fragment>
                )
              } else { // 1st Level SubRoutes
                return (
                  roles?.some(r => rolePermission?.includes(r)) &&
                    <SubMenu key={pathPrimary} title={namePrimary} icon={IconPrimary}>
                      {
                        subRoutesPrimary.map(({ name: nameSecondary, path: pathSecondary, icon: IconSecondary, roles: rolesSecondary }) =>
                          rolePermission?.includes(rolesSecondary) &&
                            <Menu.Item key={pathSecondary} icon={IconSecondary}>
                              {nameSecondary}
                            </Menu.Item>
                        )
                      }
                    </SubMenu>
                )
              }
            }

              // return null
            )
          }
        </Menu>
      </AppSider>
      <MainLayout>
        <LayoutHeader>
          {
            merchantCurrenciesSelectList.length <= 1
              ? <Icon style={{ fontSize: 32, paddingBottom: 4 }} component={getCurrencyFlag(globalCurrency)} />
              : <Dropdown overlay={HeaderCurrencyMenu} placement='bottomRight'>
                <HeaderCurrencyButton
                  icon={<Icon style={{ fontSize: 32 }} component={getCurrencyFlag(globalCurrency)} />}
                  onClick={e => e.preventDefault()}
                />
              </Dropdown> // eslint-disable-line
          }
          <Dropdown overlay={HeaderMenu}>
            <HeaderMenuButton onClick={e => e.preventDefault()}>
              <HeaderMenuButtonIcon icon={<UserOutlined />} />
            </HeaderMenuButton>
          </Dropdown>
        </LayoutHeader>
        <LayoutContent>
          <MainContent pathname={location.pathname}>
            {
              children
            }
          </MainContent>
        </LayoutContent>
        <Footer />
      </MainLayout>
    </LayoutWrapper>
  )
}

export default AuthLayout
