import { useEffect, useCallback, useState } from 'react'
import { message, Button, Col, Modal, Grid, Select, Input } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import { selectMerchantId, selectRolePermission, selectSelectList } from '../../LoginPage/reducers'
import { getBankAccounts, selectBankAccounts, selectGetBankAccountsLoading, postValidateBankAccount, selectPostValidateBankAccountLoading, selectPostValidateBankAccountDetail } from '../reducers'
import { BankRowDetails, BankAccountFilters } from '../interfaces'
import EditBankForm from './bankforms/EditBankForm'
import AddBankForm from './bankforms/AddBankForm'
import dayjs from 'dayjs'
import { RowContainer, ButtonWithIcon } from '../styles'
import { isNullOrUndefined, getCurrencyValue } from '../../../utils/utils'
import StatusIcon from './StatusIcon'
import { selectGlobalCurrency } from '../../AuthLayout/reducers'
import { BiEdit } from 'react-icons/bi'
import { RiSearchLine, RiFileAddLine } from 'react-icons/ri'
import Table from '../../../components/TableComponent'

const { useBreakpoint } = Grid

const BankAccounts = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const bankAccounts = useAppSelector(selectBankAccounts)
  const getBankAccountsLoading = useAppSelector(selectGetBankAccountsLoading)
  const postValidateBankAccountLoading = useAppSelector(selectPostValidateBankAccountLoading)
  const postValidateBankAccountDetail = useAppSelector(selectPostValidateBankAccountDetail)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const [filters, setFilters] = useState<BankAccountFilters>({
    pageIndex: 1,
    pageSize: 10,
    cardNumber: '',
    merchantId: merchantId,
    status: undefined,
    currency: getCurrencyValue(globalCurrency)
  })
  const [editVisible, setEditVisible] = useState(false)
  const [addVisible, setAddVisible] = useState(false)
  const [rowDetails, setRowDetails] = useState<BankRowDetails>({
    bankCode: '',
    accName: '',
    cardNumber: '',
    branch: '',
    status: 0,
    id: 0
  })
  const rolePermission = useAppSelector(selectRolePermission)
  const canAddBankAccount = rolePermission?.includes('BANK_ACCOUNT_ADD')
  const canEditBankAccount = rolePermission?.includes('BANK_ACCOUNT_EDIT')

  const bankWhitelistStatusList = selectList.bankWhitelistStatusList.map((item: { name: string, value: number }) =>
    ({
      label: item.name,
      value: item.value
    })
  )

  const columns: ColumnsType<any> = [
    {
      title: 'Bank',
      dataIndex: 'bankCode',
      sorter: (a, b) => a.bankCode.localeCompare(b.bankCode),
      ellipsis: true
    },
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      sorter: (a, b) => a.bankName.localeCompare(b.bankName),
      ellipsis: true
    },
    {
      title: 'Account Name',
      dataIndex: 'accName',
      sorter: (a, b) => a.accName.localeCompare(b.accName),
      ellipsis: true
    },
    {
      title: 'Account Number',
      dataIndex: 'cardNumber',
      sorter: (a, b) => a.cardNumber - b.cardNumber,
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'statusText',
      sorter: (a, b) => a.statusText.localeCompare(b.statusText),
      ellipsis: true,
      align: 'center',
      render: status => <StatusIcon status={status} />
    },
    {
      title: 'Remarks',
      dataIndex: 'branch',
      ellipsis: true,
      sorter: (a, b) => a.branch.localeCompare(b.branch)
    },
    {
      title: 'Registered Date',
      dataIndex: 'registerDate',
      ellipsis: true,
      sorter: (a, b) => a.registerDate.localeCompare(b.registerDate),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('DD/MM/YYYY HH:mm')
    },
    ...canEditBankAccount ? [{
      title: 'Action',
      dataIndex: 'Action',
      fixed: 'right' as 'right',
      align: 'center' as 'center',
      render: (_: any, row: any) => {
        return (
          <Button shape='circle' type='primary' onClick={() => onHandleEdit(row)}>
            <BiEdit />
          </Button>
        )
      }
    }] : []
  ]

  const getBankAccountsAction = useCallback(async (): Promise<any> => {
    const payload = {
      merchantId,
      pageIndex: 1,
      pageSize: 10,
      cardNumber: '',
      status: undefined,
      currency: getCurrencyValue(globalCurrency)
    }

    try {
      await dispatch(getBankAccounts(payload)).then(unwrapResult)
      await dispatch(postValidateBankAccount())
    } catch (error: any) {
      return await message.error(error.message)
    }
  }, [dispatch, merchantId, globalCurrency])

  function onHandleOnChangePagination (page: number, pageSize: number | undefined): any {
    setFilters(prevState => ({
      ...prevState,
      pageIndex: page,
      pageSize: pageSize
    }))
    return dispatch(getBankAccounts({ ...filters, pageIndex: page, pageSize: pageSize }))
  }

  function onHandleAddUser (): void {
    setAddVisible(true)
  }

  function onHandleEdit (row: BankRowDetails): void {
    setRowDetails({
      bankCode: row.bankCode,
      accName: row.accName,
      cardNumber: row.cardNumber,
      branch: row.branch,
      status: row.status,
      id: row.id
    })
    setEditVisible(true)
  }

  function onHandleChangeFilters (value: any, name: string): void {
    setFilters(prevState => ({
      ...prevState,
      [name]: value === null ? '' : value
    }))
  }

  async function onHandleSubmitFilters (): Promise<any> {
    const payload = {
      ...filters,
      pageIndex: 1
    }
    try {
      await dispatch(getBankAccounts(payload)).then(unwrapResult)
      setFilters(prevState => {
        return { ...prevState, pageIndex: 1 }
      })
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  useEffect(() => {
    getBankAccountsAction().finally(() => {})
  }, [getBankAccountsAction])

  useEffect(() => {
    setFilters(f => {
      return {
        ...f,
        currency: getCurrencyValue(globalCurrency)
      }
    })
  }, [globalCurrency])

  return (
    <>
      <RowContainer gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={7} xxl={5}>
          <Select
            placeholder='Select Status'
            style={{ width: '100%' }}
            defaultValue={filters.status}
            value={filters.status}
            onChange={(value) => onHandleChangeFilters(value, 'status')}
            options={bankWhitelistStatusList}
          />
        </Col>
        <Col xs={24} sm={12} lg={7} xxl={5}>
          <Input
            placeholder='Card Number'
            value={filters.cardNumber}
            onChange={(e: any) => {
              const reg = new RegExp('^[0-9]+$')
              if (reg.test(e.target.value) || e.target.value === '') {
                onHandleChangeFilters(e.target.value, 'cardNumber')
              }
            }}
          />
        </Col>
        <Col xs={24} sm={6} md={5} lg={4} xxl={3}>
          <ButtonWithIcon
            shape='round'
            type='primary'
            block={screens.xs}
            onClick={onHandleSubmitFilters}
          >
            <RiSearchLine /> Search
          </ButtonWithIcon>
        </Col>
        {
          canAddBankAccount &&
            <Col style={{ textAlign: 'right' }} xs={24} sm={{ span: 5, offset: 13 }} md={{ span: 5, offset: 14 }} lg={{ span: 3, offset: 3 }} xxl={{ span: 2, offset: 9 }}>
              <ButtonWithIcon
                type='primary'
                onClick={onHandleAddUser}
                loading={postValidateBankAccountLoading}
                disabled={!postValidateBankAccountDetail}
                block={screens.xs}
                shape='round'
              >
                <RiFileAddLine />Add
              </ButtonWithIcon>
            </Col>
        }
      </RowContainer>
      <Table
        dataSource={bankAccounts.data}
        loading={getBankAccountsLoading}
        columns={columns}
        current={filters.pageIndex}
        pageSize={filters.pageSize}
        total={bankAccounts.total}
        onChange={onHandleOnChangePagination}
      />
      <Modal
        open={addVisible}
        title='Add Bank Account'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <AddBankForm setAddVisible={setAddVisible} filters={filters} />
      </Modal>
      <Modal
        open={editVisible}
        title='Edit Bank Account'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <EditBankForm {...rowDetails} setEditVisible={setEditVisible} filters={filters} />
      </Modal>
    </>
  )
}

export default BankAccounts
