import { Form, Input, Select, Button, Row, Col, InputNumber, message, Grid } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { getSelectList, selectSelectList } from '../../../LoginPage/reducers'
import { postCreateUser, selectPostCreateUserLoading, getUserList } from '../reducers'
import { CreateUserPageButtonsContainer } from '../styles'
import { CreateUserFormState } from '../interfaces'
import { useCallback, useEffect } from 'react'

const { useBreakpoint } = Grid

const CreateUserForm = (props: CreateUserFormState): JSX.Element => {
  const { setAddVisible } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const postCreateUserLoading = useAppSelector(selectPostCreateUserLoading)
  const merchantUserRolesOptions = selectList.merchantUserRolesSelectList.map((item: { name: string, value: number }) => ({
    label: item.name,
    value: item.value
  })).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)
  const [form] = Form.useForm()

  async function onHandleCreateUserSubmit (values: any): Promise<any> {
    const payload = {
      pageIndex: 1,
      pageSize: 10
    }

    try {
      await dispatch(postCreateUser(values)).then(unwrapResult)
      await dispatch(getUserList(payload))
      await setAddVisible(false)
      return await message.success('Create User Success!')
    } catch (error: any) {
      await message.error(error.message)
      return form.resetFields()
    }
  }
  const getSelectListAction = useCallback(async (): Promise<any> => {
    await dispatch(getSelectList())
  }, [dispatch])
  useEffect(() => {
    getSelectListAction().finally(() => {})
  }, [getSelectListAction])

  function onHandleCancel (): void {
    setAddVisible(false)
  }

  return (
    <Form
      name='user-detail-form'
      onFinish={onHandleCreateUserSubmit}
      requiredMark={false}
      layout='vertical'
      form={form}
      initialValues={{
        userName: '',
        fullname: '',
        phone: '',
        password: '',
        email: '',
        merchantUserRoles: []
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Username'
            name='userName'
            rules={[{ required: true, message: 'Username is required.' }]}
          >
            <Input placeholder='Username' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Fullname'
            name='fullname'
            rules={[{ required: true, message: 'Fullname is required.' }]}
          >
            <Input placeholder='Fullname' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Phone'
            name='phone'
          >
            <InputNumber placeholder='Phone' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Password'
            name='password'
            rules={[{ required: true, message: 'Password is required.' }]}
          >
            <Input placeholder='Password' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Email'
            name='email'
          >
            <Input placeholder='Email' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='User Role'
            name='merchantUserRoles'
          >
            <Select
              mode='multiple'
              placeholder='Select Roles'
              options={merchantUserRolesOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <CreateUserPageButtonsContainer>
        <Button
          type='primary'
          shape='round'
          htmlType='submit'
          block={screens.xs}
          loading={postCreateUserLoading}
        >
          Submit
        </Button>
        <Button
          shape='round'
          onClick={onHandleCancel}
          block={screens.xs}
        >
          Cancel
        </Button>
      </CreateUserPageButtonsContainer>
    </Form>
  )
}

export default CreateUserForm
