import { useState, useEffect } from 'react'
import { message, Row, Col, Select, Grid } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { TableContainer } from '../../../components/styles'
import { DailyBalanceFilters } from './interfaces'
import { getDailyBalance, selectDailyBalance, selectDailyBalanceLoading, exportDailyBalanceExcel, selectExportDailyBalanceExcelLoading } from './reducers'
import NumberFormat from 'react-number-format'
import { getCurrencyValue } from '../../../utils/utils'
import { selectGlobalCurrency } from '../../AuthLayout/reducers'
import { RiSearchLine } from 'react-icons/ri'
import { SiMicrosoftexcel } from 'react-icons/si'
import { ButtonWithIcon } from './styles'
import Table from '../../../components/TableComponent'
import { selectRolePermission } from '../../LoginPage/reducers'

const { useBreakpoint } = Grid

const DailyBalancePage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const dailyBalance = useAppSelector(selectDailyBalance)
  const dailyBalanceLoading = useAppSelector(selectDailyBalanceLoading)
  const exportDailyBalanceExcelLoading = useAppSelector(selectExportDailyBalanceExcelLoading)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const rolePermission = useAppSelector(selectRolePermission)
  const canExportDailyBalance = rolePermission?.includes('DAILY_BALANCE_EXPORT')
  const [filters, setFilters] = useState<DailyBalanceFilters>({
    currency: getCurrencyValue(globalCurrency),
    month: ''
  })

  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (value: string) => <>{value.slice(0, 10)}</>
    },
    {
      title: 'Opening Balance',
      dataIndex: 'openingBalance',
      sorter: (a, b) => a.openingBalance - b.openingBalance,
      render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Deposit',
      children: [
        {
          title: 'Amount',
          dataIndex: 'deposit',
          sorter: (a, b) => a.deposit - b.deposit,
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Service Fee',
          dataIndex: 'depositFee',
          sorter: (a, b) => a.depositFee - b.depositFee,
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        }
      ]
    },
    {
      title: 'Withdrawal',
      children: [
        {
          title: 'Amount',
          dataIndex: 'withdrawal',
          sorter: (a, b) => a.withdrawal - b.withdrawal,
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Service Fee',
          dataIndex: 'withdrawalServicelFee',
          sorter: (a, b) => a.withdrawalServicelFee - b.withdrawalServicelFee,
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Bank Fee',
          dataIndex: 'withdrawalBankFee',
          sorter: (a, b) => a.withdrawalBankFee - b.withdrawalBankFee,
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        }
      ]
    },
    {
      title: 'Settlement',
      children: [
        {
          title: 'Amount',
          dataIndex: 'settlement',
          sorter: (a, b) => a.settlement - b.settlement,
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Service Fee',
          dataIndex: 'settlementFee',
          sorter: (a, b) => a.settlementFee - b.settlementFee,
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        }
      ]
    },
    {
      title: 'Top Up',
      dataIndex: 'topUp',
      sorter: (a, b) => a.topUp - b.topUp,
      render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Last Balance',
      dataIndex: 'lastBalance',
      sorter: (a, b) => a.lastBalance - b.lastBalance,
      render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
    }
  ]

  const summaryColumns: ColumnsType<any> = [
    {
      title: 'Total Adjustment',
      dataIndex: 'totalAdjustment',
      render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Deposit',
      children: [
        {
          title: 'Amount',
          dataIndex: 'totalDeposit',
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Service Fee',
          dataIndex: 'totalDepositFee',
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        }
      ]
    },
    {
      title: 'Withdrawal',
      children: [
        {
          title: 'Amount',
          dataIndex: 'totalWithdrawal',
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Service Fee',
          dataIndex: 'totalWithdrawalServiceFee',
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Bank Fee',
          dataIndex: 'totalWithdrawalBankFee',
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        }
      ]
    },
    {
      title: 'Settlement',
      children: [
        {
          title: 'Amount',
          dataIndex: 'totalSettlement',
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        },
        {
          title: 'Service Fee',
          dataIndex: 'totalSettlementFee',
          render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
        }
      ]
    },
    {
      title: 'Total Top Up',
      dataIndex: 'totalTopUp',
      render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator />
    }
  ]

  const summaryData = [
    {
      id: 1,
      totalAdjustment: dailyBalance.totalAdjustment === null ? 0 : dailyBalance.totalAdjustment,
      totalDeposit: dailyBalance.totalDeposit === null ? 0 : dailyBalance.totalDeposit,
      totalDepositFee: dailyBalance.totalDepositFee === null ? 0 : dailyBalance.totalDepositFee,
      totalSettlement: dailyBalance.totalSettlement === null ? 0 : dailyBalance.totalSettlement,
      totalSettlementFee: dailyBalance.totalSettlementFee === null ? 0 : dailyBalance.totalSettlementFee,
      totalTopUp: dailyBalance.totalTopUp === null ? 0 : dailyBalance.totalTopUp,
      totalWithdrawal: dailyBalance.totalWithdrawal === null ? 0 : dailyBalance.totalWithdrawal,
      totalWithdrawalBankFee: dailyBalance.totalWithdrawalBankFee === null ? 0 : dailyBalance.totalWithdrawalBankFee,
      totalWithdrawalServiceFee: dailyBalance.totalWithdrawalServiceFee === null ? 0 : dailyBalance.totalWithdrawalServiceFee
    }
  ]

  const monthSelectList = []
  for (let i = 0; i <= 5; i++) {
    const currentMonth = dayjs().startOf('month').format('YYYY/MM')
    const previousMonths = dayjs().subtract(i, 'months').startOf('month').format('YYYY/MM')

    if (i === 0) {
      monthSelectList.push({
        name: currentMonth,
        value: currentMonth
      })
    } else {
      monthSelectList.push({
        name: previousMonths,
        value: previousMonths
      })
    }
  }

  function onHandleChangeFilters (value: any, name: string): void {
    setFilters(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  async function onHandleSubmitFilters (): Promise<any> {
    const month = filters.month.split('/')[1]
    const year = filters.month.split('/')[0]
    const payload = {
      ...filters,
      month: parseInt(month),
      year: parseInt(year)
    }

    try {
      await dispatch(getDailyBalance(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  async function onHandleExportExcel (): Promise<any> {
    const month = filters.month.split('/')[1]
    const year = filters.month.split('/')[0]
    const payload = {
      ...filters,
      month: parseInt(month),
      year: parseInt(year)
    }

    try {
      await dispatch(exportDailyBalanceExcel(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  useEffect(() => {
    setFilters(f => {
      return {
        ...f,
        currency: getCurrencyValue(globalCurrency)
      }
    })
  }, [globalCurrency])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={7} xxl={5}>
          <Select
            placeholder='Select Month'
            style={{ width: '100%' }}
            options={monthSelectList}
            onChange={(value) => onHandleChangeFilters(value, 'month')}
          />
        </Col>
        <Col xs={24} sm={6} md={5} lg={4} xxl={3}>
          <ButtonWithIcon
            type='primary'
            shape='round'
            block={screens.xs}
            onClick={onHandleSubmitFilters}
            disabled={filters.currency === undefined || filters.month === ''}
          >
            <RiSearchLine /> Search
          </ButtonWithIcon>
        </Col>
        {
          canExportDailyBalance &&
            <Col style={{ textAlign: 'right' }} xs={24} sm={{ span: 5, offset: 1 }} md={{ span: 5, offset: 2 }} lg={{ span: 3, offset: 10 }} xxl={{ span: 2, offset: 14 }}>
              <ButtonWithIcon
                type='primary'
                shape='round'
                block={screens.xs}
                disabled={dailyBalance.details !== null && dailyBalance.details.length <= 0}
                onClick={onHandleExportExcel}
                loading={exportDailyBalanceExcelLoading}
              >
                <SiMicrosoftexcel /> Export
              </ButtonWithIcon>
            </Col>
        }
      </Row>
      <TableContainer>
        <Table
          dataSource={dailyBalance.details === null ? [] : dailyBalance.details}
          loading={dailyBalanceLoading}
          columns={columns}
          hasPagination={false}
          footer={() =>
            <Table
              dataSource={summaryData}
              loading={dailyBalanceLoading}
              columns={summaryColumns}
              hasPagination={false}
            />}
        />
      </TableContainer>
    </>
  )
}

export default DailyBalancePage
