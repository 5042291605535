import styled from 'styled-components'
import { Layout } from 'antd'

const { Footer } = Layout

const LayoutFooter = styled(Footer)`
    background: transparent;
    text-align:  center;
    width: 100%;
`
const TableContainer = styled.div`
    margin-top: 16px;
`

export {
  LayoutFooter,
  TableContainer
}
