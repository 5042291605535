import { Form, Input, message } from 'antd'
import { LoginContainer, FormContainer, LogoContainer, Logo, LoginButton, LoginUserOutlined, LoginLockOutlined } from './styles'
import { LoginSubmitValues } from './interfaces'
import { customTheme } from '../..'
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks'
import { postLogin, selectAuthLoading } from './reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import Footer from '../../components/Footer'

const LoginPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const authLoading = useAppSelector(selectAuthLoading)

  async function handleLoginSubmit (values: LoginSubmitValues): Promise<any> {
    try {
      await dispatch(postLogin(values)).then(unwrapResult)
      return await message.success('Login Success!')
    } catch (error: any) {
      return await message.error(error.message, 2)
    }
  }

  return (
    <LoginContainer>
      <FormContainer>
        <LogoContainer>
          <Logo src={customTheme.logo} alt={customTheme.title} />
        </LogoContainer>
        <Form
          name='login-form'
          onFinish={handleLoginSubmit}
        >
          <Form.Item
            name='userName'
            rules={[{
              required: true,
              message: 'Username is required.'
            }]}
          >
            <Input
              prefix={<LoginUserOutlined />}
              placeholder='Username'
              autoComplete='username'
              allowClear
              size='large'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Password is required.' }]}
          >
            <Input.Password
              prefix={<LoginLockOutlined />}
              placeholder='Password'
              autoComplete='current-password'
              allowClear
              size='large'
            />
          </Form.Item>
          <LoginButton size='large' type='primary' htmlType='submit' loading={authLoading}>
            Log In
          </LoginButton>
        </Form>
      </FormContainer>
      <Footer />
    </LoginContainer>
  )
}

export default LoginPage
