import { useCallback, useEffect, useState } from 'react'
import { Table, Button, Row, Col, Modal, Grid, Input, message } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { RouteComponentProps } from 'react-router-dom'
import { TableContainer } from '../../../components/styles'
import { ButtonWithIcon, DisableButton, InactiveButton, RoleManagementButtonContainer, RoleManagementModalContainer } from './styles'
import { ColumnsType } from 'antd/lib/table'
import { RiFileAddLine } from 'react-icons/ri'
import { getRoleManagementList, postAddRoleName, postDisableRole, selectRoleManagementList, selectRoleManagementLoading } from './reducers'
import { RoleManagementTable } from './interfaces'
import { unwrapResult } from '@reduxjs/toolkit'
import { isNullOrUndefined } from '../../../utils/utils'
import dayjs from 'dayjs'
import { selectRolePermission } from '../../LoginPage/reducers'

const { useBreakpoint } = Grid

const RoleManagementPage = (props: RouteComponentProps): JSX.Element => {
  const { history } = props
  const screens = useBreakpoint()
  const dispatch = useAppDispatch()
  const rolePermission = useAppSelector(selectRolePermission)
  const roleManagementList = useAppSelector(selectRoleManagementList)
  const roleManagementLoading = useAppSelector(selectRoleManagementLoading)
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false)
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [addRoleName, setAddRoleName] = useState('')
  const [deactivatePayload, setDeactivatePayload] = useState({ id: 0, role: '', isEnabled: false })
  const canAdd = rolePermission.includes('ROLE_MANAGEMENT_ADD')
  const canEdit = rolePermission.includes('ROLE_MANAGEMENT_EDIT')

  const getRoleManagementListAction = useCallback(async (): Promise<any> => {
    await dispatch(getRoleManagementList()).then(unwrapResult)
  }, [dispatch])

  const postAddRoleNameAction = useCallback(async (payload: object): Promise<any> => {
    try {
      await dispatch(postAddRoleName(payload)).then(unwrapResult)
      setAddRoleName('')
      setAddModalVisible(false)
      getRoleManagementListAction().finally(() => { })
      await message.success(`Successfully Added ${addRoleName}.`)
    } catch (error: any) {
      return await message.error(error.message, 5)
    }
  }, [dispatch, addRoleName, getRoleManagementListAction])

  const postDisableRoleAction = useCallback(async (payload: object): Promise<any> => {
    try {
      await dispatch(postDisableRole(payload)).then(unwrapResult)
      getRoleManagementListAction().finally(() => { })
      await message.success(`Successfully ${!deactivatePayload.isEnabled ? 'deactivate' : 'activate'} ${deactivatePayload.role}.`)
    } catch (error: any) {
      return await message.error(error.message, 5)
    }
  }, [dispatch, getRoleManagementListAction, deactivatePayload])

  useEffect(() => {
    getRoleManagementListAction().finally(() => {})
  }, [getRoleManagementListAction])

  const columns: ColumnsType<RoleManagementTable> = [
    {
      title: 'ID',
      dataIndex: 'id',
      ellipsis: true,
      sorter: (a: any, b: any) => a.id - b.id
    },
    {
      title: 'Roles',
      dataIndex: 'roleName',
      ellipsis: true,
      sorter: (a: any, b: any) => a.roleName.localeCompare(b.roleName)
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      ellipsis: true,
      sorter: (a: any, b: any) => a.createdBy.localeCompare(b.createdBy)
    },
    {
      title: 'Created Time',
      dataIndex: 'createdTime',
      ellipsis: true,
      sorter: (a: any, b: any) => a.createdTime.localeCompare(b.createdTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      ellipsis: true,
      sorter: (a: any, b: any) => a.updatedBy.localeCompare(b.updatedBy)
    },
    {
      title: 'Updated Time',
      dataIndex: 'updatedTime',
      ellipsis: true,
      sorter: (a: any, b: any) => a.updatedTime.localeCompare(b.updatedTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('DD/MM/YYYY HH:mm')
    },
    ...canEdit ? ([
      {
        title: 'Action',
        dataIndex: 'action',
        ellipsis: true,
        align: 'center' as 'center',
        fixed: screens.xs === true ? false : 'right' as 'right',
        render: (_: any, row: any) => {
          const userId: number = row.id
          const role: string = row.roleName
          const isEnabled: boolean = row.isEnabled
          return (
            <RoleManagementButtonContainer>
              <Button type='primary' shape='round' onClick={() => onHandleEdit(row)}>
                Edit
              </Button>
              {
                isEnabled ? (
                  <DisableButton shape='round' onClick={() => onHandleDeactivate(userId, role, !isEnabled)}>
                    Deactivate
                  </DisableButton>
                ) : (
                  <InactiveButton type='primary' shape='round' onClick={() => onHandleDeactivate(userId, role, !isEnabled)}>
                    Activate
                  </InactiveButton>
                )
              }
            </RoleManagementButtonContainer>
          )
        }
      }
    ]) : []

  ]

  const onHandleEdit = (row: {id: number, roleName: string}): void => {
    history.push(`/administrator/role-management/detail/${row.id}`)
  }

  const onHandleDeactivate = (id: number, role: string, isEnabled: boolean): void => {
    setDeactivateModalVisible(true)
    setDeactivatePayload({ id, role, isEnabled })
  }

  const onHandleOnChangePagination = (): void => {}

  const onHandleAddRole = (): void => {
    setAddModalVisible(true)
  }

  const onSubmitRoleModal = (): void => {
    const payload = {
      roleName: addRoleName
    }
    postAddRoleNameAction(payload).finally(() => {})
  }

  const onHandleCancelModals = (): void => {
    setAddRoleName('')
    setDeactivateModalVisible(false)
    setAddModalVisible(false)
  }

  const onHandleDeactivateConfirm = (): void => {
    const { id, isEnabled } = deactivatePayload
    const payload = {
      id,
      isEnabled
    }
    postDisableRoleAction(payload).finally(() => { })
    setDeactivateModalVisible(false)
  }

  return (
    <>
      {
        canAdd &&
          <Row>
            <Col style={{ textAlign: 'right' }} xs={24} sm={{ span: 5, offset: 19 }} md={{ span: 5, offset: 19 }} lg={{ span: 3, offset: 21 }} xxl={{ span: 2, offset: 22 }}>
              <ButtonWithIcon
                type='primary'
                onClick={onHandleAddRole}
                block={screens.xs}
                shape='round'
              >
                <RiFileAddLine />Add
              </ButtonWithIcon>
            </Col>
          </Row>
      }
      <TableContainer>
        <Table
          dataSource={roleManagementList}
          loading={roleManagementLoading}
          scroll={{ x: 1200 }}
          columns={columns}
          rowKey='id'
          size='small'
          tableLayout='auto'
          bordered
          pagination={{
            position: ['topRight'],
            total: 6,
            showQuickJumper: roleManagementList.length > 20,
            showSizeChanger: roleManagementList.length >= 20,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            onChange: onHandleOnChangePagination
          }}
        />
      </TableContainer>
      <Modal
        open={deactivateModalVisible}
        title={`${deactivatePayload.isEnabled ? 'Activate' : 'Deactivate'}`}
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        {`Are you sure you want to ${deactivatePayload.isEnabled ? 'activate' : 'deactivate'} ${deactivatePayload.role}?`}
        <RoleManagementModalContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={onHandleDeactivateConfirm}
          >
            Confirm
          </Button>
        </RoleManagementModalContainer>

      </Modal>
      <Modal
        open={addModalVisible}
        title='Add New Role'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <Input value={addRoleName} onChange={(e: any) => setAddRoleName(e.target.value)} placeholder='New Role' />
        <RoleManagementModalContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={onSubmitRoleModal}
          >
            Confirm
          </Button>
        </RoleManagementModalContainer>

      </Modal>
    </>
  )
}

export default RoleManagementPage
