import styled from 'styled-components'
import { Layout, Button, Avatar } from 'antd'
import { MainContentProps } from './interfaces'

const { Header, Content } = Layout

const LayoutWrapper = styled(Layout)`
    min-height: 100%;
`
const LayoutHeader = styled(Header)`
    align-items: center;
    background: ${props => props.theme.colors.secondaryColor};
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0 20px;
    z-index: 19;
`
const HeaderMenuButton = styled(Button)`
    border: 0;
    box-shadow: none;
    height: 100%;
    padding: 0 15px;
`
const HeaderMenuButtonIcon = styled(Avatar)`
    background: ${props => props.theme.colors.primaryColor};
    vertical-align: sub;
`
const MainLayout = styled(Layout)`
    background: ${props => props.theme.colors.primaryBackground};
    min-height: 100vh;
`
const LayoutContent = styled(Content)`
    margin: 0 20px;
    background: ${props => props.theme.colors.primaryBackground};
`
const MainContent = styled.div<MainContentProps>`
    background: ${props => (props.pathname === '/dashboard' || props.pathname === '/transaction/topup') ? 'transparent' : props.theme.colors.secondaryColor};
    border-radius: 4px;
    border: ${props => (props.pathname !== '/dashboard' && props.pathname !== '/transaction/topup') && '1px solid #dee2e6'};
    box-shadow: ${props => (props.pathname !== '/dashboard' && props.pathname !== '/transaction/topup') && '0 1px 2px 0 rgb(31 45 61 / 7%)'};
    margin-top: 32px;
    min-height: 360px;
    padding: ${props => (props.pathname === '/dashboard' || props.pathname === '/transaction/topup') ? '0' : '20px'};
`
const SidebarLogoContainer = styled.div`
    margin: 15px 0 5px;
    text-align: center;
`
const SidebarLogo = styled.img`
    height: auto;
    max-width: 130px;
    width: 100%;
    cursor: pointer;

    @media (max-width: 1200px) {
        max-width: 32px;
    }
`
const HeaderCurrencyButton = styled(Button)`
    border: 0;
    box-shadow: none;
    height: 100%;
    padding: 0;
`

const AppSider = styled(Layout.Sider)`
  background-color: ${props => props.theme.colors.secondaryColor} !important;
  .ant-layout-sider-trigger {
    background-color: ${props => props.theme.colors.secondaryColor} !important;
    > span > svg {
      color: ${props => props.theme.colors.primaryColor}
    }
  };
`

export {
  LayoutWrapper,
  LayoutHeader,
  HeaderMenuButton,
  HeaderMenuButtonIcon,
  LayoutContent,
  MainContent,
  SidebarLogoContainer,
  SidebarLogo,
  MainLayout,
  HeaderCurrencyButton,
  AppSider
}
