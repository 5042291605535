import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import api from '../../../utils/api'
import { GetState, RejectWithValueState, DepositState, GetDepositsState, GetDepositPayloadState } from './interfaces'
import dayjs from 'dayjs'
import { clientTimeZone } from '../../../utils/utils'

// Async Requests
export const getDeposit = createAsyncThunk<GetDepositsState, GetDepositPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/getDeposit',
  async ({ bankIds, currencies, toCurrencies, endDate, pageIndex, pageSize, searchTerm, searchType, startDate, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchantDeposit/search', {
      bankIds,
      currencies,
      toCurrencies,
      endDate,
      pageIndex,
      pageSize,
      searchTerm,
      searchType,
      startDate,
      clientTimeZone,
      status
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const exportDepositExcel = createAsyncThunk<any, GetDepositPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/exportDepositExcel',
  async ({ bankIds, currencies, endDate, toCurrencies, pageIndex, pageSize, searchTerm, searchType, startDate, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchantDeposit/exportdeposit', {
      bankIds,
      currencies,
      endDate,
      pageIndex,
      pageSize,
      searchTerm,
      searchType,
      startDate,
      status,
      toCurrencies,
      clientTimeZone
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/vnd.ms-excel'
      },
      responseType: 'blob'
    })

    const currentDay = dayjs(Date.now()).format('YYYYMMDDHHmmss')
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `MerchantTransactionDeposit-${currentDay}.xlsx`)
    document.body.appendChild(link)
    link.click()
    link.remove()

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const depositState: DepositState = {
  getDepositLoading: false,
  deposits: {
    data: [],
    total: 0
  },
  exportDepositExcelLoading: false
}

// Slices
const depositSlice = createSlice({
  name: 'deposit',
  initialState: depositState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeposit.fulfilled, (state, action) => {
        state.deposits.data = action.payload.data
        state.deposits.total = action.payload.total
      })
      .addCase(getDeposit.pending, (state) => {
        state.getDepositLoading = true
      })
      .addCase(exportDepositExcel.pending, (state) => {
        state.exportDepositExcelLoading = true
      })
      .addMatcher(isAnyOf(getDeposit.fulfilled,
        getDeposit.rejected,
        exportDepositExcel.fulfilled,
        exportDepositExcel.rejected), (state) => {
        state.getDepositLoading = false
        state.exportDepositExcelLoading = false
      })
  }
})

// Selectors
export const selectGetDepositLoading = (state: RootState): boolean => state.depositReducer.getDepositLoading
export const selectDeposits = (state: RootState): GetDepositsState => state.depositReducer.deposits
export const selectExportDepositExcelLoading = (state: RootState): boolean => state.depositReducer.exportDepositExcelLoading

export default depositSlice.reducer
