import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import { AuthLayoutState } from './interfaces'

// Initial States
const authLayoutInitialState: AuthLayoutState = {
  globalCurrency: ''
}

// Slices
const authLayoutSlice = createSlice({
  name: 'authLayout',
  initialState: authLayoutInitialState,
  reducers: {
    selectedGlobalCurrency: (state, action) => {
      state.globalCurrency = action.payload
    }
  }
})

// selectors
export const selectGlobalCurrency = (state: RootState): string => state.authLayoutReducer.globalCurrency

// actions
export const { selectedGlobalCurrency } = authLayoutSlice.actions

export default authLayoutSlice.reducer
