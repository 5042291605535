import { WhiteListRowDetails, EditWhiteListState } from '../../interfaces'
import { Form, Input, Select, Button, Row, Col, message, Grid } from 'antd'
import { selectSelectList, selectMerchantId } from '../../../LoginPage/reducers'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { postValidateWhiteList, getWhitelists, postUpdateWhiteList, selectPostUpdateWhiteListLoading } from '../../reducers'
import { BankAndWhiteListButtonsContainer } from '../../styles'
import { inputCheckIp } from '../../../../utils/utils'

const { useBreakpoint } = Grid

const EditWhiteListForm = (props: WhiteListRowDetails & EditWhiteListState): JSX.Element => {
  const { id, ipAddress, status, type, setEditVisible, filters } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const postUpdateWhiteListLoading = useAppSelector(selectPostUpdateWhiteListLoading)
  const [form] = Form.useForm()
  const whiteListTypeSelectList = selectList.whiteListTypeSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)
  const whiteListStatusSelectList = selectList.whiteListStatusSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  async function onHandleEditWhiteListSubmit (values: any): Promise<any> {
    const editSubmitPayload = {
      id,
      ipAddress: values.ipAddress,
      merchantId,
      status: values.status,
      type: values.type
    }
    const filtersPayload = {
      ...filters
    }

    try {
      await dispatch(postUpdateWhiteList(editSubmitPayload)).then(unwrapResult)
      await dispatch(getWhitelists(filtersPayload))
      await dispatch(postValidateWhiteList({ type: filters.ipType }))
      await setEditVisible(false)
      return await message.success('Updated White List Success!')
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  function onHandleCancel (): void {
    setEditVisible(false)
  }

  function onHandleChangeFilters (value: any, name: string): void {
    if (name === 'ipAddress' && !inputCheckIp(value) && value != null) {
      value = value.slice(0, -1)
    }
    form.setFieldValue('ipAddress', value)
  }

  return (
    <Form
      name='edit-whitelist-form'
      onFinish={onHandleEditWhiteListSubmit}
      requiredMark={false}
      layout='vertical'
      form={form}
      initialValues={{
        id,
        ipAddress,
        status,
        type
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item
            label='IP Type'
            name='type'
          >
            <Select placeholder='Select Ip Type' options={whiteListTypeSelectList} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label='IP Address'
            name='ipAddress'
            rules={[{ required: true, message: 'IP Address is required.' }]}
          >
            <Input placeholder='IP Address' onChange={(e) => onHandleChangeFilters(e.target.value, 'ipAddress')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Status'
            name='status'
          >
            <Select placeholder='Select Status' options={whiteListStatusSelectList} />
          </Form.Item>
        </Col>
      </Row>
      <BankAndWhiteListButtonsContainer>
        <Button
          onClick={onHandleCancel}
          block={screens.xs}
          shape='round'
        >
          Cancel
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          block={screens.xs}
          shape='round'
          loading={postUpdateWhiteListLoading}
        >
          Update
        </Button>
      </BankAndWhiteListButtonsContainer>
    </Form>
  )
}

export default EditWhiteListForm
