import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { getTransactionCallback, transactionCallbackData } from './reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import useOnMountEffect from '../../../../hooks/useOnMountEffect'
import { CallbackData, ExpandedDataProps } from './interface'

import styled from 'styled-components'
import { ColumnsType } from 'antd/es/table'
import TableComponent from '../../../../components/TableComponent'

const StyledTable = styled(TableComponent)`
  margin: 0.5em;
  border: 1px solid ${props => props.theme.colors.primaryColor};
  border-radius: 8px 8px;
  padding: 8px 8px 13px 4px;
  .ant-table {
    margin-left: -4px !important;
  }
`
const ExpandedData = ({ ...props }: ExpandedDataProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const propsRef = useRef<ExpandedDataProps | null>(null)

  const [loading, setLoading] = useState(true)
  const cachedData = useAppSelector(transactionCallbackData)
  const callbackData = cachedData[props.transactionType][props.transactionId] ?? {}
  useEffect(() => {
    if (propsRef.current !== props) {
      propsRef.current = props
    }
  }, [props])
  const columns: ColumnsType<CallbackData> = [
    {
      title: 'Log No.',
      dataIndex: 'createdTime',
      ellipsis: true,
      render: (_, __, index) => callbackData?.data?.length - index
    },
    {
      title: 'Status',
      dataIndex: 'responseStatusDesc',
      ellipsis: true,
      width: '150px'
    },
    {
      title: 'Callback',
      dataIndex: 'requestBody',
      ellipsis: true,
      render: (value: string) => {
        return (
          <div style={{ whiteSpace: 'normal' }}><>{JSON.parse(JSON.stringify(value))}</></div>
        )
      }
    }
  ]
  const getExpandedData = useCallback(async (): Promise<void> => {
    await dispatch(getTransactionCallback({ ...props, cursors: [], isNextPage: null })).then(unwrapResult)
    setLoading(false)
  }, [dispatch, props])

  useOnMountEffect(() => {
    getExpandedData().finally(() => {})
  })
  return (
    <StyledTable
      rowKey='createdTime'
      columns={columns}
      dataSource={callbackData?.data ?? []}
      hasPagination={false}
      loading={loading}
    />
  )
}

export default ExpandedData
