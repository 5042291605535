import styled from 'styled-components'
import { Button } from 'antd'

const Title = styled.h1`
    font-size: 14px;
    margin: 0 0 10px;
`
const CreateButton = styled(Button)`
    margin-bottom: 24px;

    > svg {
        margin-right: 5px;
    }
`

const TopUpContent = styled.div`
    background: ${props => props.theme.colors.secondaryColor};
    border-radius: 4px;
    border: 1px solid #dee2e6;
    box-shadow: 0 1px 2px 0 rgb(31 45 61 / 7%);
    margin-bottom: 20px;
    padding: 20px;
`
const ButtonWithIcon = styled(Button)`
  > svg {
    margin-right: 5px;
  }
`

export {
  Title,
  CreateButton,
  TopUpContent,
  ButtonWithIcon
}
