import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import api from '../../../utils/api'
import { GetState, RejectWithValueState, GetSettlementState, GetSettlementPayloadState, SettlementState, PostSettlementPayloadState, SettlementBankAccountListState, PostAddSettlementPayloadState } from './interfaces'
import { clientTimeZone } from '../../../utils/utils'

// Async Requests
export const getSettlement = createAsyncThunk<GetSettlementState, GetSettlementPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/getSettlement',
  async ({ endDate, pageIndex, pageSize, startDate, status, beneficiaryName, dateType, currencies }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/settlement/search', {
      endDate,
      pageIndex,
      pageSize,
      startDate,
      status,
      beneficiaryName,
      dateType,
      clientTimeZone,
      currencies
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const getSettlementBankAccountList = createAsyncThunk<SettlementBankAccountListState, void | never, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/getSettlementBankAccountList',
  async (_, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get('/look-up/bank-account-list', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postSettlementCancelRequest = createAsyncThunk<any, PostSettlementPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/postSettlementCancelRequest',
  async ({ id, reason }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/settlement/cancel', {
      id,
      reason
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postAddSettlement = createAsyncThunk<any, PostAddSettlementPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/postAddSettlement',
  async ({ bankId, currency, note, otp, requestedAmount, toMAccountId }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/settlement/add', {
      bankId,
      currency,
      note,
      otp,
      requestedAmount,
      toMAccountId
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const settlementState: SettlementState = {
  getSettlementLoading: false,
  postSettlementCancelRequestLoading: false,
  settlements: {
    data: [],
    total: 0
  },
  settlementBankAcountList: [{
    bankAccountCode: '',
    name: '',
    id: 0
  }],
  settlementBankAcountListLoading: false,
  postAddSettlementLoading: false
}

// Slices
const settlementSlice = createSlice({
  name: 'settlement',
  initialState: settlementState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettlement.fulfilled, (state, action) => {
        state.settlements.data = action.payload.data
        state.settlements.total = action.payload.total
      })
      .addCase(getSettlement.pending, (state) => {
        state.getSettlementLoading = true
      })
      .addCase(postSettlementCancelRequest.pending, (state) => {
        state.postSettlementCancelRequestLoading = true
      })
      .addCase(getSettlementBankAccountList.pending, (state) => {
        state.settlementBankAcountListLoading = true
      })
      .addCase(getSettlementBankAccountList.fulfilled, (state, action) => {
        state.settlementBankAcountList = action.payload
      })
      .addCase(postAddSettlement.pending, (state) => {
        state.postAddSettlementLoading = true
      })
      .addMatcher(isAnyOf(getSettlement.fulfilled,
        getSettlement.rejected,
        postSettlementCancelRequest.fulfilled,
        postSettlementCancelRequest.rejected,
        getSettlementBankAccountList.fulfilled,
        getSettlementBankAccountList.rejected,
        postAddSettlement.fulfilled,
        postAddSettlement.rejected), (state) => {
        state.getSettlementLoading = false
        state.postSettlementCancelRequestLoading = false
        state.settlementBankAcountListLoading = false
        state.postAddSettlementLoading = false
      })
  }
})

// Selectors
export const selectGetSettlementLoading = (state: RootState): boolean => state.settlementReducer.getSettlementLoading
export const selectSettlements = (state: RootState): GetSettlementState => state.settlementReducer.settlements
export const selectPostSettlementCancelRequestLoading = (state: RootState): boolean => state.settlementReducer.postSettlementCancelRequestLoading
export const selectSettlementBankAcountList = (state: RootState): SettlementBankAccountListState => state.settlementReducer.settlementBankAcountList
export const selectSettlementBankAcountListLoading = (state: RootState): boolean => state.settlementReducer.settlementBankAcountListLoading
export const selectPostAddSettlementLoading = (state: RootState): boolean => state.settlementReducer.postAddSettlementLoading

export default settlementSlice.reducer
