import styled from 'styled-components'
import { StatusType } from '../../utils/interface'
import { renderStatusColors } from '../../utils/utils'

const StatusDisplay = styled.p<{status: StatusType}>`
  padding: 0 6px;
  margin: 0;
  border-radius: 5px;
  text-align: center;
  color: ${props => renderStatusColors(props.status, props.theme.statusTextColors)};
  background-color: ${props => renderStatusColors(props.status, props.theme.statusBackgroundColors)};
  border: 1px solid ${props => renderStatusColors(props.status, props.theme.statusTextColors)};
`

export {
  StatusDisplay
}
