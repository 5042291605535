import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    #root {
        height: 100%;
    }

    body {
        background-color: #f6f9fc;
        margin: 0;
        padding: 0;
    }

    input {
        &:focus-visible {
            outline-color: #1890ff;
        }
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-card-loading-content {
        padding: 0 !important;
    }

    .ant-input-affix-wrapper,
    .ant-picker,
    .ant-input-number,
    .ant-input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 4px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active
    {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .ant-dropdown-menu-item:hover, 
    .ant-dropdown-menu-submenu-title:hover {
        background-color: transparent;
        color: #64af0e;
    }

    .ant-card {
        box-shadow: 0 1px 2px 0 rgb(31 45 61 / 7%);
        border: 1px solid #dee2e6;
        border-radius: 4px;
    }
    
    .ant-picker, 
    .ant-input,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 37px;
    }

    .ant-input-number-input {
        height: 35px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
    .ant-select-multiple .ant-select-selection-search-input, .ant-select-multiple .ant-select-selection-search-mirror {
        line-height: 37px;
    }
    
    .ant-select-multiple .ant-select-selection-search-input, .ant-select-multiple .ant-select-selection-search-mirror {
        height: 31px;
    }
    
    .ant-input-affix-wrapper > input.ant-input {
        height: 27px;
    }
    
    .ant-table-column-sorters,
    .ant-table-thead > tr > th {
        text-align: center;
    }
    
    .ant-table-small .ant-table-thead > tr > th {
        background-color: #ebebeb;
        border-right: 2px solid #FFF !important;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
        border-right: 2px solid #f0f0f0 !important;
    }

    .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
        border-bottom: 2px solid #FFF !important;
    }
    .table-row-light {
        background-color: rgb(247,247,247) !important;
    }
    .table-row-dark {
        background-color: #ffffff !important;
    }
    .ant-table-cell-fix-left, .ant-table-cell-fix-right {
      background: inherit;
    }
`
