import React from 'react'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import { TooltipPlacement } from 'antd/lib/tooltip'

const StyledTooltip = styled(Tooltip)`
    
`

const lightOrDark = (color: string): 'light' | 'dark' => {
  let colorMatch
  // Variables for red, green, blue values
  var r, g, b, hsp

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/) != null) {
    // If RGB --> store the red, green, blue values in separate variables
    colorMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

    r = parseInt(color[1])
    g = parseInt(color[2])
    b = parseInt(color[3])
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    colorMatch = +('0x' + color.slice(1).replace(
      color.length < 5 ? /./g : '', '$&$&'))

    r = colorMatch >> 16
    g = (colorMatch >> 8) & 255
    b = colorMatch & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  )

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light'
  } else {
    return 'dark'
  }
}
interface ITooltip {
  text: string | JSX.Element
  error?: boolean
  children: React.ReactNode
  placement: TooltipPlacement
  color?: string
  className?: string
}
const TooltipComponent = ({
  placement,
  children,
  text,
  color = 'black',
  error = false,
  className,
  ...rest
}: ITooltip): JSX.Element => {
  const textColor = lightOrDark(color) === 'light' ? 'black' : 'white'
  return (
    <StyledTooltip
      placement={placement}
      title={text}
      color={error ? 'red' : color}
      overlayClassName={className}
      overlayInnerStyle={{
        color: textColor
      }}
      {...rest}
    >
      {children}
    </StyledTooltip>
  )
}

export default TooltipComponent
