import { useEffect, useCallback, useState } from 'react'
import { message, Row, Col, Input, Select, Grid } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import { selectRolePermission, selectSelectList } from '../../LoginPage/reducers'
import dayjs from 'dayjs'
import { isNullOrUndefined, getCurrencyName, getCurrencyValue, pascalCase, datetime } from '../../../utils/utils'
import { RangeValue } from 'rc-picker/lib/interface'
import { TableContainer } from '../../../components/styles'
import { getWithdrawal, exportWithdrawalExcel, selectWithdrawals, selectGetWithdrawalLoading, selectExportWithdrawalExcelLoading } from './reducers'
import { DataInterface, WithdrawalFilters } from './interfaces'
import NumberFormat from 'react-number-format'
import { selectGlobalCurrency } from '../../AuthLayout/reducers'
import { RiSearchLine } from 'react-icons/ri'
import { SiMicrosoftexcel } from 'react-icons/si'
import { ButtonWithIcon } from './styles'
import Table from '../../../components/TableComponent'
import { StatusDisplay } from '../styles'
import { StatusCodeType } from '../../../utils/interface'
import FilterRangePicker from '../../../components/FilterRangePicker'
import ExpandedData from '../Components/ExpandedData/ExpandedData'

const { useBreakpoint } = Grid

const initialFilters = {
  bankIds: undefined,
  currencies: undefined,
  endDate: dayjs(),
  pageIndex: 1,
  pageSize: 10,
  searchTerm: '',
  searchType: 1,
  startDate: dayjs(),
  status: undefined
} as const

const WithdrawalPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const getWithdrawalLoading = useAppSelector(selectGetWithdrawalLoading)
  const withdrawals = useAppSelector(selectWithdrawals)
  const exportWithdrawalExcelLoading = useAppSelector(selectExportWithdrawalExcelLoading)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const rolePermission = useAppSelector(selectRolePermission)
  const canExportWithdrawal = rolePermission?.includes('WITHDRAWAL_EXPORT')
  const [filters, setFilters] = useState<WithdrawalFilters>(initialFilters)
  const bankSelectList = selectList.providerSelectList.filter(b => (filters.currencies !== undefined && filters.currencies.length <= 0) ||
  filters.currencies?.findIndex(c => c === b.currency) !== -1)
    .map((item: { code: string, name: string, id: number }) => ({
      label: `${item.code} - ${item.name}`,
      value: item.id
    })
    ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  const merchantBOPayoutStatusSelectList = selectList.merchantBOPayoutStatusSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      ellipsis: true
    },
    {
      title: 'Reference',
      dataIndex: 'transactionCode',
      ellipsis: true,
      sorter: (a, b) => a.transactionCode.localeCompare(b.transactionCode),
      render: (_, record: {
        merchantCode: string
        transactionCode: string
      }) => `${record.transactionCode}`
    },
    {
      title: 'Bank',
      dataIndex: 'toBank',
      sorter: (a, b) => a.toBank.localeCompare(b.toBank),
      ellipsis: true,
      render: (_, record: {
        toBank: string
        toName: string
        toNumber: string
      }) => `${record.toBank}/${record.toName} `
    },
    {
      title: 'Requested Amount',
      dataIndex: 'requestedAmount',
      ellipsis: true,
      sorter: (a, b) => a.requestedAmount - b.requestedAmount,
      render: (value: number, row: { currency: number }) => <NumberFormat prefix={`${getCurrencyName(row.currency)} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Paid Amount',
      dataIndex: 'totalTransferAmount',
      sorter: (a, b) => a.totalTransferAmount - b.totalTransferAmount,
      render: (value: number, row: { currency: number }) => <NumberFormat prefix={`${getCurrencyName(row.currency)} `} value={value} displayType='text' thousandSeparator />,
      ellipsis: true
    },
    {
      title: 'Rate (%)',
      dataIndex: 'serviceFeePercentage',
      ellipsis: true,
      sorter: (a, b) => a.serviceFeePercentage - b.serviceFeePercentage,
      width: 120
    },
    {
      title: 'Service Fee',
      dataIndex: 'serviceFee',
      sorter: (a, b) => a.serviceFee - b.serviceFee,
      render: (value: number, row: { currency: number }) => <NumberFormat prefix={`${getCurrencyName(row.currency)} `} value={value} displayType='text' thousandSeparator />,
      ellipsis: true
    },
    {
      title: 'Bank Fee',
      dataIndex: 'totalFee',
      sorter: (a, b) => a.fee - b.fee,
      render: (value: number, row: { currency: number }) => <NumberFormat prefix={`${getCurrencyName(row.currency)} `} value={value} displayType='text' thousandSeparator />,
      ellipsis: true
    },
    {
      title: 'Bank Fee On',
      dataIndex: 'bankFeeOn',
      sorter: (a, b) => a.bankFeeOn.localeCompare(b.bankFeeOn),
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'merchantStatusMessage',
      sorter: (a, b) => a.merchantStatusMessage.localeCompare(b.merchantStatusMessage),
      ellipsis: true,
      render: (value: string) => <StatusDisplay status={value.toLocaleUpperCase() as StatusCodeType}>{pascalCase(value)}</StatusDisplay>
    },
    {
      title: 'Created On',
      dataIndex: 'createdDate',
      ellipsis: true,
      sorter: (a, b) => a.createdDate?.localeCompare(b.createdDate),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('YYYY/MM/DD HH:mm:ss')
    },
    {
      title: 'Completed On',
      dataIndex: 'completedDate',
      ellipsis: true,
      sorter: (a, b) => a.completedDate?.localeCompare(b.completedDate),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('YYYY/MM/DD HH:mm:ss')
    }
  ]

  function onHandleChangeFilters (value: any, name: string): void {
    const filterValue = name === 'searchTerm' ? value.target.value : value

    setFilters(prevState => ({
      ...prevState,
      [name]: filterValue === null ? '' : filterValue
    }))
  }

  const handleSearch = useCallback(async (data: WithdrawalFilters): Promise<void> => {
    const payload = {
      ...data,
      startDate: datetime.getStartDate(data.startDate),
      endDate: datetime.getEndDate(data.endDate)
    }
    try {
      await dispatch(getWithdrawal(payload)).then(unwrapResult)
    } catch (error: any) {
      await message.error(error.message)
    }
  }, [dispatch])

  function onHandleOnChangePagination (pageIndex: number, pageSize: number | undefined): any {
    const newFilters = {
      ...filters,
      pageIndex,
      pageSize
    }
    setFilters(newFilters)
    handleSearch(newFilters).finally(() => {})
  }

  function onHandleRangePicker (dates: RangeValue<dayjs.Dayjs>): void {
    if (dates !== null) {
      setFilters(prevState => ({
        ...prevState,
        startDate: dates[0],
        endDate: dates[1]
      }))
    } else {
      setFilters(prevState => ({
        ...prevState,
        startDate: null,
        endDate: null
      }))
    }
  }

  async function onHandleSubmitFilters (): Promise<any> {
    const payload = {
      ...filters,
      pageIndex: 1
    }
    setFilters(prevState => ({ ...prevState, pageIndex: 1 }))
    await handleSearch(payload)
  }

  const getWithdrawalAction = useCallback(async (): Promise<any> => {
    const payload = {
      ...initialFilters,
      currencies: [getCurrencyValue(globalCurrency)]
    }

    await handleSearch(payload)
  }, [handleSearch, globalCurrency])

  async function onHandleExportExcel (): Promise<any> {
    const payload = {
      ...filters,
      startDate: datetime.getStartDate(filters.startDate),
      endDate: datetime.getEndDate(filters.endDate)
    }

    try {
      await dispatch(exportWithdrawalExcel(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  useEffect(() => {
    getWithdrawalAction().finally(() => {})
  }, [getWithdrawalAction])

  useEffect(() => {
    setFilters(f => {
      return {
        ...f,
        currencies: [getCurrencyValue(globalCurrency)],
        bankIds: undefined
      }
    })
  }, [globalCurrency])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={5} lg={5} xxl={3}>
          <Select
            mode='multiple'
            optionFilterProp='label'
            placeholder='Select Bank/Provider'
            value={filters.bankIds}
            style={{ width: '100%' }}
            options={bankSelectList}
            onChange={(value) => onHandleChangeFilters(value, 'bankIds')}
            allowClear
          />
        </Col>
        <Col xs={24} sm={5} lg={5} xxl={3}>
          <Select
            mode='multiple'
            placeholder='Select Status'
            style={{ width: '100%' }}
            options={merchantBOPayoutStatusSelectList}
            onChange={(value) => onHandleChangeFilters(value, 'status')}
            allowClear
          />
        </Col>
        <Col xs={24} sm={8} lg={7} xxl={4}>
          <FilterRangePicker
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={onHandleRangePicker}
          />
        </Col>
        <Col xs={24} sm={6} lg={7} xxl={4}>
          <Input
            placeholder='Search Term'
            addonBefore='Reference'
            allowClear
            onChange={(value) => onHandleChangeFilters(value, 'searchTerm')}
          />
        </Col>
        <Col xs={24} sm={5} lg={4} xxl={2}>
          <ButtonWithIcon
            type='primary'
            shape='round'
            block={screens.xs}
            onClick={onHandleSubmitFilters}
          >
            <RiSearchLine />  Search
          </ButtonWithIcon>
        </Col>
        {
          canExportWithdrawal &&
            <Col xs={24} sm={{ span: 4, offset: 14 }} lg={{ span: 4, offset: 16 }} xxl={{ span: 2, offset: 6 }} style={{ textAlign: 'right' }}>
              <ButtonWithIcon
                type='primary'
                shape='round'
                block={screens.xs}
                onClick={onHandleExportExcel}
                loading={exportWithdrawalExcelLoading}
                disabled={withdrawals.data.length <= 0}
              >
                <SiMicrosoftexcel />  Export
              </ButtonWithIcon>
            </Col>
        }
      </Row>
      <TableContainer>
        <Table
          dataSource={withdrawals.data}
          loading={getWithdrawalLoading}
          columns={columns}
          current={filters.pageIndex}
          pageSize={filters.pageSize}
          total={withdrawals.total}
          onChange={onHandleOnChangePagination}
          expandable={{
            expandedRowRender: (record: DataInterface, index) => (
              <ExpandedData
                transactionType={2}
                methodType={record.methodType}
                transactionId={record.id}
              />
            ),
            columnTitle: 'Callback'
          }}
        />
      </TableContainer>
    </>
  )
}

export default WithdrawalPage
