import React, { useEffect, useCallback } from 'react'
import { Form, Input, Select, Button, Row, Col, message, Skeleton, Grid } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { getUserListDetail, postUpdateUser, selectPostUpdateUserLoading, selectGetUserListDetailLoading } from '../reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { getSelectList, selectSelectList } from '../../../LoginPage/reducers'
import { RouteComponentProps } from 'react-router-dom'
import { UserListDetailPageContentContainer, UserListDetailPageButtonsContainer } from '../styles'

const { useBreakpoint } = Grid

const UserListDetailPage = (props: RouteComponentProps<{ id: string }>): JSX.Element => {
  const { history, match: { params } } = props
  const userId = params.id
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const postUpdateUserLoading = useAppSelector(selectPostUpdateUserLoading)
  const getUserListDetailLoading = useAppSelector(selectGetUserListDetailLoading)
  const [form] = Form.useForm()
  const merchantUserRolesOptions = selectList.merchantUserRolesSelectList.map((item: { name: string, value: number }) => ({
    label: item.name,
    value: item.value
  })).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)
  const mUserStatusOptions = selectList.mUserStatusSelectList.map((item: { name: string, value: number }) => ({
    label: item.name,
    value: item.value
  })).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  async function handleUserListSubmit (values: any): Promise<any> {
    try {
      await dispatch(postUpdateUser({ id: userId, ...values })).then(unwrapResult)
      await history.push('/administrator/user-list')
      return await message.success('Update User Success!')
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  function onHandleCancel (): void {
    history.goBack()
  }

  const getUserListDetailAction = useCallback(async (): Promise<any> => {
    try {
      const userListDetail = await dispatch(getUserListDetail({ id: userId })).then(unwrapResult)
      return form.setFieldsValue({
        username: userListDetail.username,
        fullName: userListDetail.fullName,
        merchantUserRoles: userListDetail.roleIdArray,
        status: userListDetail.statusValue,
        password: userListDetail.password
      })
    } catch (error: any) {
      await message.error(error.message)
      return history.goBack()
    }
  }, [dispatch, userId, history, form])

  const getSelectListAction = useCallback(async (): Promise<any> => {
    await dispatch(getSelectList())
  }, [dispatch])

  useEffect(() => {
    getUserListDetailAction().finally(() => {})
    getSelectListAction().finally(() => { })
  }, [getUserListDetailAction, getSelectListAction])

  return (
    <>
      <UserListDetailPageContentContainer>
        {
          (getUserListDetailLoading && <Skeleton active />) ||
            <Form
              name='user-detail-form'
              onFinish={handleUserListSubmit}
              requiredMark={false}
              layout='vertical'
              form={form}
            >
              <Row gutter={24}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label='Username'
                    name='username'
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label='Fullname'
                    name='fullName'
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label='Reset Password'
                    name='password'
                  >
                    <Input placeholder='Reset Passowrd' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label='User Roles'
                    name='merchantUserRoles'
                    rules={[{ required: true, message: 'User Roles is required.' }]}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Select Roles'
                      options={merchantUserRolesOptions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label='Status'
                    name='status'
                    rules={[{ required: true, message: 'Status is required.' }]}
                  >
                    <Select
                      placeholder='Select Status'
                      options={mUserStatusOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <UserListDetailPageButtonsContainer>
                <Button
                  type='primary'
                  htmlType='submit'
                  shape='round'
                  block={screens.xs}
                  loading={postUpdateUserLoading}
                >
                  Update
                </Button>
                <Button
                  onClick={onHandleCancel}
                  shape='round'
                  block={screens.xs}
                >
                  Cancel
                </Button>
              </UserListDetailPageButtonsContainer>
            </Form>
        }
      </UserListDetailPageContentContainer>
    </>
  )
}

export default UserListDetailPage
