import { Route, Redirect } from 'react-router-dom'
import {
  selectIsEnableLoginTwoFactor,
  selectIsTwoFaLoginVerified,
  selectRolePermission,
  logOut,
  selectAuthToken
} from '../containers/LoginPage/reducers'
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks'
import AuthLayout from '../containers/AuthLayout/AuthLayout'
import { RouteState } from './interfaces'
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage'

const AuthenticatedRoute = ({
  component: Component,
  path,
  permission,
  ...rest
}: RouteState): JSX.Element => {
  const dispatch = useAppDispatch()
  const hasAuthToken = useAppSelector(selectAuthToken) !== ''
  const isEnableLoginTwoFactor = useAppSelector(selectIsEnableLoginTwoFactor)
  const isTwoFaLoginVerified = useAppSelector(selectIsTwoFaLoginVerified)
  const rolePermission = useAppSelector(selectRolePermission)
  const hasNoPermission = rolePermission.length === 1 && rolePermission[0] === ''

  function renderTwoFactorAuthentication (): any {
    if (hasNoPermission) {
      // this will only happen if user still has the old AuthToken stored in localStorage
      dispatch(logOut())
      return (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
    if (isEnableLoginTwoFactor && !isTwoFaLoginVerified) {
      // if 2fa is enabled and not yet verified will redirect to 2fa page
      switch (path) {
        case '/two-factor-authentication':
          return (
            <Route {...rest} render={(props) => <Component {...props} />} />
          )
        default:
          return (
            <Redirect
              to={{
                pathname: '/two-factor-authentication',
                state: { from: rest.location }
              }}
            />
          )
      }
    }
    switch (
      path // if both 2fa enabled and verified will redirect to dashboard page
    ) {
      case '/two-factor-authentication':
        return (
          <Redirect
            to={{ pathname: '/dashboard', state: { from: rest.location } }}
          />
        )
      default:
        return permission !== null && rolePermission?.includes(permission) ? (
          <Route
            {...rest}
            render={(props) => (
              <AuthLayout {...props}>
                <Component {...props} />
              </AuthLayout>
            )}
          />
        ) : (
          <Route
            {...rest}
            render={(props) => (
              <AuthLayout {...props}>
                <NotFoundPage {...props} />
              </AuthLayout>)}
          />
        )
    }
  }

  return hasAuthToken ? (
    renderTwoFactorAuthentication()
  ) : (
    <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
  )
}

export default AuthenticatedRoute
