import styled from 'styled-components'
import { Button } from 'antd'

const EnableButton = styled(Button)`
  background: ${props => props.theme.colors.enableColor};
  border-color: ${props => props.theme.colors.enableColor};
  color: ${props => props.theme.colors.secondaryColor};

  > svg {
    margin-right: 5px;
  }

  &:hover, &:active, &:focus {
    background: ${props => props.theme.colors.enableColor};
    border-color: ${props => props.theme.colors.enableColor};
    color: ${props => props.theme.colors.secondaryColor};
    opacity: 0.8;
  }
`
const DisableButton = styled(Button)`
  background: ${props => props.theme.colors.disableColor};
  border-color: ${props => props.theme.colors.disableColor};
  color: ${props => props.theme.colors.secondaryColor};

  > svg {
    margin-right: 5px;
  }

  &:hover, &:active, &:focus {
    background: ${props => props.theme.colors.disableColor};
    border-color: ${props => props.theme.colors.disableColor};
    color: ${props => props.theme.colors.secondaryColor};
    opacity: 0.8;
  }
`
const ActiveTwoFa = styled.div`
  background: ${props => props.theme.colors.enableColor};
  border-color: ${props => props.theme.colors.enableColor};
  border-radius: 2px;
  border-radius: 37px;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  color: ${props => props.theme.colors.secondaryColor};
  height: 37px;
  line-height: 2;
  min-width: 150.56px;
  padding: 4px 15px;
  text-align: center;

  @media (max-width: 768px) {
    min-width: 0;
  }
`
const InactiveTwoFa = styled.div`
  background: ${props => props.theme.colors.inActiveColor};
  border-color: ${props => props.theme.colors.inActiveColor};
  border-radius: 2px;
  border-radius: 37px;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  height: 37px;
  line-height: 2;
  min-width: 150.56px;
  padding: 4px 15px;
  text-align: center;

  @media (max-width: 768px) {
    min-width: 0;
  }
`
const TwoFaTitle = styled.h1`
  font-size: 16px;
  margin: 0 0 10px;
`
const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.errorColor};
  margin: 15px 0 0;
  text-align: center;
`
const ProfilePageButtonsContainer = styled.div`
  display: flex;

    > div, button {
        margin: 0 16px 0 0;

        @media (max-width: 576px) {
            margin: 0 0 16px;
        }
    }

    @media (max-width: 576px) {
      display: block;
    }
`
const ProfilePageModalButtonsContainer = styled.div`
  margin: 16px 0 0;
  text-align: right;

  > button {
    margin: 0 0 0 16px;

    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
  }
`

export {
  EnableButton,
  DisableButton,
  TwoFaTitle,
  ActiveTwoFa,
  InactiveTwoFa,
  ErrorMessage,
  ProfilePageButtonsContainer,
  ProfilePageModalButtonsContainer
}
