import React, { useEffect, useCallback, useState } from 'react'
import { Form, Input, Grid, Tag, Button, Row, Col, message, Skeleton, Tabs } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { MerchantEyeInvisibleOutlined, MerchantEyeOutline, LabelText, ValueText, ShowIconContainer, CopyIcon, MaskSecurityCode, MerchantDetailButtonsContainer, ButtonWithIcon } from './styles'
import { selectMerchantId, selectSelectList, selectRolePermission } from '../LoginPage/reducers'
import { getMerchantDetails, selectGetMerchantDetailsLoading, postMerchantDetails, selectPostMerchantDetailsLoading, selectMerchantDetails, postValidateBankAccount, postValidateWhiteList } from './reducers'
import { useClipboard } from 'use-clipboard-copy'
import BankAccounts from './components/BankAccounts'
import Whitelist from './components/Whitelist'
import CurrenciesSupported from './components/CurrenciesSupported'
import { MerchantInfoState } from './interfaces'
import { truncateString } from '../../utils/utils'
import { BiEdit } from 'react-icons/bi'

const { TabPane } = Tabs
const { useBreakpoint } = Grid

const MerchantPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const merchantId = useAppSelector(selectMerchantId)
  const getMerchantDetailsLoading = useAppSelector(selectGetMerchantDetailsLoading)
  const postMerchantDetailsLoading = useAppSelector(selectPostMerchantDetailsLoading)
  const merchantDetails = useAppSelector(selectMerchantDetails)
  const [form] = Form.useForm()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showSecurityKey, setShowSecurityKey] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>('bankAccounts')
  const selectList = useAppSelector(selectSelectList)
  const [merchantInfo, setMerchantInfo] = useState<MerchantInfoState>({
    name: '',
    code: '',
    securityCode: '',
    currenciesSupported: [],
    transactionRate: 0,
    withdrawRate: 0,
    website: '',
    status: 0,
    byUser: '',
    isAdmin: false
  })
  const rolePermission = useAppSelector(selectRolePermission)
  const canEditMerchantInfo = rolePermission?.includes('MERCHANT_INFO_EDIT')
  const canViewWhiteListPage = rolePermission?.includes('WHITELIST_VIEW')
  const canViewBankAccountPage = rolePermission?.includes('BANK_ACCOUNT_VIEW')
  const canViewSupportedCurrencyPage = rolePermission?.includes('SUPPORTED_CURRENCY_VIEW')

  const clipboard = useClipboard({
    async onSuccess () {
      await message.info('Copied Security Key!')
    }
  })
  const merchantStatusTypeSelectList = selectList.merchantStatusTypeSelectList.map((item: { name: string, value: number }) => ({
    label: item.name,
    value: item.value
  })).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)
  const currencySelectList = selectList.currencySelectList.map((item: { name: string, value: number }) => ({
    label: item.name,
    value: item.value
  })).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  async function onHandleMerchantDetailSubmit (values: any): Promise<any> {
    const payload = {
      id: merchantId,
      name: values.name,
      website: values.website
    }

    try {
      await dispatch(postMerchantDetails(payload)).then(unwrapResult)
      await getMerchantDetailsAction()
      setIsEdit(false)
      return await message.success('Update Merchant Details Success!')
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  function onHandleCancel (): void {
    form.validateFields()
      .then(() => {
        setIsEdit(false)
        form.resetFields()
      })
      .catch(error => {
        const isError = error.errorFields.length > 0
        setIsEdit(isError)
      })
  }

  function onHandleEditForm (): void {
    setIsEdit(true)
  }

  function onHandleShowSecurityKey (): void {
    setShowSecurityKey(prevState => !prevState)
  }

  function onHandleCopySecurityCode (): void {
    clipboard.copy(merchantDetails.securityCode)
  }

  async function onHandleTabClick (key: string): Promise<any> {
    if (key === 'bankAccounts' && activeTab !== 'bankAccounts') { // by using activeTab we can assure the user won't abuse clicking tab to make multiple request
      setActiveTab('bankAccounts')
      return await dispatch(postValidateBankAccount())
    } else if (key === 'whiteList' && activeTab !== 'whiteList') {
      setActiveTab('whiteList')
      return await dispatch(postValidateWhiteList({ type: 1 }))
    } else if (key === 'currenciesSupported' && activeTab !== 'currenciesSupported') {
      setActiveTab('currenciesSupported')
    }
  }

  const getMerchantDetailsAction = useCallback(
    async (): Promise<any> => {
      const payload = {
        merchantId
      }

      try {
        const response = await dispatch(getMerchantDetails(payload)).then(unwrapResult)
        setMerchantInfo({
          name: response.name,
          code: response.code,
          securityCode: response.securityCode,
          currenciesSupported: response.currenciesSupported,
          transactionRate: response.transactionRate,
          withdrawRate: response.withdrawRate,
          website: response.website,
          status: response.status,
          byUser: response.byUser,
          isAdmin: response.isAdmin
        })

        return form.setFieldsValue({
          name: response.name,
          website: response.website
        })
      } catch (error: any) {
        return await message.error(error.message)
      }
    },
    [dispatch, merchantId, form]
  )

  useEffect(() => {
    getMerchantDetailsAction().finally(() => {})
  }, [getMerchantDetailsAction])

  const currenciesSupported = currencySelectList.filter(item => merchantInfo.currenciesSupported.includes(item.value))
  const status = merchantStatusTypeSelectList.filter(item => merchantInfo.status === item.value)

  return (
    <>
      {
        (getMerchantDetailsLoading && <Skeleton active />) ||
          <Form
            name='merchant-detail-form'
            onFinish={onHandleMerchantDetailSubmit}
            requiredMark={false}
            layout='vertical'
            form={form}
            initialValues={{
              ...merchantDetails
            }}
          >
            {canEditMerchantInfo &&
              <Row justify='end'>
                <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                  <Form.Item>
                    <ButtonWithIcon
                      type='primary'
                      shape='round'
                      block={screens.xs}
                      onClick={onHandleEditForm}
                      hidden={isEdit}
                    >
                      <BiEdit />  Edit
                    </ButtonWithIcon>
                    {
                      isEdit &&
                        <MerchantDetailButtonsContainer>
                          <Button
                            type='primary'
                            shape='round'
                            htmlType='submit'
                            block={screens.xs}
                            loading={postMerchantDetailsLoading}
                          >
                            Update
                          </Button>
                          <Button
                            onClick={onHandleCancel}
                            shape='round'
                            block={screens.xs}
                          >
                            Cancel
                          </Button>
                        </MerchantDetailButtonsContainer>
                    }
                  </Form.Item>
                </Col>
              </Row>}
            <Row gutter={16}>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={<LabelText>Merchant Name</LabelText>}
                  name='name'
                  rules={[{ required: true, message: 'Merchant Name is required.' }]}
                >
                  {
                    !isEdit ? <ValueText>{merchantInfo.name}</ValueText> : <Input placeholder='Merchant Name' />
                  }
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={<LabelText>Website</LabelText>}
                  name='website'
                  rules={[{ required: true, message: 'Website is required.' }]}
                >
                  {
                    !isEdit ? <ValueText>{merchantInfo.website}</ValueText> : <Input placeholder='Website' />
                  }
                </Form.Item>
              </Col>
              {
                merchantInfo.isAdmin && merchantInfo.securityCode &&
                  <Col xs={24} sm={6}>
                    <Form.Item
                      label={<LabelText>Security Key</LabelText>}
                    >
                      <ValueText>
                        {
                          !showSecurityKey ? <MaskSecurityCode>************</MaskSecurityCode> : truncateString(merchantInfo.securityCode, 10)
                        }
                      </ValueText>
                      <ShowIconContainer>
                        {
                          !showSecurityKey
                            ? <MerchantEyeOutline onClick={onHandleShowSecurityKey} />
                            : <MerchantEyeInvisibleOutlined onClick={onHandleShowSecurityKey} />
                        }
                      </ShowIconContainer>
                      <CopyIcon onClick={onHandleCopySecurityCode} />
                    </Form.Item>
                  </Col>
              }
              <Col xs={24} sm={6}>
                <Form.Item
                  label={<LabelText>Supported Currencies</LabelText>}
                >
                  <ValueText>
                    {
                      currenciesSupported.map(item => (
                        <Tag key={item.value}>
                          {item.label}
                        </Tag>
                      ))
                    }
                  </ValueText>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={<LabelText>Transaction Rate (%)</LabelText>}
                >
                  <ValueText>{merchantInfo.transactionRate}</ValueText>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={<LabelText>Withdrawal Rate (%)</LabelText>}
                >
                  <ValueText>{merchantInfo.withdrawRate}</ValueText>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={<LabelText>Merchant Code</LabelText>}
                >
                  <ValueText>{merchantInfo.code}</ValueText>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={<LabelText>Status</LabelText>}
                >
                  <ValueText>
                    <Tag color='success'>
                      {
                        status[0]?.label
                      }
                    </Tag>
                  </ValueText>
                </Form.Item>
              </Col>
            </Row>
          </Form>
      }
      <Tabs type='card' onTabClick={onHandleTabClick}>
        {
          canViewBankAccountPage &&
            <TabPane tab='Bank Accounts' key='bankAccounts'>
              <BankAccounts />
            </TabPane>
        }
        {
          canViewWhiteListPage &&
            <TabPane tab='Whitelisted IP' key='whiteList'>
              <Whitelist />
            </TabPane>
        }
        {
          canViewSupportedCurrencyPage &&
            <TabPane tab='Supported Currencies' key='currenciesSupported'>
              <CurrenciesSupported />
            </TabPane>
        }
      </Tabs>
    </>
  )
}

export default MerchantPage
