import { NavigationRoutesState } from './interfaces'
import { MdDashboard, MdStorefront, MdOutlineAdminPanelSettings, MdStore } from 'react-icons/md'
import { GrTransaction } from 'react-icons/gr'
import { HiOutlineDocumentReport, HiDocumentReport } from 'react-icons/hi'
import { RiBankLine, RiBankCard2Line, RiBankCardFill, RiAdminLine } from 'react-icons/ri'
import { BiWallet } from 'react-icons/bi'

const navigationRoutes: NavigationRoutesState[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <MdDashboard />,
    roles: ['DASHBOARD_VIEW']
  },
  {
    path: '/merchant',
    name: 'Merchant',
    icon: <MdStorefront />,
    roles: ['MERCHANT_INFO_VIEW']
  },
  {
    path: '/transaction',
    name: 'Transaction',
    icon: <GrTransaction />,
    roles: ['MERCHANT_SUMMARY_VIEW', 'DEPOSIT_VIEW', 'WITHDRAWAL_VIEW', 'SETTLEMENT_VIEW', 'TOPUP_VIEW'],
    subRoutesPrimary: [
      {
        path: '/transaction/merchant-summary',
        name: 'Merchant Summary',
        icon: <MdStore />,
        roles: 'MERCHANT_SUMMARY_VIEW'
      },
      {
        path: '/transaction/deposit',
        name: 'Deposit',
        icon: <RiBankLine />,
        roles: 'DEPOSIT_VIEW'
      },
      {
        path: '/transaction/withdrawal',
        name: 'Withdrawal',
        icon: <BiWallet />,
        roles: 'WITHDRAWAL_VIEW'
      },
      {
        path: '/transaction/settlement',
        name: 'Settlement',
        icon: <RiBankCard2Line />,
        roles: 'SETTLEMENT_VIEW'
      },
      {
        path: '/transaction/topup',
        name: 'Top Up',
        icon: <RiBankCardFill />,
        roles: 'TOPUP_VIEW'
      }
    ]
  },
  {
    path: '/report',
    name: 'Report',
    icon: <HiOutlineDocumentReport />,
    roles: ['DAILY_BALANCE_VIEW'],
    subRoutesPrimary: [
      {
        path: '/report/daily-balance',
        name: 'Daily Balance',
        icon: <HiDocumentReport />,
        roles: 'DAILY_BALANCE_VIEW'
      }
    ]
  },
  {
    path: '/administrator',
    name: 'Administrator',
    icon: <MdOutlineAdminPanelSettings />,
    roles: ['USER_LIST_VIEW', 'ROLE_MANAGEMENT_VIEW'],
    subRoutesPrimary: [
      {
        path: '/administrator/user-list',
        name: 'User List',
        icon: <RiAdminLine />,
        roles: 'USER_LIST_VIEW'
      },
      {
        path: '/administrator/role-management',
        name: 'Role Management',
        icon: <RiAdminLine />,
        roles: 'ROLE_MANAGEMENT_VIEW'
      }
    ]
  }
]

export default navigationRoutes
