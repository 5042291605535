import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import api from '../../../utils/api'
import { GetState, RejectWithValueState, GetWithdrawalsState, GetWithdrawalPayloadState, WithdrawalState } from './interfaces'
import dayjs from 'dayjs'
import { clientTimeZone } from '../../../utils/utils'

// Async Requests
export const getWithdrawal = createAsyncThunk<GetWithdrawalsState, GetWithdrawalPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/getWithdrawal',
  async ({ bankIds, currencies, endDate, pageIndex, pageSize, searchTerm, searchType, startDate, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchant-withdraw/transactions', {
      bankIds,
      currencies,
      endDate,
      pageIndex,
      pageSize,
      searchTerm,
      searchType,
      startDate,
      clientTimeZone,
      status
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const exportWithdrawalExcel = createAsyncThunk<any, GetWithdrawalPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/exportWithdrawalExcel',
  async ({ bankIds, currencies, endDate, pageIndex, pageSize, searchTerm, searchType, startDate, status }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchant-withdraw/exportwithdraw', {
      bankIds,
      currencies,
      endDate,
      pageIndex,
      pageSize,
      searchTerm,
      searchType,
      startDate,
      status,
      clientTimeZone
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/vnd.ms-excel'
      },
      responseType: 'blob'
    })

    const currentDay = dayjs(Date.now()).format('YYYYMMDDHHmmss')
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `MerchantTransactionWithdrawal-${currentDay}.xlsx`)
    document.body.appendChild(link)
    link.click()
    link.remove()

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const withdrawalState: WithdrawalState = {
  getWithdrawalLoading: false,
  exportWithdrawalExcelLoading: false,
  withdrawals: {
    data: [],
    total: 0
  }
}

// Slices
const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: withdrawalState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawal.fulfilled, (state, action) => {
        state.withdrawals.data = action.payload.data
        state.withdrawals.total = action.payload.total
      })
      .addCase(getWithdrawal.pending, (state) => {
        state.getWithdrawalLoading = true
      })
      .addCase(exportWithdrawalExcel.pending, (state) => {
        state.exportWithdrawalExcelLoading = true
      })
      .addMatcher(isAnyOf(getWithdrawal.fulfilled,
        getWithdrawal.rejected,
        exportWithdrawalExcel.fulfilled,
        exportWithdrawalExcel.rejected), (state) => {
        state.getWithdrawalLoading = false
        state.exportWithdrawalExcelLoading = false
      })
  }
})

// Selectors
export const selectGetWithdrawalLoading = (state: RootState): boolean => state.withdrawalReducer.getWithdrawalLoading
export const selectWithdrawals = (state: RootState): GetWithdrawalsState => state.withdrawalReducer.withdrawals
export const selectExportWithdrawalExcelLoading = (state: RootState): boolean => state.withdrawalReducer.exportWithdrawalExcelLoading

export default withdrawalSlice.reducer
