import { useEffect, useCallback, useState } from 'react'
import { message, Button, Row, Col, Input, Select, Modal, Form, Grid } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import { selectRolePermission, selectSelectList } from '../../LoginPage/reducers'
import dayjs from 'dayjs'
import { isNullOrUndefined, getCurrencyName, getCurrencyValue, pascalCase, datetime } from '../../../utils/utils'
import { RangeValue } from 'rc-picker/lib/interface'
import { TableContainer } from '../../../components/styles'
import { SettlementFilters } from './interfaces'
import { getSettlement, selectSettlements, selectGetSettlementLoading, postSettlementCancelRequest, selectPostSettlementCancelRequestLoading } from './reducers'
import { CancelRequestButton, SettlementButtonsContainer, ButtonWithIcon } from './styles'
import AddSettlementForm from './components/AddSettlementForm'
import NumberFormat from 'react-number-format'
import { RiDeleteBinLine, RiSearchLine, RiFileAddLine } from 'react-icons/ri'
import Table from '../../../components/TableComponent'
import { selectGlobalCurrency } from '../../AuthLayout/reducers'
import { StatusDisplay } from '../styles'
import { StatusCodeType } from '../../../utils/interface'
import FilterRangePicker from '../../../components/FilterRangePicker'

const { useBreakpoint } = Grid

const initialFilters = {
  currencies: undefined,
  endDate: dayjs(),
  pageIndex: 1,
  pageSize: 10,
  startDate: dayjs(),
  status: undefined,
  dateType: 1,
  beneficiaryName: ''
} as const
const SettlementPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const settlements = useAppSelector(selectSettlements)
  const getSettlementLoading = useAppSelector(selectGetSettlementLoading)
  const postSettlementCancelRequestLoading = useAppSelector(selectPostSettlementCancelRequestLoading)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const rolePermission = useAppSelector(selectRolePermission)
  const [cancelVisible, setCancelVisible] = useState(false)
  const [addVisible, setAddVisible] = useState(false)
  const [rowId, setRowId] = useState(0)
  const [filters, setFilters] = useState<SettlementFilters>(initialFilters)
  const canAddSettlement = rolePermission?.includes('SETTLEMENT_ADD')
  const canRemoveSettlement = rolePermission?.includes('SETTLEMENT_REMOVE')
  const settlementStatusSelectList = selectList.settlementStatusSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      ellipsis: true
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      ellipsis: true,
      sorter: (a, b) => a.remark.localeCompare(b.remark)
    },
    {
      title: 'Requested Amount',
      dataIndex: 'requestedAmount',
      sorter: (a, b) => a.requestedAmount - b.requestedAmount,
      ellipsis: true,
      render: (value: number, row: { currency: number }) => <NumberFormat prefix={`${getCurrencyName(row.currency)} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Fee',
      dataIndex: 'totalFee',
      ellipsis: true,
      sorter: (a, b) => a.totalFee - b.totalFee,
      render: (value: number, row: { currency: number }) => <NumberFormat prefix={`${getCurrencyName(row.currency)} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Payout Amount',
      dataIndex: 'totalTransferAmount',
      sorter: (a, b) => a.totalTransferAmount - b.totalTransferAmount,
      ellipsis: true,
      render: (value: number, row: { currency: number }) => <NumberFormat prefix={`${getCurrencyName(row.currency)} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Status',
      dataIndex: 'statusText',
      sorter: (a, b) => a.statusText.localeCompare(b.statusText),
      ellipsis: true,
      render: (value: string) => <StatusDisplay status={value.toLocaleUpperCase() as StatusCodeType}>{pascalCase(value)}</StatusDisplay>
    },
    {
      title: 'Message',
      dataIndex: 'message',
      ellipsis: true,
      sorter: (a, b) => a.message.localeCompare(b.message)
    },
    {
      title: 'Created On',
      dataIndex: 'createdTime',
      ellipsis: true,
      sorter: (a, b) => a.createdTime.localeCompare(b.createdTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('YYYY/MM/DD HH:mm:ss')
    },
    {
      title: 'Completed On',
      dataIndex: 'completedTime',
      ellipsis: true,
      sorter: (a, b) => a.completedTime?.localeCompare(b.completedTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('YYYY/MM/DD HH:mm:ss')
    },
    ...canRemoveSettlement ? [{
      title: 'Action',
      dataIndex: 'Action',
      fixed: 'right' as 'right',
      align: 'center' as 'center',
      render: (_: any, row: any) => {
        const canCancel: boolean = row.canCancel
        const rowId: number = row.id

        return canCancel ? (
          <CancelRequestButton
            type='primary'
            shape='circle'
            onClick={() => onHandleCancelRequest(rowId)}
          >
            <RiDeleteBinLine />
          </CancelRequestButton>
        ) : null
      }
    }] : []
  ]

  const handleSearch = useCallback(async (data: SettlementFilters): Promise<void> => {
    const payload = {
      ...data,
      startDate: datetime.getStartDate(data.startDate),
      endDate: datetime.getEndDate(data.endDate)
    }
    try {
      await dispatch(getSettlement(payload)).then(unwrapResult)
    } catch (error: any) {
      await message.error(error.message)
    }
  }, [dispatch])

  const getSettlementAction = useCallback(async (): Promise<any> => {
    const payload = {
      ...initialFilters,
      currencies: [getCurrencyValue(globalCurrency)]
    }

    await handleSearch(payload)
  }, [handleSearch, globalCurrency])

  function onHandleCancelRequest (rowId: number): void {
    setRowId(rowId)
    setCancelVisible(true)
  }

  function onHandleCancelRequestModal (): void {
    setCancelVisible(false)
  }

  function onHandleChangeFilters (value: any, name: string): void {
    const filterValue = name === 'beneficiaryName' ? value.target.value : value

    setFilters(prevState => ({
      ...prevState,
      [name]: filterValue === null ? '' : filterValue
    }))
  }

  function onHandleRangePicker (dates: RangeValue<dayjs.Dayjs>): void {
    if (dates !== null) {
      setFilters(prevState => ({
        ...prevState,
        startDate: dates[0],
        endDate: dates[1]
      }))
    } else {
      setFilters(prevState => ({
        ...prevState,
        startDate: null,
        endDate: null
      }))
    }
  }

  function onHandleOnChangePagination (pageIndex: number, pageSize: number | undefined): any {
    const payload = {
      ...filters,
      pageIndex,
      pageSize
    }
    setFilters(payload)
    handleSearch(payload).finally(() => {})
  }

  async function onHandleSubmitFilters (): Promise<any> {
    const payload = {
      ...filters,
      pageIndex: 1
    }
    await handleSearch(payload)
    setFilters(prevState => {
      return { ...prevState, pageIndex: 1 }
    })
  }

  async function onHandleCancelRequestSubmit (values: any): Promise<any> {
    const payload = {
      id: rowId,
      reason: values.reason
    }

    try {
      await dispatch(postSettlementCancelRequest(payload)).then(unwrapResult)
      await handleSearch(filters)
      await setCancelVisible(false)
      return await message.success('Cancel Request Success!')
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  function onHandleAddSettlement (): void {
    setAddVisible(true)
  }

  useEffect(() => {}, [])

  useEffect(() => {
    getSettlementAction().finally(() => {})
  }, [getSettlementAction])

  useEffect(() => {
    setFilters(f => {
      return {
        ...f,
        currencies: [getCurrencyValue(globalCurrency)]
      }
    })
  }, [globalCurrency])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8} xl={7} xxl={5}>
          <FilterRangePicker
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={onHandleRangePicker}
          />
        </Col>
        <Col xs={24} sm={8} xl={5} xxl={3}>
          <Select
            placeholder='Select Status'
            style={{ width: '100%' }}
            options={settlementStatusSelectList}
            onChange={(value) => onHandleChangeFilters(value, 'status')}
            allowClear
          />
        </Col>
        <Col xs={24} sm={8} xl={5} xxl={3}>
          <Input
            placeholder='Beneficiary Name'
            onChange={(value) => onHandleChangeFilters(value, 'beneficiaryName')}
          />
        </Col>
        <Col xs={24} sm={6} md={5} xl={4} xxl={2}>
          <ButtonWithIcon
            type='primary'
            shape='round'
            block={screens.xs}
            onClick={onHandleSubmitFilters}
          >
            <RiSearchLine />  Search
          </ButtonWithIcon>
        </Col>
        {
          canAddSettlement &&
            <Col style={{ textAlign: 'right' }} xs={24} sm={{ span: 5, offset: 13 }} md={{ span: 4, offset: 15 }} xl={{ span: 3, offset: 0 }} xxl={{ span: 2, offset: 9 }}>
              <ButtonWithIcon
                type='primary'
                shape='round'
                onClick={onHandleAddSettlement}
                block={screens.xs}
              >
                <RiFileAddLine /> Add
              </ButtonWithIcon>
            </Col>
        }
      </Row>
      <TableContainer>
        <Table
          dataSource={settlements.data}
          loading={getSettlementLoading}
          columns={columns}
          current={filters.pageIndex}
          pageSize={filters.pageSize}
          total={settlements.total}
          onChange={onHandleOnChangePagination}
        />
      </TableContainer>
      <Modal
        open={cancelVisible}
        title='Cancel request'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <Form
          name='cancel-request'
          onFinish={onHandleCancelRequestSubmit}
          layout='vertical'
          requiredMark={false}
          initialValues={{
            reason: ''
          }}
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                label='Are you sure you would like to cancel this request?'
                name='reason'
                rules={[{ required: true, message: 'Reason is required.' }]}
              >
                <Input placeholder='Reason' />
              </Form.Item>
            </Col>
          </Row>
          <SettlementButtonsContainer>
            <Button
              onClick={onHandleCancelRequestModal}
              shape='round'
              block={screens.xs}
            >
              Cancel
            </Button>
            <CancelRequestButton
              htmlType='submit'
              shape='round'
              type='primary'
              block={screens.xs}
              loading={postSettlementCancelRequestLoading}
            >
              Cancel
            </CancelRequestButton>
          </SettlementButtonsContainer>
        </Form>
      </Modal>
      <Modal
        open={addVisible}
        title='Create Settlement Transaction'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <AddSettlementForm setAddVisible={setAddVisible} filters={filters} />
      </Modal>
    </>
  )
}

export default SettlementPage
