import { useState } from 'react'
import { TwoFAContainer, TwoFAForm, TwoFATitle, TwoFAText, OtpButtonsContainer, ErrorMessage } from './styles'
import OtpInput from 'react-otp-input'
import { Button, message } from 'antd'
import Footer from '../../components/Footer'
import { postVerifyTwoFa, selectAuthLoading, logOut } from './reducers'
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { persistor } from '../../store/store'
import { ButtonEvent } from '../../utils/interface'

const TwoFactorAuthenticationPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const authLoading = useAppSelector(selectAuthLoading)
  const [otp, setOtp] = useState<string>('')
  const [hasError, setHasError] = useState<boolean>(false)
  const containerStyle = {
    justifyContent: 'center',
    marginBottom: '30px'
  }
  const inputStyle = {
    height: '40px',
    width: '40px',
    fontSize: '18px'
  }
  const errorStyle = {
    border: '2px solid #f5222d'
  }

  function onHandleChangeOtp (otp: string): void {
    setHasError(false)
    return setOtp(otp)
  }

  const onHandleSubmitOtp = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()

    try {
      if (otp.length < 6) {
        return setHasError(true)
      } else {
        await setHasError(false)
        await dispatch(postVerifyTwoFa(otp)).then(unwrapResult)
        return await message.success('Two-Factor Authentication Success!')
      }
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  const onHandleCancelOtp = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()
    await persistor.purge()
    await dispatch(logOut())
    return window.location.reload()
  }

  return (
    <TwoFAContainer>
      <TwoFAForm>
        <TwoFATitle>Two-Factor Authentication</TwoFATitle>
        <TwoFAText>
          Your account has been configured to use two-factor authentication. Type your 6 digit code below to verify this action.
        </TwoFAText>
        <OtpInput
          value={otp}
          onChange={onHandleChangeOtp}
          numInputs={6}
          separator={<span>-</span>}
          shouldAutoFocus
          isInputNum
          containerStyle={containerStyle}
          inputStyle={inputStyle}
          hasErrored={hasError}
          errorStyle={errorStyle}
        />
        <OtpButtonsContainer>
          <Button onClick={onHandleCancelOtp}>
            Cancel
          </Button>
          <Button type='primary' onClick={onHandleSubmitOtp} loading={authLoading}>
            Submit
          </Button>
        </OtpButtonsContainer>
        {
          hasError &&
            <ErrorMessage>OTP is required.</ErrorMessage>
        }
      </TwoFAForm>
      <Footer />
    </TwoFAContainer>
  )
}

export default TwoFactorAuthenticationPage
