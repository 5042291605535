import { unwrapResult } from '@reduxjs/toolkit'
import { Form, Table, Checkbox, Row, Col, Input, message } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { DataArrayChild, DataSource, DataSourceArray, SaveRoleEditPayload } from '../interfaces'
import {
  postSaveRoleEdit,
  selectRoleEditList,
  selectRoleEditLoading,
  getRoleEditList,
  setRoleEditList,
  selectRoleManagementList,
  getRoleManagementList,
  selectRoleEditNode
} from '../reducers'
import { TableContainer, ButtonWithIcon } from '../styles'

const RoleEditPage = (props: RouteComponentProps<any>): JSX.Element => {
  const dispatch = useAppDispatch()
  const roleList = useAppSelector(selectRoleEditList)
  const roleManagementList = useAppSelector(selectRoleManagementList)
  const roleEditNode = useAppSelector(selectRoleEditNode)
  const roleEditLoading = useAppSelector(selectRoleEditLoading)
  const { history, match: { params } } = props
  const userId = params.id
  const screens = useBreakpoint()
  const [form] = Form.useForm()
  const getRoleManagementListAction = useCallback(async (): Promise<any> => {
    await dispatch(getRoleManagementList()).then(unwrapResult)
  }, [dispatch])

  const getRoleEditListAction = useCallback(async (): Promise<any> => {
    try {
      await dispatch(getRoleEditList(userId)).then(unwrapResult)
    } catch (error: any) {
      setTimeout(() => {
        history.push('/dashboard')
      }, 5000)
      return await message.error(error.message, 5)
    }
  }, [dispatch, userId, history])

  const postSaveRoleEditAction = useCallback(async (payload: SaveRoleEditPayload): Promise<any> => {
    try {
      await dispatch(postSaveRoleEdit(payload)).then(unwrapResult)
      await history.push('/administrator/role-management')
      return await message.success('Edit Success!')
    } catch (error: any) {
      return await message.error(error.message, 5)
    }
  }, [dispatch, history])

  const sharedOnCell = (row: { hasChild: boolean }, i: number): { colSpan: number } | undefined => {
    if (row.hasChild) {
      return { colSpan: 0 }
    }
  }

  const onHandleSaveUser = async (): Promise<any> => {
    const payload: SaveRoleEditPayload = {
      permissionIds: roleList,
      roleType: parseInt(userId)
    }
    postSaveRoleEditAction(payload).finally(() => {})
  }

  const onHandleCancel = (): void => {
    history.push('/administrator/role-management')
  }

  const handleRoleListSubmit = (): void => { }

  const columns: Array<{}> = [
    {
      title: 'Menu',
      dataIndex: 'module',
      // ellipsis: true,
      width: '550px',
      onCell: (row: DataSource, i: any) => {
        if (row.hasChild) {
          return {
            colSpan: 8
          }
        }
      },
      render: (text: any, row: DataSource) => {
        return {
          props: {
            style: {
              background: row.hasChild ? 'rgb(235,235,235)' : 'white'
            }
          },
          children: (
            <>
              <div><b>{row.module}</b></div>
              {!row.hasChild && <div><em>{row.description}</em></div>}
            </>
          )
        }
      }
    },
    {
      title: 'View Page',
      ellipsis: true,
      align: 'center',
      onCell: sharedOnCell,
      render: (_: any, row: DataSource) => {
        return (
          <Checkbox
            checked={roleList.includes(`${row.moduleCode}_VIEW`)}
            onChange={async () => {
              await dispatch(setRoleEditList(`${row.moduleCode}_VIEW`))
            }}
            disabled={row.isAllowView === null}
          />
        )
      }
    },
    {
      title: 'Add',
      ellipsis: true,
      align: 'center',
      onCell: sharedOnCell,
      render: (_: any, row: DataSource) => {
        return (
          <Checkbox
            checked={roleList.includes(`${row.moduleCode}_ADD`)}
            onChange={async () => {
              await dispatch(setRoleEditList(`${row.moduleCode}_ADD`))
            }}
            disabled={row.isAllowAdd === null}
          />
        )
      }
    },
    {
      title: 'Edit',
      ellipsis: true,
      align: 'center',
      onCell: sharedOnCell,
      render: (_: any, row: DataSource) => {
        return (
          <Checkbox
            checked={roleList.includes(`${row.moduleCode}_EDIT`)}
            onChange={async () => {
              await dispatch(setRoleEditList(`${row.moduleCode}_EDIT`))
            }}
            disabled={row.isAllowEdit === null}
          />
        )
      }
    },
    {
      title: 'Export',
      ellipsis: true,
      align: 'center',
      onCell: sharedOnCell,
      render: (_: any, row: DataSource) => {
        return (
          <Checkbox
            checked={roleList.includes(`${row.moduleCode}_EXPORT`)}
            onChange={async () => {
              await dispatch(setRoleEditList(`${row.moduleCode}_EXPORT`))
            }}
            disabled={row.isAllowExport === null}
          />
        )
      }
    },
    {
      title: 'Create',
      ellipsis: true,
      align: 'center',
      onCell: sharedOnCell,
      render: (_: any, row: DataSource) => {
        return (
          <Checkbox
            checked={roleList.includes(`${row.moduleCode}_CREATE`)}
            onChange={async () => {
              await dispatch(setRoleEditList(`${row.moduleCode}_CREATE`))
            }}
            disabled={row.isAllowCreate === null}
          />
        )
      }
    },
    {
      title: '2FA',
      ellipsis: true,
      align: 'center',
      onCell: sharedOnCell,
      render: (_: any, row: DataSource) => {
        return (
          <Checkbox
            checked={roleList.includes(`${row.moduleCode}_2FA`)}
            onChange={async () => {
              await dispatch(setRoleEditList(`${row.moduleCode}_2FA`))
            }}
            disabled={row.isAllow2fa === null}
          />
        )
      }
    },
    {
      title: 'Cancel/ Remove',
      ellipsis: true,
      align: 'center',
      onCell: sharedOnCell,
      render: (_: any, row: DataSource) => {
        return (
          <Checkbox
            checked={roleList.includes(`${row.moduleCode}_REMOVE`)}
            onChange={async () => {
              await dispatch(setRoleEditList(`${row.moduleCode}_REMOVE`))
            }}
            disabled={row.isAllowRemove === null}
          />
        )
      }
    }

  ]

  const dataSource: DataArrayChild[] = roleEditNode.map((data: DataSourceArray, i) => {
    if (data.group === null) {
      return [...data.rolePermissions].flat()
    } else {
      const { rolePermissions, ...rest } = data
      const parent = { ...rest, module: data.group, hasChild: true, moduleCode: data.group }
      return [parent, rolePermissions].flat()
    }
  }).flat()

  useEffect(() => {
    const role = roleManagementList.filter((value) => value.id === parseFloat(userId))
    if (role.length !== 0) {
      form.setFieldsValue({
        roleName: role[0].roleName
      })
    }
  }, [form, userId, roleManagementList])

  useEffect(() => {
    if (roleManagementList.length === 0) {
      getRoleManagementListAction().finally(() => { })
    }
  }, [roleManagementList, getRoleManagementListAction])

  useEffect(() => {
    getRoleEditListAction().finally(() => {})
  }, [getRoleEditListAction])

  return (
    <>
      <Form
        name='user-detail-form'
        onFinish={handleRoleListSubmit}
        requiredMark={false}
        layout='horizontal'
        form={form}
      >
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label='Role Name' name='roleName'>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <TableContainer>
        <Table
          dataSource={dataSource}
          loading={roleEditLoading}
          scroll={{ x: 1200 }}
          columns={columns}
          rowKey='id'
          size='small'
          tableLayout='auto'
          bordered
          pagination={{
            pageSize: dataSource.length,
            position: ['topRight'],
            total: dataSource.length,
            showQuickJumper: dataSource.length > 40,
            showSizeChanger: dataSource.length >= 40,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
          }}
          title={() => 'Role Access Setting'}
        />
      </TableContainer>
      <Row>
        <Col>
          <ButtonWithIcon
            type='primary'
            onClick={onHandleSaveUser}
            block={screens.xs}
            shape='round'
          >
            Save
          </ButtonWithIcon>
        </Col>
        <Col>
          <ButtonWithIcon
            type='default'
            onClick={onHandleCancel}
            block={screens.xs}
            shape='round'
          >
            Cancel
          </ButtonWithIcon>
        </Col>
      </Row>
    </>
  )
}

export default RoleEditPage
