import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import {
  RejectWithValueState,
  GetState,
  DashboardState,
  GetDashboardInfoState,
  GetDashboardInfoPayloadState
} from './interfaces'
import api from '../../utils/api'

// Async Requests
export const getDashboardInfo = createAsyncThunk<GetDashboardInfoState, GetDashboardInfoPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'dashboard/getDashboardInfo',
  async ({ currencyId }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get(`/Dashboard/GetNewMerchantDashBoardInformation?currencyId=${currencyId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial States
const dashboardState: DashboardState = {
  getDashboardInfoLoading: false,
  getDashBoardInfoData: {
    balance: {
      balance: 0,
      currency: 1,
      settledBalance: 0,
      unsettleBalance: 0
    },
    todayDeposit: {
      amount: 0,
      serviceFee: 0,
      total: 0
    },
    todayWithdrawal: {
      amount: 0,
      serviceFee: 0,
      total: 0
    },
    todayTransaction: {
      amount: 0,
      serviceFee: 0,
      total: 0
    }
  }
}

// Slices
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: dashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardInfo.pending, (state) => {
        state.getDashboardInfoLoading = true
      })
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        state.getDashBoardInfoData = {
          balance: action.payload.balance,
          todayDeposit: action.payload.todayDeposit,
          todayWithdrawal: action.payload.todayWithdrawal,
          todayTransaction: action.payload.todayTransaction
        }
      })
      .addMatcher(isAnyOf(getDashboardInfo.fulfilled,
        getDashboardInfo.rejected), (state) => {
        state.getDashboardInfoLoading = false
      })
  }
})

// selectors
export const selectGetDashboardInfoLoading = (state: RootState): boolean => state.dashboardReducer.getDashboardInfoLoading
export const selectGetDashboardInfoData = (state: RootState): GetDashboardInfoState | undefined => state.dashboardReducer.getDashBoardInfoData

export default dashboardSlice.reducer
