import { useEffect, useCallback, useState } from 'react'
import { message } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import { selectMerchantId } from '../../LoginPage/reducers'
import { TableContainer } from '../../../components/styles'
import { getCurrenciesSupported, selectCurrenciesSupported, selectGetCurrenciesSupportedLoading } from '../reducers'
import { isNullOrUndefined } from '../../../utils/utils'
import dayjs from 'dayjs'
import NumberFormat from 'react-number-format'
import { CurrentPagination } from '../interfaces'
import Table from '../../../components/TableComponent'

const CurrenciesSupported = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const merchantId = useAppSelector(selectMerchantId)
  const currenciesSupported = useAppSelector(selectCurrenciesSupported)
  const getCurrenciesSupportedLoading = useAppSelector(selectGetCurrenciesSupportedLoading)
  const [currentPagination, setCurrentPagination] = useState<CurrentPagination>({
    pageIndex: 1,
    pageSize: 10
  })

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'currencyCode',
      sorter: (a, b) => a.currencyCode - b.currencyCode,
      ellipsis: true
    },
    {
      title: 'Currency',
      dataIndex: 'currencyName',
      sorter: (a, b) => a.currencyName.localeCompare(b.currencyName),
      ellipsis: true
    },
    {
      title: 'Current Balance',
      dataIndex: 'currentBalance',
      sorter: (a, b) => a.currentBalance - b.currentBalance,
      ellipsis: true,
      render: (value: number, row: { currencyName: string }) => <NumberFormat prefix={`${row.currencyName} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Available Balance',
      dataIndex: 'availableBalance',
      sorter: (a, b) => a.availableBalance - b.availableBalance,
      ellipsis: true,
      render: (value: number, row: { currencyName: string }) => <NumberFormat prefix={`${row.currencyName} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Created Time',
      dataIndex: 'createdTime',
      ellipsis: true,
      sorter: (a, b) => a.createdTime.localeCompare(b.createdTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('DD/MM/YYYY HH:mm')
    }
  ]

  const getCurrenciesSupportedAction = useCallback(async (): Promise<any> => {
    const payload = {
      merchantId,
      pageIndex: 1,
      pageSize: 10
    }

    try {
      await dispatch(getCurrenciesSupported(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }, [dispatch, merchantId])

  function onHandleOnChangePagination (page: number, pageSize: number | undefined): any {
    const payload = {
      merchantId,
      pageIndex: page,
      pageSize
    }
    setCurrentPagination({
      pageIndex: page,
      pageSize
    })
    return dispatch(getCurrenciesSupported(payload))
  }

  useEffect(() => {
    getCurrenciesSupportedAction().finally(() => {})
  }, [getCurrenciesSupportedAction])

  return (
    <>
      <TableContainer>
        <Table
          dataSource={currenciesSupported.data}
          loading={getCurrenciesSupportedLoading}
          columns={columns}
          current={currentPagination.pageIndex}
          pageSize={currentPagination.pageSize}
          total={currenciesSupported.total}
          onChange={onHandleOnChangePagination}
        />
      </TableContainer>
    </>
  )
}

export default CurrenciesSupported
