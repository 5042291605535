import { useEffect, useCallback, useState } from 'react'
import { message, Row, Col, Input, Select, Grid } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import { selectRolePermission, selectSelectList } from '../../LoginPage/reducers'
import dayjs from 'dayjs'
import { pascalCase, isNullOrUndefined, getCurrencyValue, datetime } from '../../../utils/utils'
import { RangeValue } from 'rc-picker/lib/interface'
import { TableContainer } from '../../../components/styles'
import { getMerchantSummary, selectMerchantSummary, selectGetMerchantSummaryLoading, exportMerchantSummaryExcel, selectExportMerchantSummaryExcelLoading } from './reducers'
import { DataInterface, MerchantSummaryFilters } from './interfaces'
import NumberFormat from 'react-number-format'
import { selectGlobalCurrency } from '../../AuthLayout/reducers'
import { RiSearchLine } from 'react-icons/ri'
import { SiMicrosoftexcel } from 'react-icons/si'
import { ButtonWithIcon } from './styles'
import Table from '../../../components/TableComponent'
import { StatusCodeType } from '../../../utils/interface'
import { StatusDisplay } from '../styles'
import TooltipComponent from '../../../components/Tooltip'
import { CaretUpOutlined } from '@ant-design/icons'
import FilterRangePicker from '../../../components/FilterRangePicker'

const { useBreakpoint } = Grid
const { Option } = Select

const initialFilters = {
  bankId: undefined,
  currency: undefined,
  endDate: dayjs(),
  pageIndex: 1,
  pageSize: 10,
  searchTerm: '',
  searchType: 2,
  startDate: dayjs(),
  transactionType: undefined
} as const

const MerchantSummaryPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantSummary = useAppSelector(selectMerchantSummary)
  const getMerchantSummaryLoading = useAppSelector(selectGetMerchantSummaryLoading)
  const exportMerchantSummaryExcelLoading = useAppSelector(selectExportMerchantSummaryExcelLoading)
  const rolePermission = useAppSelector(selectRolePermission)
  const canExport: boolean = rolePermission?.includes('MERCHANT_SUMMARY_EXPORT')
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const [filters, setFilters] = useState<MerchantSummaryFilters>(initialFilters)
  const providerSelectList = selectList.providerSelectList.filter(p => (filters.currency !== undefined && filters.currency > 0) ? filters.currency === p.currency : true)
    .map((item: { code: string, name: string, id: number }) => ({
      label: `${item.code} - ${item.name}`,
      value: item.id
    })
    ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)
  const merchantTransactionTypeSelectList = selectList.merchantTransactionTypeSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  const columns: ColumnsType<DataInterface> = [
    {
      title: 'ID',
      dataIndex: 'masterTransactionId',
      sorter: (a, b) => a.id - b.id,
      ellipsis: true
    },
    {
      title: 'Reference Code',
      dataIndex: 'transactionCode',
      sorter: (a, b) => a.transactionCode.localeCompare(b.transactionCode),
      ellipsis: true
    },
    {
      title: 'Type',
      dataIndex: 'methodTypeText',
      sorter: (a, b) => a.methodTypeText.localeCompare(b.methodTypeText),
      ellipsis: true
    },
    {
      title: 'Provider',
      dataIndex: 'bank',
      sorter: (a, b) => a.bank.localeCompare(b.bank),
      ellipsis: true
    },
    {
      title: 'Account Name',
      dataIndex: 'name',
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Account No',
      dataIndex: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      render: (value: string) => isNullOrUndefined(value) ? null : value.substr(-4),
      ellipsis: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      ellipsis: true,
      render: (value: number, row) => {
        if (row.markupRate != null) {
          const text = (
            <div>
              <div>Exchange Amount ({row.currency})</div>
              {row.exchangeCreatedRate != null && <div>Exchange Created Rate</div>}
              <div>Amount ({row.exchangeToCurrency})</div>
            </div>
          )
          return (
            <TooltipComponent text={text} color='#eeeded' placement='left'>
              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <div style={{ fontSize: 14, color: 'green' }}>
                  <CaretUpOutlined color='green' />
                  <NumberFormat value={row.exchangeAmount} displayType='text' thousandSeparator />
                </div>
                <NumberFormat value={row.exchangeCreatedRate} displayType='text' thousandSeparator />
                <NumberFormat value={row.amount} displayType='text' thousandSeparator />
              </div>
            </TooltipComponent>
          )
        }
        return <NumberFormat prefix={`${row.currency} `} value={value} displayType='text' thousandSeparator />
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: (a, b) => a.remarks - b.remarks,
      ellipsis: true
    },
    {
      title: 'Rate (%)',
      dataIndex: 'transactionRate',
      sorter: (a, b) => a.transactionRate - b.transactionRate,
      ellipsis: true
    },
    {
      title: 'Bank Fee',
      dataIndex: 'fee',
      sorter: (a, b) => a.fee - b.fee,
      ellipsis: true,
      render: (value: number, row) => <NumberFormat prefix={`${row.currency} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Service Fee',
      dataIndex: 'systemFee',
      sorter: (a, b) => a.systemFee - b.systemFee,
      ellipsis: true,
      render: (value: number, row: { currency: string }) => <NumberFormat prefix={`${row.currency} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'End Balance',
      dataIndex: 'endBalance',
      sorter: (a, b) => a.systemFee - b.systemFee,
      ellipsis: true,
      render: (value: number) => <NumberFormat value={value} displayType='text' thousandSeparator decimalScale={2} fixedDecimalScale />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      ellipsis: true,
      render: (value: string) => <StatusDisplay status={value.toLocaleUpperCase() as StatusCodeType}>{pascalCase(value)}</StatusDisplay>
    },
    {
      title: 'Completed On',
      dataIndex: 'completedTime',
      ellipsis: true,
      sorter: (a, b) => a.createdTime?.localeCompare(b.createdTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('YYYY/MM/DD HH:mm:ss')
    }
  ]

  const handleSearch = useCallback(async (data: MerchantSummaryFilters): Promise<any> => {
    const payload = {
      ...data,
      startDate: datetime.getStartDate(data.startDate),
      endDate: datetime.getEndDate(data.endDate)
    }
    try {
      await dispatch(getMerchantSummary(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }, [dispatch])

  const getMerchantSummaryAction = useCallback(async (): Promise<any> => {
    const payload = {
      ...initialFilters,
      currency: getCurrencyValue(globalCurrency)
    }
    await handleSearch(payload)
  }, [handleSearch, globalCurrency])

  function onHandleChangeFilters (value: any, name: string): void {
    const filterValue = name === 'searchTerm' ? value.target.value : value
    if (value === 'SELECTALL') {
      const newFilter = { ...filters }
      delete newFilter.transactionType
      setFilters(prev => ({
        ...newFilter
      }))
    } else {
      setFilters(prevState => ({
        ...prevState,
        [name]: filterValue === null ? '' : filterValue
      }))
    }
  }

  function onHandleRangePicker (dates: RangeValue<dayjs.Dayjs>): void {
    if (dates !== null) {
      setFilters(prevState => ({
        ...prevState,
        startDate: dates[0],
        endDate: dates[1]
      }))
    } else {
      setFilters(prevState => ({
        ...prevState,
        startDate: null,
        endDate: null
      }))
    }
  }

  async function onHandleSubmitFilters (): Promise<void> {
    const payload = {
      ...filters,
      pageIndex: 1
    }
    handleSearch(payload).finally(() => { })
    setFilters(prevState => ({ ...prevState, pageIndex: 1 }))
  }

  async function onHandleOnChangePagination (pageIndex: number, pageSize: number | undefined): Promise<void> {
    const newFilter = {
      ...filters,
      pageIndex,
      pageSize
    }
    setFilters(newFilter)
    await handleSearch(newFilter)
  }

  async function onHandleExportExcel (): Promise<any> {
    const payload = {
      ...filters,
      startDate: datetime.getStartDate(filters.startDate),
      endDate: datetime.getEndDate(filters.endDate)
    }

    try {
      await dispatch(exportMerchantSummaryExcel(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  useEffect(() => {
    getMerchantSummaryAction().finally(() => {})
  }, [getMerchantSummaryAction])

  useEffect(() => {
    setFilters(f => {
      return {
        ...f,
        currency: getCurrencyValue(globalCurrency),
        bankId: undefined
      }
    })
  }, [globalCurrency])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={5} lg={5} xxl={3}>
          <Select
            showSearch
            optionFilterProp='label'
            style={{ width: '100%' }}
            placeholder='Select Bank/Provider'
            defaultValue={filters.bankId}
            value={filters.bankId}
            options={providerSelectList}
            onChange={(value) => onHandleChangeFilters(value, 'bankId')}
            allowClear
          />
        </Col>
        <Col xs={24} sm={5} lg={5} xxl={3}>
          <Select
            style={{ width: '100%' }}
            placeholder='Transaction Type'
            defaultValue={filters.transactionType}
            onChange={(value) => onHandleChangeFilters(value, 'transactionType')}
            allowClear
          >
            <Option value='SELECTALL'>Select All</Option>
            {merchantTransactionTypeSelectList.map((item) => {
              return <Option key={item.value} value={item.value}>{item.label}</Option>
            })}
          </Select>
        </Col>
        <Col xs={24} sm={8} lg={8} xxl={4}>
          <FilterRangePicker
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={onHandleRangePicker}
          />
        </Col>
        <Col xs={24} sm={6} lg={6} xxl={3}>
          <Input
            placeholder='Reference Code'
            onChange={(value) => onHandleChangeFilters(value, 'searchTerm')}
          />
        </Col>
        <Col xs={24} sm={5} lg={4} xxl={2}>
          <ButtonWithIcon
            type='primary'
            shape='round'
            block={screens.xs}
            onClick={onHandleSubmitFilters}
          >
            <RiSearchLine />  Search
          </ButtonWithIcon>
        </Col>
        {
          canExport && (
            <Col xs={24} sm={{ span: 4, offset: 14 }} lg={{ span: 4, offset: 16 }} xxl={{ span: 2, offset: 7 }} style={{ textAlign: 'right' }}>
              <ButtonWithIcon
                type='primary'
                shape='round'
                block={screens.xs}
                onClick={onHandleExportExcel}
                loading={exportMerchantSummaryExcelLoading}
                disabled={merchantSummary.data.length <= 0}
              >
                <SiMicrosoftexcel />  Export
              </ButtonWithIcon>
            </Col>
          )
        }
      </Row>
      <TableContainer>
        <Table
          dataSource={merchantSummary.data}
          loading={getMerchantSummaryLoading}
          columns={columns}
          current={filters.pageIndex}
          pageSize={filters.pageSize}
          total={merchantSummary.total}
          onChange={onHandleOnChangePagination}
        />
      </TableContainer>
    </>
  )
}

export default MerchantSummaryPage
