import { Button, Result } from 'antd'
import { RouteComponentProps } from 'react-router-dom'

const pathNameVsTitle = {
  '/dashboard': 'Dashboard',
  '/merchant': 'Merchant',
  '/transaction/merchant-summary': 'Merchant Summary',
  '/transaction/deposit': 'Deposit',
  '/transaction/withdrawal': 'Withdrawal',
  '/transaction/settlement': 'Settlement',
  '/transaction/topup': 'Top Up',
  '/report/daily-balance': 'Daily Balance',
  '/profile': 'Profile',
  '/change-password': 'Change Password',
  '/administrator/user-list': 'User List',
  '/administrator/role-management': 'Role Management'
}

const subtitles = {
  404: 'Sorry, the page you visited does not exist.',
  403: 'Sorry, you are not authorized to access this page. Please contact your administrator to request for access.'
}

const getTitle = (pathname: keyof typeof pathNameVsTitle): string => (
  pathname in pathNameVsTitle ? pathNameVsTitle[pathname] : '404: Page Not Found'
)

const getSubtitle = (pathname: keyof typeof pathNameVsTitle): string => (
  pathname in pathNameVsTitle ? subtitles['403'] : subtitles['404']
)

const NotFoundPage = (props: RouteComponentProps<any>): JSX.Element => {
  const { history, location: { pathname } } = props
  const handleGoBack = (): void => {
    history.push('/dashboard')
  }
  return (
    <Result
      status={pathname in pathNameVsTitle ? '403' : '404'}
      title={getTitle(pathname as keyof typeof pathNameVsTitle)}
      subTitle={getSubtitle(pathname as keyof typeof pathNameVsTitle)}
      extra={<Button type='primary' onClick={handleGoBack}>Back Home</Button>}
    />
  )
}

export default NotFoundPage
