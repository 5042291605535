import styled from 'styled-components'
import { Button } from 'antd'

const ButtonWithIcon = styled(Button)`
  > svg {
    margin-right: 5px;
  }
`
export {
  ButtonWithIcon
}
