import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import api from '../../../utils/api'
import {
  RejectWithValueState,
  GetState,
  GetDailyBalancePayloadState,
  DailyBalanceState,
  GetDailyBalanceState
} from './interfaces'
import dayjs from 'dayjs'
import { clientTimeZone } from '../../../utils/utils'

// Async Requests
export const getDailyBalance = createAsyncThunk<GetDailyBalanceState, GetDailyBalancePayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'reports/getDailyBalance',
  async ({ currency, month, year }, { getState, rejectWithValue, dispatch }) => {
    const { authReducer: { authToken } } = getState()
    dispatch(initGetDailyBalance())
    const response = await api.post('/merchantReport/GetMonthlySummary', {
      currency,
      month,
      year
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const exportDailyBalanceExcel = createAsyncThunk<any, GetDailyBalancePayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'reports/exportDailyBalanceExcel',
  async ({ currency, month, year }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchantReport/exportExcelReportDailyBalance', {
      currency,
      month,
      year,
      clientTimeZone
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/vnd.ms-excel'
      },
      responseType: 'blob'
    })

    const currentDay = dayjs(Date.now()).format('YYYYMMDDHHmmss')
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `ReportDailyBalance-${currentDay}.xlsx`)
    document.body.appendChild(link)
    link.click()
    link.remove()

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const dailyBalanceState: DailyBalanceState = {
  dailyBalanceLoading: false,
  dailyBalance: {
    details: [],
    totalAdjustment: 0,
    totalDeposit: 0,
    totalDepositFee: 0,
    totalSettlement: 0,
    totalSettlementFee: 0,
    totalTopUp: 0,
    totalWithdrawal: 0,
    totalWithdrawalBankFee: 0,
    totalWithdrawalServiceFee: 0

  },
  exportDailyBalanceExcelLoading: false
}

// Slices
const dailyBalanceSlice = createSlice({
  name: 'dailyBalance',
  initialState: dailyBalanceState,
  reducers: {
    initGetDailyBalance (state) {
      state.dailyBalance.details = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDailyBalance.fulfilled, (state, action) => {
        state.dailyBalance.details = action.payload.details
        state.dailyBalance.totalAdjustment = action.payload.totalAdjustment
        state.dailyBalance.totalDeposit = action.payload.totalDeposit
        state.dailyBalance.totalDepositFee = action.payload.totalDepositFee
        state.dailyBalance.totalSettlement = action.payload.totalSettlement
        state.dailyBalance.totalSettlementFee = action.payload.totalSettlementFee
        state.dailyBalance.totalTopUp = action.payload.totalTopUp
        state.dailyBalance.totalWithdrawal = action.payload.totalWithdrawal
        state.dailyBalance.totalWithdrawalBankFee = action.payload.totalWithdrawalBankFee
        state.dailyBalance.totalWithdrawalServiceFee = action.payload.totalWithdrawalServiceFee
      })
      .addCase(getDailyBalance.pending, (state) => {
        state.dailyBalanceLoading = true
      })
      .addCase(exportDailyBalanceExcel.pending, (state) => {
        state.exportDailyBalanceExcelLoading = true
      })
      .addMatcher(isAnyOf(getDailyBalance.fulfilled,
        getDailyBalance.rejected,
        exportDailyBalanceExcel.fulfilled,
        exportDailyBalanceExcel.rejected), (state) => {
        state.dailyBalanceLoading = false
        state.exportDailyBalanceExcelLoading = false
      })
  }
})

// actions

export const { initGetDailyBalance } = dailyBalanceSlice.actions

// Selectors
export const selectDailyBalanceLoading = (state: RootState): boolean => state.dailyBalanceReducer.dailyBalanceLoading
export const selectDailyBalance = (state: RootState): GetDailyBalanceState => state.dailyBalanceReducer.dailyBalance
export const selectExportDailyBalanceExcelLoading = (state: RootState): boolean => state.dailyBalanceReducer.exportDailyBalanceExcelLoading

export default dailyBalanceSlice.reducer
