import { Form, Select, Button, Row, Col, message, Input, Grid } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { selectSelectList, selectMerchantId } from '../../../LoginPage/reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { AddWhiteListFormState } from '../../interfaces'
import { getWhitelists, postValidateWhiteList, postAddWhiteList, selectPostAddWhiteListLoading } from '../../reducers'
import { BankAndWhiteListButtonsContainer } from '../../styles'

const { useBreakpoint } = Grid

const AddWhiteListForm = (props: AddWhiteListFormState): JSX.Element => {
  const { setAddVisible, filters, onHandleChangeFilters } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const postAddWhiteListLoading = useAppSelector(selectPostAddWhiteListLoading)
  const [form] = Form.useForm()

  const whiteListTypeSelectList = selectList.whiteListTypeSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  async function onHandleAddWhiteListSubmit (values: any): Promise<any> {
    const addSubmitPayload = {
      ipAddress: values.ipAddress,
      merchantId,
      type: values.type
    }
    const filtersPayload = {
      ...filters,
      ipType: values.type
    }

    try {
      await dispatch(postAddWhiteList(addSubmitPayload)).then(unwrapResult)
      await onHandleChangeFilters(values.type, 'ipType')
      await dispatch(getWhitelists(filtersPayload))
      await dispatch(postValidateWhiteList({ type: values.type }))
      await setAddVisible(false)
      return await message.success('Add Whitelist Success!')
    } catch (error: any) {
      await message.error(error.message)
      return form.resetFields()
    }
  }

  function onHandleCancel (): void {
    setAddVisible(false)
  }

  return (
    <Form
      name='add-whitelist-form'
      form={form}
      onFinish={onHandleAddWhiteListSubmit}
      requiredMark={false}
      layout='vertical'
      initialValues={{
        ipAddress: '',
        type: undefined
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label='IP Type'
            name='type'
            rules={[{ required: true, message: 'IP Type is required.' }]}
          >
            <Select placeholder='Select IP Type' options={whiteListTypeSelectList} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='IP Address'
            name='ipAddress'
            rules={[
              { required: true, message: 'IP Address is required.' },
              { pattern: new RegExp(/\d+/g), message: 'IP address is not a valid.' }
            ]}
          >
            <Input placeholder='IP Address' />
          </Form.Item>
        </Col>
      </Row>
      <BankAndWhiteListButtonsContainer>
        <Button
          onClick={onHandleCancel}
          block={screens.xs}
          shape='round'
        >
          Cancel
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          block={screens.xs}
          shape='round'
          loading={postAddWhiteListLoading}
        >
          Submit
        </Button>
      </BankAndWhiteListButtonsContainer>
    </Form>
  )
}

export default AddWhiteListForm
