import styled from 'styled-components'
import { Button } from 'antd'

const CancelRequestButton = styled(Button)`
  background: ${props => props.theme.colors.disableColor};
  border-color: ${props => props.theme.colors.disableColor};
  color: ${props => props.theme.colors.secondaryColor};

  > svg {
    margin-right: 5px;
  }

  &:hover, &:active, &:focus {
    background: ${props => props.theme.colors.disableColor};
    border-color: ${props => props.theme.colors.disableColor};
    color: ${props => props.theme.colors.secondaryColor};
    opacity: 0.8;
  }
`
const SettlementButtonsContainer = styled.div`
  margin: 16px 0 0;
  text-align: right;

  > button {
    margin: 0 0 0 16px;

    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
  }
`
const ButtonWithIcon = styled(Button)`
  > svg {
    margin-right: 5px;
  }
`

export {
  CancelRequestButton,
  SettlementButtonsContainer,
  ButtonWithIcon
}
