import { useEffect, useCallback, useState } from 'react'
import { message, Button, Col, Modal, Select, Grid, Input } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import { selectMerchantId, selectRolePermission, selectSelectList } from '../../LoginPage/reducers'
import dayjs from 'dayjs'
import { inputCheckIp, isNullOrUndefined } from '../../../utils/utils'
import { getWhitelists, selectGetWhiteListsLoading, selectWhiteLists, postValidateWhiteList, selectPostValidateWhiteListLoading, selectPostValidateWhiteListDetail } from '../reducers'
import { WhiteListFilters, WhiteListRowDetails } from '../interfaces'
import { RowContainer, ButtonWithIcon } from '../styles'
import EditWhiteListForm from './whitelistforms/EditWhiteListForm'
import AddWhiteListForm from './whitelistforms/AddWhiteListForm'
import StatusIcon from './StatusIcon'
import { BiEdit } from 'react-icons/bi'
import { RiSearchLine, RiFileAddLine } from 'react-icons/ri'
import Table from '../../../components/TableComponent'

const { useBreakpoint } = Grid

const Whitelist = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const getWhiteListsLoading = useAppSelector(selectGetWhiteListsLoading)
  const whiteLists = useAppSelector(selectWhiteLists)
  const postValidateWhiteListLoading = useAppSelector(selectPostValidateWhiteListLoading)
  const postValidateWhiteListDetail = useAppSelector(selectPostValidateWhiteListDetail)
  const rolePermission = useAppSelector(selectRolePermission)
  const canAddWhiteListPage = rolePermission?.includes('WHITELIST_ADD')
  const canEditWhiteListPage = rolePermission?.includes('WHITELIST_EDIT')
  const [filters, setFilters] = useState<WhiteListFilters>({
    pageIndex: 1,
    pageSize: 10,
    ipAddress: '',
    merchantId: merchantId,
    ipType: undefined,
    status: undefined
  })
  const [editVisible, setEditVisible] = useState(false)
  const [addVisible, setAddVisible] = useState(false)
  const [rowDetails, setRowDetails] = useState<WhiteListRowDetails>({
    id: 0,
    ipAddress: '',
    status: 0,
    type: 0
  })

  const bankWhitelistStatusList = selectList.bankWhitelistStatusList.map((item: { name: string, value: number }) =>
    ({
      label: item.name,
      value: item.value
    })
  )
  const whiteListTypeSelectList = selectList.whiteListTypeSelectList.map((item: { name: string, value: number }) =>
    ({
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  const columns: ColumnsType<any> = [
    {
      title: 'Type',
      dataIndex: 'typeText',
      sorter: (a, b) => a.typeText.localeCompare(b.typeText),
      ellipsis: true
    },
    {
      title: 'IP Address',
      dataIndex: 'ipAddress',
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'statusText',
      sorter: (a, b) => a.statusText.localeCompare(b.statusText),
      ellipsis: true,
      align: 'center',
      render: status => <StatusIcon status={status} />
    },
    {
      title: 'By User',
      dataIndex: 'byUser',
      sorter: (a, b) => a.byUser.localeCompare(b.byUser),
      ellipsis: true
    },
    {
      title: 'Created Time',
      dataIndex: 'createdTime',
      ellipsis: true,
      sorter: (a, b) => a.createdTime.localeCompare(b.createdTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('DD/MM/YYYY HH:mm')
    },
    ...canEditWhiteListPage ? [{
      title: 'Action',
      dataIndex: 'Action',
      fixed: 'right' as 'right',
      align: 'center' as 'center',
      render: (_: any, row: any) => {
        return (
          <Button type='primary' shape='circle' onClick={() => onHandleEdit(row)}>
            <BiEdit />
          </Button>
        )
      }
    }] : []
  ]

  const getWhitelistsAction = useCallback(async (): Promise<any> => {
    const payload = {
      merchantId,
      pageIndex: 1,
      pageSize: 10,
      ipAddress: '',
      ipType: undefined,
      status: undefined
    }

    try {
      await dispatch(getWhitelists(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }, [dispatch, merchantId])

  function onHandleOnChangePagination (page: number, pageSize: number | undefined): any {
    setFilters(prevState => ({
      ...prevState,
      pageIndex: page,
      pageSize: pageSize
    }))
    return dispatch(getWhitelists({ ...filters, pageIndex: page, pageSize: pageSize }))
  }

  function onHandleEdit (row: WhiteListRowDetails): void {
    setRowDetails({
      id: row.id,
      ipAddress: row.ipAddress,
      status: row.status,
      type: row.type
    })
    setEditVisible(true)
  }

  function onHandleAddUser (): void {
    setAddVisible(true)
  }

  function onHandleChangeFilters (value: any, name: string): void {
    const newFilter = { ...filters, [name]: value }
    if (name === 'ipAddress' && !inputCheckIp(value) && value != null) {
      newFilter[name] = value.slice(0, -1)
    }
    setFilters(newFilter)
  }

  async function onHandleSubmitFilters (): Promise<any> {
    const payload = {
      ...filters,
      pageIndex: 1
    }
    try {
      await dispatch(getWhitelists(payload)).then(unwrapResult)
      await dispatch(postValidateWhiteList({ type: filters.ipType }))
      setFilters(prevState => {
        return { ...prevState, pageIndex: 1 }
      })
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  useEffect(() => {
    getWhitelistsAction().finally(() => {})
  }, [getWhitelistsAction])

  return (
    <>
      <RowContainer gutter={[16, 16]}>
        <Col xs={24} sm={8} xl={5} xxl={3}>
          <Select
            placeholder='Select Status'
            style={{ width: '100%' }}
            defaultValue={filters.status}
            value={filters.status}
            onChange={(value) => onHandleChangeFilters(value, 'status')}
            options={bankWhitelistStatusList}
          />
        </Col>
        <Col xs={24} sm={8} xl={5} xxl={3}>
          <Select placeholder='Type' style={{ width: '100%' }} defaultValue={filters.ipType} value={filters.ipType} onChange={(value) => onHandleChangeFilters(value, 'ipType')} options={whiteListTypeSelectList} />
        </Col>
        <Col xs={24} sm={8} xl={5} xxl={3}>
          <Input placeholder='IP Address' onChange={(e) => onHandleChangeFilters(e.target.value, 'ipAddress')} value={filters.ipAddress} />
        </Col>
        <Col xs={24} sm={6} md={5} xl={4} xxl={2}>
          <ButtonWithIcon
            type='primary'
            shape='round'
            block={screens.xs}
            onClick={onHandleSubmitFilters}
          >
            <RiSearchLine /> Search
          </ButtonWithIcon>
        </Col>
        {
          canAddWhiteListPage &&
            <Col style={{ textAlign: 'right' }} xs={24} sm={{ span: 5, offset: 13 }} md={{ span: 4, offset: 15 }} xl={{ span: 3, offset: 2 }} xxl={{ span: 2, offset: 11 }}>
              <ButtonWithIcon
                type='primary'
                onClick={onHandleAddUser}
                loading={postValidateWhiteListLoading}
                disabled={!postValidateWhiteListDetail}
                block={screens.xs}
                shape='round'
              >
                <RiFileAddLine />  Add
              </ButtonWithIcon>
            </Col>
        }
      </RowContainer>
      <Table
        dataSource={whiteLists.data}
        loading={getWhiteListsLoading}
        columns={columns}
        current={filters.pageIndex}
        pageSize={filters.pageSize}
        total={whiteLists.total}
        onChange={onHandleOnChangePagination}
      />
      <Modal
        open={addVisible}
        title='Add White List'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <AddWhiteListForm setAddVisible={setAddVisible} filters={filters} onHandleChangeFilters={onHandleChangeFilters} />
      </Modal>
      <Modal
        open={editVisible}
        title='Edit White List'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <EditWhiteListForm {...rowDetails} setEditVisible={setEditVisible} filters={filters} />
      </Modal>
    </>
  )
}

export default Whitelist
