import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import api from '../../../utils/api'
import { MerchantSummaryState, GetState, RejectWithValueState, GetMerchantSummaryState, GetMerchantSummaryPayloadState } from './interfaces'
import dayjs from 'dayjs'
import { clientTimeZone } from '../../../utils/utils'

// Async Requests
export const getMerchantSummary = createAsyncThunk<GetMerchantSummaryState, GetMerchantSummaryPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/getMerchantSummary',
  async ({ transactionType, currency, bankId, startDate, endDate, searchType, searchTerm, pageSize, pageIndex }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/MerchantSummary/SearchTransactionSummary', {
      transactionType,
      currency,
      bankId,
      startDate,
      endDate,
      searchType,
      searchTerm,
      pageSize,
      clientTimeZone,
      pageIndex
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const exportMerchantSummaryExcel = createAsyncThunk<any, GetMerchantSummaryPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/exportMerchantSummaryExcel',
  async ({ transactionType, currency, bankId, startDate, endDate, searchType, searchTerm, pageSize, pageIndex }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/MerchantSummary/exportExcelSummary', {
      transactionType,
      currency,
      bankId,
      startDate,
      endDate,
      searchType,
      searchTerm,
      pageSize,
      pageIndex,
      clientTimeZone
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/vnd.ms-excel'
      },
      responseType: 'blob'
    })

    const currentDay = dayjs(Date.now()).format('YYYYMMDDHHmmss')
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `MerchantTransactionSummary-${currentDay}.xlsx`)
    document.body.appendChild(link)
    link.click()
    link.remove()

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const merchantSummaryState: MerchantSummaryState = {
  merchantSummary: {
    data: [],
    total: 0
  },
  getMerchantSummaryLoading: false,
  exportMerchantSummaryExcelLoading: false
}

// Slices
const merchantSummarySlice = createSlice({
  name: 'merchantSummary',
  initialState: merchantSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantSummary.fulfilled, (state, action) => {
        state.merchantSummary.data = action.payload.data
        state.merchantSummary.total = action.payload.total
      })
      .addCase(getMerchantSummary.pending, (state) => {
        state.getMerchantSummaryLoading = true
        state.merchantSummary.data = []
      })
      .addCase(exportMerchantSummaryExcel.pending, (state) => {
        state.exportMerchantSummaryExcelLoading = true
      })
      .addMatcher(isAnyOf(getMerchantSummary.fulfilled,
        getMerchantSummary.rejected,
        exportMerchantSummaryExcel.fulfilled,
        exportMerchantSummaryExcel.rejected), (state) => {
        state.getMerchantSummaryLoading = false
        state.exportMerchantSummaryExcelLoading = false
      })
  }
})

// Selectors
export const selectGetMerchantSummaryLoading = (state: RootState): boolean => state.merchantSummaryReducer.getMerchantSummaryLoading
export const selectMerchantSummary = (state: RootState): GetMerchantSummaryState => state.merchantSummaryReducer.merchantSummary
export const selectExportMerchantSummaryExcelLoading = (state: RootState): boolean => state.merchantSummaryReducer.exportMerchantSummaryExcelLoading

export default merchantSummarySlice.reducer
