import styled from 'styled-components'
import { Button } from 'antd'

const UserListsButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  > button {
    margin-right: 7px;
  }
`
const EnableButton = styled(Button)`
  background: ${props => props.theme.colors.enableColor};
  border-color: ${props => props.theme.colors.enableColor};
  color: ${props => props.theme.colors.secondaryColor};

  &:hover, &:active, &:focus {
    background: ${props => props.theme.colors.enableColor};
    border-color: ${props => props.theme.colors.enableColor};
    color: ${props => props.theme.colors.secondaryColor};
    opacity: 0.8;
  }
`
const DisableButton = styled(Button)`
  background: ${props => props.theme.colors.disableColor};
  border-color: ${props => props.theme.colors.disableColor};
  color: ${props => props.theme.colors.secondaryColor};

  &:hover, &:active, &:focus {
    background: ${props => props.theme.colors.disableColor};
    border-color: ${props => props.theme.colors.disableColor};
    color: ${props => props.theme.colors.secondaryColor};
    opacity: 0.8;
  }
`
const ErrorMessage = styled.p`
    color: ${props => props.theme.colors.errorColor};
    margin: 15px 0 0;
    text-align: center;
`
const UserListModalButtonsContainer = styled.div`
  margin: 16px 0 0;
  text-align: right;

  > button {
    margin: 0 0 0 16px;

    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
  }
`
const UserListDetailPageContentContainer = styled.div`
    width: 50%;

    @media (max-width: 992px) {
        width: 100%;
    }
`
const UserListDetailPageButtonsContainer = styled.div`
    > button {
        margin: 0 16px 0 0;

        @media (max-width: 576px) {
            margin: 0 0 16px;
        }
    }
`
const CreateUserPageButtonsContainer = styled.div`
  margin: 16px 0 0;
  text-align: right;

  > button {
    margin: 0 0 0 16px;

    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
  }
`
const EnableName = styled.span`
  color: ${props => props.theme.colors.primaryColor}
`
const DisableName = styled.span`
  color: ${props => props.theme.colors.disableColor}
`
const ButtonWithIcon = styled(Button)`
  > svg {
    margin-right: 5px;
  }
`

export {
  UserListsButtonContainer,
  EnableButton,
  DisableButton,
  ErrorMessage,
  UserListModalButtonsContainer,
  UserListDetailPageContentContainer,
  UserListDetailPageButtonsContainer,
  CreateUserPageButtonsContainer,
  EnableName,
  DisableName,
  ButtonWithIcon
}
