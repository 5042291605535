import { useEffect, useCallback, useState } from 'react'
import { message, Row, Col, Select, Form, Grid } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/es/table'
import { selectSelectList, selectMerchantId, selectRolePermission } from '../../LoginPage/reducers'
import dayjs from 'dayjs'
import { isNullOrUndefined, pascalCase, getCurrencyValue, datetime } from '../../../utils/utils'
import { RangeValue } from 'rc-picker/lib/interface'
import { TableContainer } from '../../../components/styles'
import { Title, CreateButton, TopUpContent, ButtonWithIcon } from './styles'
import { postCreateTopUp, selectTopUpUriLoading, getTopUp, selectTopUps, selectTopUpLoading } from './reducers'
import { TopUpFilters } from './interfaces'
import NumberFormat from 'react-number-format'
import InputNumeric from '../../../components/InputNumeric'
import { selectGlobalCurrency } from '../../AuthLayout/reducers'
import { RiSearchLine } from 'react-icons/ri'
import { MdCreate } from 'react-icons/md'
import Table from '../../../components/TableComponent'
import { StatusType } from '../../../utils/interface'
import { StatusDisplay } from '../styles'
import FilterRangePicker from '../../../components/FilterRangePicker'

const { useBreakpoint } = Grid

const initialFilters = {
  currencies: undefined,
  endDate: dayjs(),
  startDate: dayjs(),
  pageIndex: 1,
  pageSize: 10,
  status: undefined,
  shouldSearchDate: false
} as const

const TopUpPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const merchantId = useAppSelector(selectMerchantId)
  const selectList = useAppSelector(selectSelectList)
  const topUpUriLoading = useAppSelector(selectTopUpUriLoading)
  const topUps = useAppSelector(selectTopUps)
  const topUpLoading = useAppSelector(selectTopUpLoading)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const rolePermission = useAppSelector(selectRolePermission)
  const canCreateTopUp = rolePermission?.includes('TOPUP_CREATE')
  const [filters, setFilters] = useState<TopUpFilters>(initialFilters)

  const mTransactionTopUpStatus = selectList.mTransactionTopUpStatus.map((item: { name: string, value: number }) => ({
    label: item.name,
    value: item.value
  })).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      ellipsis: true
    },
    {
      title: 'From',
      dataIndex: 'fromName',
      sorter: (a, b) => a.remark.localeCompare(b.remark),
      ellipsis: true
    },
    {
      title: 'To',
      dataIndex: 'toNumber',
      sorter: (a, b) => a.toName - b.toName,
      render: (value: string, row: any) => {
        return (
          <>
            {value.padStart(12, '*')}
          </>
        )
      },
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'statusCode',
      sorter: (a, b) => a.statusCode.localeCompare(b.statusCode),
      render: (value: string) => <StatusDisplay status={value.toLocaleUpperCase() as StatusType}>{pascalCase(value)}</StatusDisplay>,
      ellipsis: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      ellipsis: true,
      render: (value: number, row: { currency: string }) => <NumberFormat prefix={`${row.currency} `} value={value} displayType='text' thousandSeparator />
    },
    {
      title: 'Created On',
      dataIndex: 'createdTime',
      ellipsis: true,
      sorter: (a, b) => a.createdTime.localeCompare(b.createdTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('YYYY/MM/DD HH:mm:ss')
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedTime',
      ellipsis: true,
      sorter: (a, b) => a.updatedTime?.localeCompare(b.updatedTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('YYYY/MM/DD HH:mm:ss')
    }
  ]

  async function onHandleCreateTopUpSubmit (values: any): Promise<any> {
    const payload = {
      currency: getCurrencyValue(globalCurrency),
      amount: parseInt(values.amount)
    }

    try {
      const response = await dispatch(postCreateTopUp(payload)).then(unwrapResult)
      await message.success('Create Top Up Success!')
      return window.open(response.uri)?.focus()
    } catch (error: any) {
      return await message.error(error.message)
    }
  }
  const handleSearch = useCallback(async (data: TopUpFilters): Promise<void> => {
    const payload = {
      ...data,
      startDate: datetime.getStartDate(data.startDate),
      endDate: datetime.getEndDate(data.endDate),
      merchantId: merchantId.toString()
    }
    try {
      await dispatch(getTopUp(payload)).then(unwrapResult)
    } catch (error: any) {
      await message.error(error.message)
    }
  }, [dispatch, merchantId])

  async function onHandleSubmitFilters (): Promise<any> {
    const payload = {
      ...filters,
      pageIndex: 1
    }
    await handleSearch(payload)
  }

  function onHandleRangePicker (dates: RangeValue<dayjs.Dayjs>): void {
    if (dates !== null) {
      setFilters(prevState => ({
        ...prevState,
        startDate: dates[0],
        endDate: dates[1]
      }))
    } else {
      setFilters(prevState => ({
        ...prevState,
        startDate: null,
        endDate: null
      }))
    }
  }

  function onHandleChangeFilters (value: any, name: string): void {
    setFilters(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  async function onHandleOnChangePagination (pageIndex: number, pageSize: number | undefined): Promise<void> {
    const newFilters = {
      ...filters,
      pageIndex,
      pageSize
    }
    setFilters(newFilters)
    await handleSearch(newFilters)
  }

  const getTopUpAction = useCallback(async (): Promise<any> => {
    const payload = {
      ...initialFilters,
      currencies: [getCurrencyValue(globalCurrency)]
    }

    await handleSearch(payload)
  }, [handleSearch, globalCurrency])

  useEffect(() => {
    getTopUpAction().finally(() => {})
  }, [getTopUpAction])

  useEffect(() => {
    setFilters(f => {
      return {
        ...f,
        currencies: [getCurrencyValue(globalCurrency)],
        bankIds: undefined
      }
    })
  }, [globalCurrency])

  return (
    <>
      {
        canCreateTopUp &&
          <TopUpContent>
            <Title>Create Top Up Transaction</Title>
            <Form
              name='create-topup-form'
              onFinish={onHandleCreateTopUpSubmit}
              requiredMark={false}
              layout='vertical'
              initialValues={{
                currency: undefined,
                amount: ''
              }}
            >
              <Row gutter={16} align='bottom'>
                <Col xs={24} sm={8} md={7} xl={5} xxl={4}>
                  <Form.Item
                    label='Top Up Amount'
                    name='amount'
                    rules={[{ required: true, message: 'Top Up Amount is required.' }]}
                  >
                    <InputNumeric
                      placeholder='Top Up Amount'
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={5} xl={3} xxl={2}>
                  <CreateButton
                    htmlType='submit'
                    shape='round'
                    type='primary'
                    block={screens.xs}
                    loading={topUpUriLoading}
                  >
                    <MdCreate />  Create
                  </CreateButton>
                </Col>
              </Row>
            </Form>
          </TopUpContent>
      }
      <TopUpContent>
        <Title>Top Up Transaction</Title>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} md={9} xl={8} xxl={4}>
            <FilterRangePicker
              startDate={filters.startDate}
              endDate={filters.endDate}
              onChange={onHandleRangePicker}
            />
          </Col>
          <Col xs={24} sm={8} md={7} xl={5} xxl={4}>
            <Select
              placeholder='Select Status'
              style={{ width: '100%' }}
              options={mTransactionTopUpStatus}
              onChange={(value) => onHandleChangeFilters(value, 'status')}
              allowClear
            />
          </Col>
          <Col xs={24} sm={5} xl={3} xxl={2}>
            <ButtonWithIcon
              type='primary'
              shape='round'
              block={screens.xs}
              onClick={onHandleSubmitFilters}
            >
              <RiSearchLine />  Search
            </ButtonWithIcon>
          </Col>
        </Row>
        <TableContainer>
          <Table
            dataSource={topUps.data}
            loading={topUpLoading}
            columns={columns}
            current={filters.pageIndex}
            pageSize={filters.pageSize}
            total={topUps.total}
            onChange={onHandleOnChangePagination}
          />
        </TableContainer>
      </TopUpContent>
    </>
  )
}

export default TopUpPage
