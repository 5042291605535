import { useEffect, useState, useCallback, MouseEvent } from 'react'
import { Form, Input, message, Button, Skeleton, Modal, Grid } from 'antd'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { getUserProfile, selectUserProfile, selectUserProfileLoading, postVerification2Fa, selectPostVerification2FaLoading } from './reducers'
import { EnableButton, DisableButton, TwoFaTitle, ActiveTwoFa, InactiveTwoFa, ErrorMessage, ProfilePageButtonsContainer, ProfilePageModalButtonsContainer } from './styles'
import QRCode from 'react-qr-code'
import OtpInput from 'react-otp-input'
import { BsCheckCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'
import { selectRolePermission } from '../LoginPage/reducers'

const { useBreakpoint } = Grid

const ProfilePage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const userProfile = useAppSelector(selectUserProfile)
  const userProfileLoading = useAppSelector(selectUserProfileLoading)
  const postVerification2FaLoading = useAppSelector(selectPostVerification2FaLoading)
  const rolePermission = useAppSelector(selectRolePermission)
  const can2faAdmin = rolePermission?.includes('PROFILE_2FA')
  const [enableVisible, setEnableVisible] = useState(false)
  const [disableVisible, setDisableVisible] = useState(false)
  const [otp, setOtp] = useState('')
  const [hasError, setHasError] = useState(false)
  const [form] = Form.useForm()
  const containerStyle = {
    justifyContent: 'center',
    margin: '20px 0'
  }
  const inputStyle = {
    height: '40px',
    width: '40px',
    fontSize: '18px'
  }
  const errorStyle = {
    border: '2px solid #f5222d'
  }
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      xl: {
        span: 8
      }
    },
    wrapperCol: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      xl: {
        span: 8
      }
    }
  }

  const getUserProfileAction = useCallback(async (): Promise<any> => {
    const payload = {
      id: ''
    }

    try {
      const response = await dispatch(getUserProfile(payload)).then(unwrapResult)
      return form.setFieldsValue({
        name: response.name,
        username: response.username
      })
    } catch (error: any) {
      return await message.error(error.message)
    }
  }, [dispatch, form])

  useEffect(() => {
    getUserProfileAction().finally(() => {})
  }, [getUserProfileAction])

  function onHandleCancelModals (): void {
    setOtp('')
    setEnableVisible(false)
    setDisableVisible(false)
    setHasError(false)
  }

  function onHandleEnable2Fa (): void {
    setEnableVisible(true)
  }

  function onHandleDisable2Fa (): void {
    setDisableVisible(true)
  }

  function onHandleChangeOtp (otpValue: string): void {
    setHasError(false)
    setOtp(otpValue)
  }

  async function onHandle2FaSubmit (e: MouseEvent<HTMLElement, globalThis.MouseEvent>, type: string): Promise<any> {
    e.preventDefault()

    const active = type === 'enable'
    const payload = {
      active,
      otp
    }
    const userProfilePayload = {
      id: ''
    }

    try {
      if (otp.length < 6) {
        return setHasError(true)
      } else {
        await dispatch(postVerification2Fa(payload)).then(unwrapResult)
        await dispatch(getUserProfile(userProfilePayload))

        if (active) {
          await setEnableVisible(false)
          await setOtp('')
          return await message.success('Two-Factor Authentication is now Activated!')
        } else {
          await setDisableVisible(false)
          await setOtp('')
          return await message.success('Two-Factor Authentication is now Deactivated!')
        }
      }
    } catch (error: any) {
      await setOtp('')
      return await message.error(error.message)
    }
  }

  return (
    <>
      {
        (userProfileLoading && <Skeleton active />) ||
          <>
            <TwoFaTitle>Two-Factor Authentication</TwoFaTitle>
            <Form
              name='user-profile-form'
              requiredMark={false}
              layout='vertical'
              form={form}
            >
              <Form.Item
                label='Name'
                name='name'
                {...formItemLayout}
              >
                <Input placeholder='Name' disabled />
              </Form.Item>
              <Form.Item
                label='Username'
                name='username'
                {...formItemLayout}
              >
                <Input placeholder='Username' disabled />
              </Form.Item>
            </Form>
            {
              can2faAdmin &&
                <ProfilePageButtonsContainer>
                  {
                    userProfile.isEnableLoginTwoFactor ? <ActiveTwoFa>Active</ActiveTwoFa> : <InactiveTwoFa>Inactive</InactiveTwoFa>
                  }
                  {
                    userProfile.isEnableLoginTwoFactor ? <DisableButton shape='round' block={screens.xs} onClick={onHandleDisable2Fa}><ImCross /> Disable 2FA Login</DisableButton> : <EnableButton shape='round' block={screens.xs} onClick={onHandleEnable2Fa}><BsCheckCircle /> Enable 2FA Login</EnableButton>
                  }
                </ProfilePageButtonsContainer>
            }
          </>
      }
      <Modal
        open={enableVisible}
        title='Enable Two-Factor Authentication'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        bodyStyle={{
          textAlign: 'center'
        }}
        footer={null}
      >
        <QRCode value={userProfile.googleLoginAuthUri} />
        <OtpInput
          value={otp}
          onChange={onHandleChangeOtp}
          numInputs={6}
          separator={<span>-</span>}
          shouldAutoFocus
          isInputNum
          containerStyle={containerStyle}
          inputStyle={inputStyle}
          hasErrored={hasError}
          errorStyle={errorStyle}
        />
        {
          hasError &&
            <ErrorMessage>OTP is required.</ErrorMessage>
        }
        <ProfilePageModalButtonsContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <EnableButton
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={async (e) => await onHandle2FaSubmit(e, 'enable')}
            loading={postVerification2FaLoading}
          >
            Submit
          </EnableButton>
        </ProfilePageModalButtonsContainer>
      </Modal>
      <Modal
        open={disableVisible}
        title='Disable Two-Factor Authentication'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <OtpInput
          value={otp}
          onChange={onHandleChangeOtp}
          numInputs={6}
          separator={<span>-</span>}
          shouldAutoFocus
          isInputNum
          containerStyle={containerStyle}
          inputStyle={inputStyle}
          hasErrored={hasError}
          errorStyle={errorStyle}
        />
        {
          hasError &&
            <ErrorMessage>OTP is required.</ErrorMessage>
        }
        <ProfilePageModalButtonsContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <DisableButton
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={async (e) => await onHandle2FaSubmit(e, 'disable')}
            loading={postVerification2FaLoading}
          >
            Submit
          </DisableButton>
        </ProfilePageModalButtonsContainer>
      </Modal>
    </>
  )
}

export default ProfilePage
