import { Form, Input, Button, message, Grid } from 'antd'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { unwrapResult } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import { postChangePassword, selectPostChangePasswordLoading } from './reducers'
import { ChangePasswordPageButtonsContainer } from './styles'

const { useBreakpoint } = Grid

const ChangePasswordPage = (props: RouteComponentProps): JSX.Element => {
  const { history } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const postChangePasswordLoading = useAppSelector(selectPostChangePasswordLoading)
  const [form] = Form.useForm()
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      xl: {
        span: 8
      }
    },
    wrapperCol: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      xl: {
        span: 8
      }
    }
  }

  async function onHandleChangePassSubmit (values: any): Promise<any> {
    try {
      await dispatch(postChangePassword(values)).then(unwrapResult)
      await message.success('Change Password Success!')
      return form.resetFields()
    } catch (error: any) {
      await message.error(error.message)
      return form.resetFields()
    }
  }

  function onHandleCancel (): void {
    history.push('/dashboard')
  }

  return (
    <>
      <Form
        name='change-password-form'
        onFinish={onHandleChangePassSubmit}
        requiredMark={false}
        layout='vertical'
        form={form}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
      >
        <Form.Item
          label='Old Password'
          name='oldPassword'
          rules={[{ required: true, message: 'Old Password is required.' }]}
          {...formItemLayout}
        >
          <Input placeholder='Old Password' />
        </Form.Item>
        <Form.Item
          label='New Password'
          name='newPassword'
          rules={[{ required: true, message: 'New Password is required.' }]}
          {...formItemLayout}
        >
          <Input placeholder='New Password' />
        </Form.Item>
        <Form.Item
          label='Confirm Password'
          name='confirmPassword'
          rules={[{ required: true, message: 'Confirm Password is required.' }]}
          {...formItemLayout}
        >
          <Input placeholder='Confirm Password' />
        </Form.Item>
        <ChangePasswordPageButtonsContainer>
          <Button
            type='primary'
            htmlType='submit'
            shape='round'
            block={screens.xs}
            loading={postChangePasswordLoading}
          >
            Update
          </Button>
          <Button
            onClick={onHandleCancel}
            shape='round'
            block={screens.xs}
          >
            Cancel
          </Button>
        </ChangePasswordPageButtonsContainer>
      </Form>
    </>
  )
}

export default ChangePasswordPage
