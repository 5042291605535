import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import api from '../../../utils/api'
import { GetStateSetRoleEditList, RoleEditState, RoleManagementState, RoleManagementTable, GetState, RejectWithValueState, DataSourceArray, SaveRoleEditPayload } from './interfaces'

export const getRoleEditList = createAsyncThunk<any, string, {state: GetState, rejectValue: RejectWithValueState}>('role-edit/getRoleEditList',
  async (id, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get(`/RoleManagement/GetRole-Details?roletype=${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const { permissions, rolePermissionAccess } = response.data
    const hasNoData: boolean = permissions.length === 0

    if (hasNoData) {
      return rejectWithValue({ message: `No Data with ID ${id}. Redirecting to Dashboard..` })
    }
    const permissionCodeSplit = permissions.split(',')
    return { permissionCodeSplit, rolePermissionAccess }
  }
)

export const getRoleManagementList = createAsyncThunk<RoleManagementTable[], void | never, {state: GetState}>('role-management/getRoleManagementList',
  async (_, { getState }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get('/RoleManagement/GetRoles', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
    return response.data
  }
)

export const setRoleEditList = createAsyncThunk<any, string, {state: GetStateSetRoleEditList}>('role-edit/setRoleEditList',
  (payload, { getState }) => {
    const { roleEditReducer: { data } } = getState()
    if (data.includes(payload)) {
      const response = data.filter((item: string) => {
        return item !== payload
      })
      return response
    } else {
      const response = data.concat(payload)
      return response
    }
  }
)

export const postSaveRoleEdit = createAsyncThunk<any, SaveRoleEditPayload, { state: GetState, rejectWithValue: RejectWithValueState }>('role-edit/postSaveRoleEditList',
  async (payload: any, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/RoleManagement/UpdateRolePermissions', {
      ...payload
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }
    return response.data
  }
)

export const postAddRoleName = createAsyncThunk<any, any, { state: GetState, rejectWithValue: RejectWithValueState }>('role-management/postAddRoleName',
  async (payload: any, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/RoleManagement/AddMerchantRole', {
      ...payload
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }
    return response.data
  }
)

export const postDisableRole = createAsyncThunk<any, any, { state: GetState, rejectWithValue: RejectWithValueState }>('role-management/postDisableRole',
  async (payload: any, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/RoleManagement/UpdateMerchantRole', {
      ...payload
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const message: string | undefined = response.data.message

    if (message !== undefined) {
      return rejectWithValue({ message: response.data.message })
    }
    return response.data
  }
)

const roleEditState: RoleEditState = {
  roleEditLoading: false,
  roleType: 0,
  data: [],
  roleEditNode: []
}

const roleManagementState: RoleManagementState = {
  roleManagementLoading: false,
  roleManagement: {
    data: [],
    total: 6
  }
}

const RoleManagementSlice = createSlice({
  name: 'roleManagement',
  initialState: roleManagementState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleManagementList.fulfilled, (state, action) => {
        state.roleManagement.data = action.payload
        state.roleManagementLoading = false
      })
      .addCase(getRoleManagementList.pending, (state, action) => {
        state.roleManagementLoading = true
      })
  }
})

const RoleEditSlice = createSlice({
  name: 'roleEdit',
  initialState: roleEditState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleEditList.fulfilled, (state, action) => {
        state.data = action.payload.permissionCodeSplit
        state.roleEditNode = action.payload.rolePermissionAccess
        state.roleEditLoading = false
      })
      .addCase(getRoleEditList.pending, (state, action) => {
        state.roleEditLoading = true
        state.data = []
      })
      .addCase(setRoleEditList.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }

})

export const selectRoleEditNode = (state: RootState): DataSourceArray[] => state.roleEditReducer.roleEditNode
export const selectRoleEditList = (state: RootState): string [] => state.roleEditReducer.data
export const selectRoleEditLoading = (state: RootState): boolean => state.roleEditReducer.roleEditLoading

export const selectRoleManagementList = (state: RootState): RoleManagementTable[] => state.roleManagementReducer.roleManagement.data
export const selectRoleManagementLoading = (state: RootState): boolean => state.roleManagementReducer.roleManagementLoading

export const roleEditReducer = RoleEditSlice.reducer
export const roleManagementReducer = RoleManagementSlice.reducer
