import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import {
  RejectWithValueState,
  GetState,
  TopUpState,
  PostCreateTopUpState,
  PostCreateTopUpPayloadState,
  GetTopUpPayloadState,
  GetTopUpState
} from './interfaces'
import api from '../../../utils/api'
import { clientTimeZone } from '../../../utils/utils'

// Async Requests
export const postCreateTopUp = createAsyncThunk<PostCreateTopUpState, PostCreateTopUpPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/postCreateTopUp',
  async ({ currency, amount }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get(`/merchant/topup?currency=${currency}&amount=${amount}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const getTopUp = createAsyncThunk<GetTopUpState, GetTopUpPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/getTopUp',
  async ({ endDate, merchantId, pageIndex, pageSize, shouldSearchDate, startDate, status, currencies }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/merchantTopUp/search', {
      endDate,
      merchantId,
      pageIndex,
      pageSize,
      shouldSearchDate,
      startDate,
      status,
      clientTimeZone,
      currencies
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const topUpState: TopUpState = {
  topUpUriLoading: false,
  topUpLoading: false,
  topUps: {
    data: [],
    total: 0
  }
}

// Slices
const topUpSlice = createSlice({
  name: 'topup',
  initialState: topUpState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postCreateTopUp.pending, (state) => {
        state.topUpUriLoading = true
      })
      .addCase(getTopUp.pending, (state) => {
        state.topUpLoading = true
      })
      .addCase(getTopUp.fulfilled, (state, action) => {
        state.topUps.data = action.payload.data
        state.topUps.total = action.payload.total
      })
      .addMatcher(isAnyOf(postCreateTopUp.fulfilled,
        postCreateTopUp.rejected,
        getTopUp.fulfilled,
        getTopUp.rejected), (state) => {
        state.topUpUriLoading = false
        state.topUpLoading = false
      })
  }
})

// Selectors
export const selectTopUpUriLoading = (state: RootState): boolean => state.topUpReducer.topUpUriLoading
export const selectTopUpLoading = (state: RootState): boolean => state.topUpReducer.topUpLoading
export const selectTopUps = (state: RootState): GetTopUpState => state.topUpReducer.topUps

export default topUpSlice.reducer
