import { customTheme } from '..'
import { LayoutFooter } from './styles'

const Footer = (): JSX.Element => {
  const date = new Date()
  const currentYear = date.getFullYear()

  return (
    <LayoutFooter>
      {customTheme.title} ©{currentYear}
    </LayoutFooter>
  )
}

export default Footer
