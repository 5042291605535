import { Route, Redirect } from 'react-router-dom'
import { selectIsEnableLoginTwoFactor, selectAuthToken } from '../containers/LoginPage/reducers'
import { useAppSelector } from '../hooks/storeHooks'
import { PublicRouteState } from './interfaces'

const PublicRoute = ({ component: Component, ...rest }: PublicRouteState): JSX.Element => {
  const isLoggedIn = useAppSelector(selectAuthToken) !== ''
  const isEnableLoginTwoFactor = useAppSelector(selectIsEnableLoginTwoFactor)

  function redirectTwoFactorAuthentication (): any { // after logging in, will check if 2fa is enabled or else will redirect to dashboard
    if (isEnableLoginTwoFactor) {
      return <Redirect to={{ pathname: '/two-factor-authentication', state: { from: rest.location } }} />
    } else {
      return <Redirect to={{ pathname: '/dashboard', state: { from: rest.location } }} />
    }
  }

  return isLoggedIn
    ? redirectTwoFactorAuthentication()
    : (
      <Route
        {...rest}
        render={(props) => (
          <Component {...props} />
        )}
      />
    )
}

export default PublicRoute
