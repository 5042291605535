import { BankRowDetails, EditBankFormState } from '../../interfaces'
import { Form, Input, Select, Button, Row, Col, message, Grid } from 'antd'
import { selectSelectList, selectMerchantId } from '../../../LoginPage/reducers'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { postUpdateBankAccount, getBankAccounts, selectPostUpdateBankAccountLoading, postValidateBankAccount } from '../../reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { BankAndWhiteListButtonsContainer } from '../../styles'

const { useBreakpoint } = Grid

const EditBankForm = (props: BankRowDetails & EditBankFormState): JSX.Element => {
  const { bankCode, accName, cardNumber, branch, status, id, setEditVisible, filters } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const merchantId = useAppSelector(selectMerchantId)
  const postUpdateBankAccountLoading = useAppSelector(selectPostUpdateBankAccountLoading)
  const bankSelectList = selectList.bankSelectList.map((item: { code: string, name: string }) =>
    ({
      label: `${item.code} - ${item.name}`,
      value: item.code
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)
  const mAccountStatusSelectList = selectList.mAccountStatusSelectList.map((item: { name: string, value: number }) => (
    {
      label: item.name,
      value: item.value
    })
  ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  function onHandleCancel (): void {
    setEditVisible(false)
  }

  async function onHandleEditBankSubmit (values: any): Promise<any> {
    const editSubmitPayload = {
      bankCode: values.bankCode,
      branch: values.branch,
      id,
      merchantId,
      name: values.accName,
      number: values.cardNumber,
      status: values.status
    }
    const filtersPayload = {
      ...filters
    }

    try {
      await dispatch(postUpdateBankAccount(editSubmitPayload)).then(unwrapResult)
      await dispatch(getBankAccounts(filtersPayload))
      await dispatch(postValidateBankAccount())
      await setEditVisible(false)
      return await message.success('Updated Bank Account Success!')
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  return (
    <Form
      name='edit-bank-form'
      onFinish={onHandleEditBankSubmit}
      requiredMark={false}
      layout='vertical'
      initialValues={{
        bankCode,
        accName,
        cardNumber,
        branch,
        status,
        id
      }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Remarks'
            name='branch'
            rules={[{ required: true, message: 'Remarks is required.' }]}
          >
            <Input placeholder='Remarks' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Beneficiary Name'
            name='accName'
            rules={[{ required: true, message: 'Beneficiary Name is required.' }]}
          >
            <Input placeholder='Beneficiary Name' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label='Account Status'
            name='status'
          >
            <Select placeholder='Select Status' options={mAccountStatusSelectList} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Bank'
            name='bankCode'
          >
            <Select disabled placeholder='Select Bank' options={bankSelectList} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Account Number'
            name='cardNumber'
          >
            <Input disabled placeholder='Account Number' />
          </Form.Item>
        </Col>
      </Row>
      <BankAndWhiteListButtonsContainer>
        <Button
          onClick={onHandleCancel}
          block={screens.xs}
          shape='round'
        >
          Cancel
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          block={screens.xs}
          shape='round'
          loading={postUpdateBankAccountLoading}
        >
          Update
        </Button>
      </BankAndWhiteListButtonsContainer>
    </Form>
  )
}

export default EditBankForm
