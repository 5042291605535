import { CheckCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
import { StatusIconProps } from '../interfaces'
import { memo } from 'react'

const StatusIcon = ({ status }: StatusIconProps): JSX.Element => {
  if (status === 'Active') {
    return <CheckCircleOutlined style={{ fontSize: 22, color: 'green' }} />
  }
  return <PauseCircleOutlined style={{ fontSize: 22, color: 'grey' }} />
}

export default memo(StatusIcon)
