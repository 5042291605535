import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import {
  RejectWithValueState,
  GetState,
  GetUserProfilePayloadState,
  GetUserProfileState,
  ProfileState,
  PostVerification2FaState
} from './interfaces'
import api from '../../utils/api'

// Async Requests
export const getUserProfile = createAsyncThunk<GetUserProfileState, GetUserProfilePayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'profile/getUserProfile',
  async ({ id }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get(`/user-profile/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postVerification2Fa = createAsyncThunk<any, PostVerification2FaState, { state: GetState, rejectValue: RejectWithValueState }>(
  'profile/postVerification2Fa',
  async ({ active, otp }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user-profile/verification-2fa', {
      active,
      otp
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial State
const profileState: ProfileState = {
  userProfileLoading: false,
  userProfile: {
    name: '',
    username: '',
    isEnableTwoFactor: false,
    isEnableLoginTwoFactor: false,
    googleAuthUri: '',
    googleLoginAuthUri: '',
    isAdmin: false
  },
  postVerification2FaLoading: false
}

// Slices
const profileSlice = createSlice({
  name: 'profile',
  initialState: profileState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.userProfileLoading = true
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = {
          name: action.payload.name,
          username: action.payload.username,
          isEnableTwoFactor: action.payload.isEnableTwoFactor,
          isEnableLoginTwoFactor: action.payload.isEnableLoginTwoFactor,
          googleAuthUri: action.payload.googleAuthUri,
          googleLoginAuthUri: action.payload.googleLoginAuthUri,
          isAdmin: action.payload.isAdmin
        }
      })
      .addCase(postVerification2Fa.pending, (state) => {
        state.postVerification2FaLoading = true
      })
      .addMatcher(isAnyOf(getUserProfile.fulfilled,
        getUserProfile.rejected,
        postVerification2Fa.fulfilled,
        postVerification2Fa.rejected), (state) => {
        state.userProfileLoading = false
        state.postVerification2FaLoading = false
      })
  }
})

// Selectors
export const selectUserProfileLoading = (state: RootState): boolean => state.profileReducer.userProfileLoading
export const selectUserProfile = (state: RootState): GetUserProfileState => state.profileReducer.userProfile
export const selectPostVerification2FaLoading = (state: RootState): boolean => state.profileReducer.postVerification2FaLoading

export default profileSlice.reducer
