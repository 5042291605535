import { Table } from 'antd'
import { TablePaginationConfig } from 'antd/es/table'
import { ExpandableConfig } from 'rc-table/lib/interface'

interface TableInterface {
  total?: number
  current?: number
  pageSize?: number
  onChange?: (page: number, pageSize: number | undefined) => any
  dataSource: any
  loading: boolean
  columns: any
  footer?: any
  hasPagination?: boolean
  expandable?: ExpandableConfig<any>
  rowKey?: string
}

const TableComponent = ({ total, current, pageSize, onChange, hasPagination = true, expandable, rowKey = 'id', ...rest }: TableInterface): JSX.Element => {
  const handlePagination = (page: number, rowsPerPage: number | undefined): void => {
    let currentPage = page
    if (pageSize !== rowsPerPage) {
      currentPage = 1
    }
    if (onChange !== undefined) onChange(currentPage, rowsPerPage)
  }

  const pagination: TablePaginationConfig | false = hasPagination ? {
    current: current,
    position: ['topRight'],
    total: total,
    showQuickJumper: typeof total === 'number' ? total > 20 : undefined,
    showSizeChanger: typeof total === 'number' ? total >= 20 : undefined,
    showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total}`,
    onChange: handlePagination
  } : false

  return (
    <Table
      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
      scroll={{ x: 1200 }}
      rowKey={rowKey}
      size='small'
      tableLayout='auto'
      bordered
      pagination={pagination}
      expandable={expandable}
      {...rest}
    />
  )
}

export default TableComponent
