import {
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom'
import './App.css'
import './App.less'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import PublicRoute from './components/PublicRoute'
import LoginPage from './containers/LoginPage/LoginPage'
import TwoFactorAuthenticationPage from './containers/LoginPage/TwoFactorAuthenticationPage'
import DashboardPage from './containers/DashboardPage/DashboardPage'
import MerchantPage from './containers/MerchantPage/MerchantPage'
import MerchantSummaryPage from './containers/Transactions/MerchantSummaryPage/MerchantSummaryPage'
import DepositPage from './containers/Transactions/DepositPage/DepositPage'
import WithdrawalPage from './containers/Transactions/WithdrawalPage/WithdrawalPage'
import SettlementPage from './containers/Transactions/SettlementPage/SettlementPage'
import TopUpPage from './containers/Transactions/TopUpPage/TopUpPage'
import DailyBalancePage from './containers/Report/DailyBalancePage/DailyBalancePage'
import ProfilePage from './containers/ProfilePage/ProfilePage'
import ChangePasswordPage from './containers/ChangePasswordPage/ChangePasswordPage'
import UserListPage from './containers/Administrator/UserListPage/UserListPage'
import NotFoundPage from './containers/NotFoundPage/NotFoundPage'
import UserListDetailPage from './containers/Administrator/UserListPage/components/UserListDetailPage'
import RoleManagementPage from './containers/Administrator/RoleManagementPage/RoleManagementPage'
import RoleEditPage from './containers/Administrator/RoleManagementPage/components/RoleEditPage'

const App = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Redirect exact from='/' to='/login' />
        <PublicRoute exact path='/login' component={LoginPage} />
        <AuthenticatedRoute exact path='/two-factor-authentication' component={TwoFactorAuthenticationPage} permission={null} />
        <AuthenticatedRoute exact path='/dashboard' component={DashboardPage} permission='DASHBOARD_VIEW' />
        <AuthenticatedRoute exact path='/merchant' component={MerchantPage} permission='MERCHANT_INFO_VIEW' />
        <Redirect exact from='/transaction' to='/transaction/merchant-summary' />
        <AuthenticatedRoute exact path='/transaction/merchant-summary' component={MerchantSummaryPage} permission='MERCHANT_SUMMARY_VIEW' />
        <AuthenticatedRoute exact path='/transaction/deposit' component={DepositPage} permission='DEPOSIT_VIEW' />
        <AuthenticatedRoute exact path='/transaction/withdrawal' component={WithdrawalPage} permission='WITHDRAWAL_VIEW' />
        <AuthenticatedRoute exact path='/transaction/settlement' component={SettlementPage} permission='SETTLEMENT_VIEW' />
        <AuthenticatedRoute exact path='/transaction/topup' component={TopUpPage} permission='TOPUP_VIEW' />
        <AuthenticatedRoute exact path='/report/daily-balance' component={DailyBalancePage} permission='DAILY_BALANCE_VIEW' />
        <AuthenticatedRoute exact path='/profile' component={ProfilePage} permission='PROFILE_VIEW' />
        <AuthenticatedRoute exact path='/change-password' component={ChangePasswordPage} permission='CHANGE_PASSWORD_VIEW' />
        <AuthenticatedRoute exact path='/administrator/user-list' component={UserListPage} permission='USER_LIST_VIEW' />
        <AuthenticatedRoute exact strict sensitive path='/administrator/user-list/:id' component={UserListDetailPage} permission='USER_LIST_EDIT' />
        <AuthenticatedRoute exact path='/administrator/role-management' component={RoleManagementPage} permission='ROLE_MANAGEMENT_VIEW' />
        <Redirect exact from='/administrator/role-management/detail' to='/administrator/role-management' />
        <AuthenticatedRoute exact strict sensitive path='/administrator/role-management/detail/:id' component={RoleEditPage} permission='ROLE_MANAGEMENT_VIEW' />
        <AuthenticatedRoute path='*' component={NotFoundPage} permission='' />
      </Switch>
    </Router>
  )
}

export default App
