import React, { useState, useRef } from 'react'
import { datePresets } from '../utils/utils'
import { DatePicker } from 'antd'
import { RangeValue } from 'rc-picker/lib/interface'
import { RangeInfo, RangeType } from 'rc-picker/lib/RangePicker'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

interface FilterRangePickerProps {
  startDate: dayjs.Dayjs | null
  endDate: dayjs.Dayjs | null
  onChange: (arg: RangeValue<dayjs.Dayjs>) => void
}
const FilterRangePicker = ({ startDate, endDate, onChange }: FilterRangePickerProps): JSX.Element => {
  const [maxDate, setMaxDate] = useState<dayjs.Dayjs>()
  const [minDate, setMinDate] = useState<dayjs.Dayjs>()
  const [dateTemp, setDateTemp] = useState<RangeValue<dayjs.Dayjs>>([startDate, endDate])
  const dateRef = useRef<RangeValue<dayjs.Dayjs>>([startDate, endDate])
  const lastRangeRef = useRef<RangeType>()

  function onDateClick (date: RangeValue<dayjs.Dayjs>, info: RangeInfo): void {
    if (date == null) return
    const [startDate, endDate] = date
    let newDate = [startDate, endDate] as RangeValue<dayjs.Dayjs | null>
    if (info.range === 'start') {
      newDate = [startDate, minDate != null ? endDate : null]
      setMaxDate(dayjs(startDate).startOf('day').add(92, 'days'))
    } else {
      newDate = [maxDate != null ? startDate : null, endDate]
      setMinDate(dayjs(endDate).endOf('day').subtract(92, 'days'))
    }
    setDateTemp(newDate)
    lastRangeRef.current = info.range
    dateRef.current = date
  }

  function handleDateOpen (open: boolean): void {
    if (open) {
      setMaxDate(undefined)
      setMinDate(undefined)
    } else {
      if (dateRef.current == null) return
      const start = dateRef.current[0]
      const end = dateRef.current[1]
      const isStart = lastRangeRef.current === 'start'

      const range92Days = isStart ? dayjs(start).add(92, 'days') : dayjs(end).subtract(92, 'days')
      const isBetweenRange = Boolean(isStart ? Boolean(end?.isBefore(range92Days)) || end?.isSame(range92Days, 'day') : Boolean(start?.isAfter(range92Days)) || start?.isSame(range92Days, 'day'))

      if (isBetweenRange) {
        setDateTemp(dateRef.current)
        onChange(dateRef.current)
        return
      }
      const date = [isStart ? start : range92Days, isStart ? range92Days : end] as RangeValue<dayjs.Dayjs>

      setDateTemp(date)
      onChange(date)
    }
  }

  return (
    <RangePicker
      allowClear={false}
      style={{ width: '100%' }}
      presets={datePresets}
      value={dateTemp}
      format='DD/MM/YYYY'
      onCalendarChange={(values, _, info) => onDateClick(values, info)}
      onOpenChange={handleDateOpen}
      disabledDate={(date) => date < dayjs(minDate ?? -Infinity) || date > dayjs(maxDate ?? Infinity) || date > dayjs()}
    />
  )
}

export default FilterRangePicker
