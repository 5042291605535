import { Input } from 'antd'
import { InputNumericProps } from './interfaces'

const InputNumeric = ({ onChange, ...props }: InputNumericProps): JSX.Element => {
  function onChangeHandler (e: { target: { value: any } }): void {
    const { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if (((!isNaN(value) && reg.test(value)) || value === '' || value === '-') && (onChange != null)) {
      onChange(value)
    }
  }

  return (
    <Input
      {...props}
      onChange={onChangeHandler}
    />
  )
}

export default InputNumeric
