import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'
import { RootState } from '../../../store/store'
import {
  UserListState,
  UserListPayloadState,
  UpdateUserPayloadState,
  DeleteUserPayloadState,
  UserListDetailPayloadState,
  GetState,
  RejectWithValueState,
  GetUserListState,
  GetUserListDetailState,
  UpdateLoginTwoFaPayloadState,
  UpdateSettlementTwoFaPayloadState,
  CreateUserPayloadState,
  ResetPasswordPayloadState,
  ResetPasswordState
} from './interfaces'
import api from '../../../utils/api'

// Async Requests
export const getUserList = createAsyncThunk<GetUserListState, UserListPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/getUserList',
  async ({ pageIndex, pageSize }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user/search-user', {
      pageIndex,
      pageSize
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)
export const getUserListDetail = createAsyncThunk<GetUserListDetailState, UserListDetailPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/getUserListDetail',
  async ({ id }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.get(`/user/GetUserById?id=${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)
export const postUpdateLoginTwoFa = createAsyncThunk<any, UpdateLoginTwoFaPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/postUpdateLoginTwoFa',
  async ({ active, userId, otp }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user/UpdateLogin2FA', {
      active,
      userId,
      otp
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)
export const postUpdateSettlementTwoFa = createAsyncThunk<any, UpdateSettlementTwoFaPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/postUpdateSettlementTwoFa',
  async ({ active, userId, otp }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user-profile/user-list-verification-2fa', {
      active,
      userId,
      otp
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)
export const postUpdateUser = createAsyncThunk<any, UpdateUserPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/postUpdateUser',
  async ({ id, fullName, merchantUserRoles, password, status, username }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user/update-user', {
      id,
      fullName,
      merchantUserRoles,
      password,
      status,
      username
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postDeleteUser = createAsyncThunk<any, DeleteUserPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/postDeleteUser',
  async ({ id, deleted }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user/delete-user', {
      id,
      deleted
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postResetPassword = createAsyncThunk<ResetPasswordState, ResetPasswordPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/postResetPassword',
  async ({ agentId }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user/ResetPassword', {
      agentId
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

export const postCreateUser = createAsyncThunk<any, CreateUserPayloadState, { state: GetState, rejectValue: RejectWithValueState }>(
  'administrator/postCreateUser',
  async ({ email, fullname, merchantUserRoles, password, phone, userName }, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/user/create-user', {
      email,
      fullname,
      merchantUserRoles,
      password,
      phone,
      userName
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return response.data
  }
)

// Initial States
const userListState: UserListState = {
  userLists: {
    data: [],
    total: 0
  },
  resetPassword: {
    password: '',
    userName: ''
  },
  getUserListLoading: false,
  postUpdateLoginTwoFaLoading: false,
  postUpdateSettlementTwoFaLoading: false,
  postUpdateUserLoading: false,
  postDeleteUserLoading: false,
  postResetPasswordLoading: false,
  getUserListDetailLoading: false,
  postCreateUserLoading: false
}

// Slices
const userListSlice = createSlice({
  name: 'userList',
  initialState: userListState,
  reducers: {
    clearResetPassword (state) {
      state.resetPassword.userName = ''
      state.resetPassword.password = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.getUserListLoading = true
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userLists.data = action.payload.data
        state.userLists.total = action.payload.total
      })
      .addCase(postUpdateLoginTwoFa.pending, (state) => {
        state.postUpdateLoginTwoFaLoading = true
      })
      .addCase(postUpdateSettlementTwoFa.pending, (state) => {
        state.postUpdateSettlementTwoFaLoading = true
      })
      .addCase(postUpdateUser.pending, (state) => {
        state.postUpdateUserLoading = true
      })
      .addCase(postDeleteUser.pending, (state) => {
        state.postDeleteUserLoading = true
      })
      .addCase(postResetPassword.fulfilled, (state, action) => {
        state.resetPassword.password = action.payload.password
        state.resetPassword.userName = action.payload.userName
      })
      .addCase(postResetPassword.pending, (state) => {
        state.postResetPasswordLoading = true
      })
      .addCase(getUserListDetail.pending, (state) => {
        state.getUserListDetailLoading = true
      })
      .addCase(postCreateUser.pending, (state) => {
        state.postCreateUserLoading = true
      })
      .addMatcher(isAnyOf(postUpdateLoginTwoFa.fulfilled,
        postUpdateLoginTwoFa.rejected,
        postUpdateUser.fulfilled,
        postUpdateUser.rejected,
        postDeleteUser.fulfilled,
        postDeleteUser.rejected,
        getUserListDetail.fulfilled,
        getUserListDetail.rejected,
        getUserList.fulfilled,
        getUserList.rejected,
        postCreateUser.fulfilled,
        postCreateUser.rejected,
        postUpdateSettlementTwoFa.fulfilled,
        postUpdateSettlementTwoFa.rejected,
        postResetPassword.fulfilled,
        postResetPassword.rejected), (state) => {
        state.postUpdateLoginTwoFaLoading = false
        state.postUpdateSettlementTwoFaLoading = false
        state.postUpdateUserLoading = false
        state.postDeleteUserLoading = false
        state.getUserListDetailLoading = false
        state.getUserListLoading = false
        state.postCreateUserLoading = false
        state.postResetPasswordLoading = false
      })
  }
})

// actions
export const { clearResetPassword } = userListSlice.actions

// selectors
export const selectUserLists = (state: RootState): GetUserListState => state.userListReducer.userLists
export const selectResetPassword = (state: RootState): ResetPasswordState => state.userListReducer.resetPassword
export const selectPostUpdateLoginTwoFaLoading = (state: RootState): boolean => state.userListReducer.postUpdateLoginTwoFaLoading
export const selectPostUpdateSettlementTwoFaLoading = (state: RootState): boolean => state.userListReducer.postUpdateSettlementTwoFaLoading
export const selectPostUpdateUserLoading = (state: RootState): boolean => state.userListReducer.postUpdateUserLoading
export const selectPostDeleteUserLoading = (state: RootState): boolean => state.userListReducer.postDeleteUserLoading
export const selectPostResetPasswordLoading = (state: RootState): boolean => state.userListReducer.postResetPasswordLoading
export const selectGetUserListDetailLoading = (state: RootState): boolean => state.userListReducer.getUserListDetailLoading
export const selectGetUserListLoading = (state: RootState): boolean => state.userListReducer.getUserListLoading
export const selectPostCreateUserLoading = (state: RootState): boolean => state.userListReducer.postCreateUserLoading

export default userListSlice.reducer
