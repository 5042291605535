import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/store'
import api from '../../../../utils/api'
import { CallbackState, GetState, RejectWithValueState, TransactionCallbackData } from './interface'

// Async Requests
export const getTransactionCallback = createAsyncThunk<TransactionCallbackData, any, { state: GetState, rejectValue: RejectWithValueState }>(
  'transactions/search-merchant-callback',
  async (payload, { getState, rejectWithValue }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/callback-audit-log/search-merchant-callback', payload, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    return { ...response.data, transactionType: payload.transactionType, id: payload.transactionId }
  }
)

// Initial State
const transactionCallbackState: CallbackState = {
  1: {},
  2: {},
  3: {}
}

// Slices
const transactionCallbackSlice = createSlice({
  name: 'transaction-callbacks',
  initialState: transactionCallbackState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionCallback.fulfilled, (state, action) => {
        state[action.payload.transactionType][action.payload.id] = action.payload
      })
  }
})

// Selectors
export const transactionCallbackData = (state: RootState): CallbackState => state.transactionCallbackReducer
// export const selectGetDepositLoading = (state: RootState): boolean => state.depositReducer.getDepositLoading
// export const selectDeposits = (state: RootState): GetDepositsState => state.depositReducer.deposits
// export const selectExportDepositExcelLoading = (state: RootState): boolean => state.depositReducer.exportDepositExcelLoading

export default transactionCallbackSlice.reducer
